import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createUser, deleteUser, getAllUsers, updateUser, activateUser, deactivateUser } from "../../Api/userServices";
import { toast } from "react-toastify";
import usePagination from "../usePagination";

export const useUserQuery = () => {
  const queryClient = useQueryClient();
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } = usePagination();

  const { data: apiResponse = { data: [], metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 } }, isLoading, error } = useQuery({
    queryKey: ["users", offset, limit],
    queryFn: () => getAllUsers({ 
      offset: Math.max(0, Number(offset)), 
      limit: Math.max(1, Number(limit))
    }),
    keepPreviousData: true,
    staleTime: Infinity,
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to fetch users. Please try again.");
    },
  });

  const totalItems = apiResponse.metadata?.count || 0;
  const totalPages = apiResponse.metadata?.total_pages || Math.ceil(totalItems / limit);

  const createMutation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User created successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to create user. Please try again.");
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateUser(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User updated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update user. Please try again.");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete user. Please try again.");
    },
  });

  const activateMutation = useMutation({
    mutationFn: ({ userId, studentId, parentId }) => activateUser(userId, studentId || parentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User activated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to activate user. Please try again.");
    },
  });

  const deactivateMutation = useMutation({
    mutationFn: deactivateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success("User deactivated successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to deactivate user. Please try again.");
    },
  });

  return {
    data: apiResponse.data || [],
    metadata: apiResponse.metadata,
    isLoading,
    error,
    createMutation,
    editMutation,
    deleteMutation,
    activateMutation,
    deactivateMutation,
    pagination: {
      currentPage,
      totalPages,
      nextPage: () => nextPage(totalPages),
      prevPage,
      goToPage: (page) => goToPage(page, totalPages)
    },
  };
};
