import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TableActions } from "../../components/common/TableActions";

const useTableColumns = () => {
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/feestructures/edit/${id}`);
  };

  const handleDelete = (id) => {
    console.log("Delete:", id);
  };

  const columns = useMemo(
    () => [
      {
        header: "Fee Type",
        accessorKey: "fee_type_name",
      },
      {
        header: "Academic Year",
        accessorKey: "academic_year_name",
      },
      {
        header: "Class",
        accessorKey: "class_name",
      },
      {
        header: "Description",
        accessorKey: "description",
      },
      {
        id: "action",
        header: "Action",
        enableSorting: false,
        cell: ({ row }) => (
          <TableActions
            onEdit={() => handleEdit(row.original.id)}
            onDelete={() => handleDelete(row.original.id)}
            editIcon="eye"
          />
        ),
      },
    ],
    []
  );

  return columns;
};

export default useTableColumns;
