import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getTermById } from "../../Api/termServices";
import { useTermQuery } from "../../hooks/term/useTermQuery";
import { getAcademicYears } from "../../Api/academicYearServices";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import LoadingDots from "../common/LoadingDots";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const TermForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useTermQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAcademicYearsExpanded, setIsAcademicYearsExpanded] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      code: "",
      description: "",
      academic_year_terms: [
        {
          academic_year_id: "",
          weightage: "",
        },
      ],
    },
  });

  const { data: termData } = useQuery({
    queryKey: ["term", id],
    queryFn: () => getTermById(id),
    enabled: !!id,
  });

  const {
    data: academicYearsResponse = { data: [] },
    isLoading: isLoadingAcademicYears,
  } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
    staleTime: 5 * 60 * 1000,
  });

  const academicYears = academicYearsResponse.data || [];

  console.log("Academic Years Data:", academicYears);

  const {
    fields: academicYearFields,
    append: appendAcademicYear,
    remove: removeAcademicYear,
  } = useFieldArray({
    control,
    name: "academic_year_terms",
  });

  React.useEffect(() => {
    if (termData) {
      // Ensure academic_year_terms data includes id and version
      const formData = {
        ...termData,
        academic_year_terms:
          termData.academic_year_terms?.map((term) => ({
            id: term.id,
            version: term.version,
            term_id: term.term_id,
            academic_year_id: term.academic_year_id,
            weightage: term.weightage,
          })) || [],
      };
      reset(formData);
      if (formData.academic_year_terms?.length > 0) {
        setIsAcademicYearsExpanded(true);
      }
    }
  }, [termData, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      // Process the data
      const processedData = {
        ...data,
        description: data.description || null,
        version: id ? termData.version : undefined,
        academic_year_terms: data.academic_year_terms
          .filter((year) => year.academic_year_id && year.weightage)
          .map((year) => ({
            ...(year.id && { id: year.id }), // Include id if it exists
            ...(year.version !== undefined && { version: year.version }), // Include version if it exists
            ...(id && { term_id: parseInt(id) }), // Include term_id when editing
            academic_year_id: parseInt(year.academic_year_id),
            weightage: parseFloat(year.weightage),
          })),
      };

      if (id) {
        editMutation.mutate(
          { id, updatedData: processedData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/terms/all");
            },
            onError: () => {
              setIsSubmitting(false);
            },
          }
        );
      } else {
        createMutation.mutate(processedData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/terms/all");
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">
        {id ? "Edit Term" : "Create Term"}
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
      >
        <div className="space-y-6">
          <div>
            <label className="form_label">
              ID <span className="text-red-500">*</span>
            </label>
            <div className="mt-1">
              <input
                type="text"
                {...register("id", {
                  required: "ID is required",
                  pattern: {
                    value: /^[0-9_-]+$/i,
                    message: "ID must be alphanumeric, dashes, or underscores",
                  },
                })}
                className={`form_input ${errors.id && requiredFieldClass}`}
                disabled={!!id}
              />
              {errors.id && (
                <span className={errorMessageClass}>{errors.id.message}</span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="form_label">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`form_input ${errors.name && requiredFieldClass}`}
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <span className={errorMessageClass}>{errors.name.message}</span>
              )}
            </div>

            <div>
              <label className="form_label">
                Code <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  {...register("code", { required: "Code is required" })}
                  className={`form_input ${errors.code && requiredFieldClass}`}
                />
                {errors.code && (
                  <span className={errorMessageClass}>
                    {errors.code.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Second row: Description */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="form_label">Description</label>
              <div className="mt-1">
                <textarea
                  {...register("description")}
                  className="form_input"
                  rows={3}
                />
              </div>
            </div>
          </div>

          <div className="mb-8">
            <button
              type="button"
              className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
              onClick={() =>
                setIsAcademicYearsExpanded(!isAcademicYearsExpanded)
              }
            >
              <h3 className="text-lg font-semibold">
                Academic Years & Weightage
              </h3>
              {isAcademicYearsExpanded ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              )}
            </button>

            {isAcademicYearsExpanded && (
              <div className="mt-4 space-y-4">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      appendAcademicYear({
                        academic_year_id: "",
                        weightage: "",
                      })
                    }
                    className="px-3 py-1 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700"
                  >
                    Add Academic Year
                  </button>
                </div>

                {academicYearFields.map((field, index) => (
                  <div
                    key={field.id}
                    className="p-4 border rounded-lg space-y-4"
                  >
                    <div className="flex justify-between items-center">
                      <h4 className="font-medium">#{index + 1}</h4>
                      <button
                        type="button"
                        onClick={() => removeAcademicYear(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="form_label">Academic Year</label>
                        <select
                          {...register(
                            `academic_year_terms.${index}.academic_year_id`
                          )}
                          className="form_input"
                        >
                          <option value="">Select Academic Year</option>
                          {!isLoadingAcademicYears &&
                            academicYears.map((year) => (
                              <option key={year.id} value={year.id}>
                                {year.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div>
                        <label className="form_label">Weightage (%)</label>
                        <input
                          type="number"
                          step="0.01"
                          {...register(
                            `academic_year_terms.${index}.weightage`
                          )}
                          className="form_input"
                          placeholder="Enter weightage"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end space-x-3 pt-5">
          <button
            type="button"
            onClick={() => navigate("/terms/all")}
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {isSubmitting ? (
              <LoadingDots text={id ? "Updating" : "Creating"} />
            ) : id ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TermForm;
