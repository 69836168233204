import React, { useState } from "react";
import CalendarView from "../../components/Calendar/CalendarView";
import EventList from "../../components/Calendar/EventList";

const Calendar = () => {
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateSelect = (date, events) => {
    setSelectedDate(date);
    setSelectedEvents(events || []);
  };

  const handleEventCreate = () => {
    // Refresh the calendar view when a new event is created
    // The CalendarView component will handle fetching updated events
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">
          School Calendar
        </h1>
        <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
          <CalendarView
            onDateSelect={handleDateSelect}
            onEventCreate={handleEventCreate}
          />
        </div>
        {selectedDate && (
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4">
              Events for {selectedDate}
            </h2>
            <EventList events={selectedEvents} onEventUpdate={handleEventCreate} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
