import axiosInstance from "../lib/axiosConfig";

export const getAllFees = async ({ offset = 0, limit = 10 } = {}) => {
  const response = await axiosInstance.get("/fees/", {
    params: { offset, limit },
  });
  return response.data;
};

export const getFeeById = async (id) => {
  const response = await axiosInstance.get(`/fees/${id}`);
  return response.data;
};

export const createFee = async (data) => {
  const response = await axiosInstance.post("/fees/", data);
  return response.data;
};

export const updateFee = async (id, data) => {
  const response = await axiosInstance.patch(`/fees/${id}/`, data);
  return response.data;
};

export const deleteFee = async (id) => {
  const response = await axiosInstance.delete(`/fees/${id}/`);
  return response.data;
};

export const getFeesByYearAndParent = async ({
  yearId,
  parentId = null,
  offset = 0,
  limit = 10,
} = {}) => {
  const endpoint = parentId
    ? `/fees/years/${yearId}/parents/${parentId}`
    : `/years/${yearId}/fees`;
  const response = await axiosInstance.get(endpoint, {
    params: { offset, limit },
  });
  return response.data;
};

export const getUserFees = async () => {
  try {
    const response = await axiosInstance.get("/fees/users/");
    return response.data;
  } catch (error) {
    throw error;
  }
};
