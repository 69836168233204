import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFeeTypes } from '../../Api/feeTypeServices';
import { useFeeTypeQuery } from '../../hooks/feeType/useFeeTypeQuery';
import { useTableColumns } from '../../hooks/feeType/useTableColumns';
import Table from '../../components/common/Table';
import LoadingDots from '../common/LoadingDots';

export const ListFeeType = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { deleteMutation } = useFeeTypeQuery();
  const columns = useTableColumns();

  const {
    data: feeTypesData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['feetypes', { page: currentPage }],
    queryFn: () => getFeeTypes(currentPage),
    keepPreviousData: true,
    staleTime: 5000, // Consider data fresh for 5 seconds
  });

  // Default pagination values
  const pagination = {
    currentPage: feeTypesData?.currentPage || currentPage,
    totalPages: feeTypesData?.totalPages || 1,
    nextPage: () => {
      if (currentPage < (feeTypesData?.totalPages || 1)) {
        setCurrentPage(old => old + 1);
      }
    },
    prevPage: () => {
      if (currentPage > 1) {
        setCurrentPage(old => old - 1);
      }
    },
    goToPage: (page) => {
      if (page >= 1 && page <= (feeTypesData?.totalPages || 1)) {
        setCurrentPage(page);
      }
    },
  };

  const handleLink = () => '/feetypes/create';

  if (isError) {
    return <div>Error loading fee types</div>;
  }

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="container mx-auto p-4">
      <Table
        data={feeTypesData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Fee Type"
        baseEditPath="/feetypes/edit"
      />
    </div>
  );
};
