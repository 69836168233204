import axiosInstance from "../lib/axiosConfig";

export const createStudentParent = async (data) => {
  const response = await axiosInstance.post('/studentparents/', data);
  return response.data;
};

export const getStudentParents = async () => {
  const response = await axiosInstance.get('/studentparents/');
  return response.data;
};
