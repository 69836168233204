import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getEnrollments,
  createEnrollment,
  updateEnrollment,
  deleteEnrollment,
} from "../../Api/enrollmentServices";
import { toast } from "react-toastify";

export const useEnrollmentQuery = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["enrollments", currentPage],
    queryFn: () => getEnrollments(currentPage),
  });

  const createMutation = useMutation({
    mutationFn: createEnrollment,
    onSuccess: () => {
      queryClient.invalidateQueries(["enrollments"]);
      toast.success("Enrollment created successfully!");
    },
    onError: (error) => {
      if (error.response?.status === 422 && error.response?.data?.detail) {
        const detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail.forEach(err => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          toast.error(detail.msg || JSON.stringify(detail));
        }
      } else {
        toast.error(error.response?.data?.message || "Failed to create enrollment. Please try again.");
      }
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, data }) => updateEnrollment(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["enrollments"]);
      toast.success("Enrollment updated successfully!");
    },
    onError: (error) => {
      if (error.response?.status === 422 && error.response?.data?.detail) {
        const detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail.forEach(err => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          toast.error(detail.msg || JSON.stringify(detail));
        }
      } else {
        toast.error(error.response?.data?.message || "Failed to update enrollment. Please try again.");
      }
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteEnrollment,
    onSuccess: () => {
      queryClient.invalidateQueries(["enrollments"]);
      toast.success("Enrollment deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to delete enrollment. Please try again.");
    },
  });

  return {
    data: data?.data || [],
    isLoading,
    currentPage,
    setCurrentPage,
    totalPages: data?.totalPages || 1,
    createMutation,
    editMutation,
    deleteMutation,
  };
};
