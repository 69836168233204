import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getFeeStructures } from "../../Api/feeStructureServices";
import useTableColumns from "../../hooks/feeStructure/useTableColumns";
import Table from "../../components/common/Table";

const ListFeeStructure = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const columns = useTableColumns();

  const { data: feeStructureData, isLoading } = useQuery({
    queryKey: ["feeStructures", currentPage],
    queryFn: () => getFeeStructures(currentPage),
    refetchOnMount: true,
  });

  // Default pagination values
  const pagination = {
    currentPage: feeStructureData?.currentPage || 1,
    totalPages: feeStructureData?.totalPages || 1,
    nextPage: () =>
      setCurrentPage((old) =>
        Math.min(old + 1, feeStructureData?.totalPages || 1)
      ),
    prevPage: () => setCurrentPage((old) => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  const handleLink = () => "/feestructures/create";

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <Table
        data={feeStructureData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Fee Structure"
        baseEditPath="/feestructures/edit"
      />
    </div>
  );
};

export default ListFeeStructure;
