import axiosInstance from "../lib/axiosConfig";

export const getFeeTypes = async (page = 1, limit = 10) => {
  const offset = (page - 1) * limit;
  const response = await axiosInstance.get(`feetypes/`, {
    params: {
      offset,
      limit,
    },
  });

  return {
    data: response.data.data,
    currentPage: response.data.metadata.current_page,
    totalPages: response.data.metadata.total_pages,
    totalCount: response.data.metadata.count,
  };
};

export const getFeeType = async (id) => {
  const response = await axiosInstance.get(`feetypes/${id}`);
  return response.data;
};

export const createFeeType = async (feeTypeData) => {
  const response = await axiosInstance.post("feetypes/", feeTypeData);
  return response.data;
};

export const updateFeeType = async (id, feeTypeData) => {
  const response = await axiosInstance.patch(`feetypes/${id}`, feeTypeData);
  return response.data;
};

export const deleteFeeType = async (id) => {
  const response = await axiosInstance.delete(`feetypes/${id}`);
  return response.data;
};
