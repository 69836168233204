import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useFeeTypeQuery } from "../../hooks/feeType/useFeeTypeQuery";
import { getFeeType } from "../../Api/feeTypeServices";
import { getClasses } from "../../Api/classServices";
import { getAcademicYears } from "../../Api/academicYearServices";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import LoadingDots from "../common/LoadingDots";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const currencies = [
  { value: "NPR", label: "Nepalese Rupee (NPR)" },
  { value: "USD", label: "US Dollar (USD)" },
  { value: "EUR", label: "Euro (EUR)" },
  { value: "INR", label: "Indian Rupee (INR)" },
];

const FeeTypeForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useFeeTypeQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFeeStructuresExpanded, setIsFeeStructuresExpanded] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      currency: "NPR",
      amount: "",
      description: "",
      fee_structures: [{ academic_year_id: "", class_id: "", description: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fee_structures",
  });

  const { data: selectedFeeType, isLoading: isLoadingFeeType } = useQuery({
    queryKey: ["feetypes", id],
    queryFn: () => getFeeType(id),
    enabled: !!id,
  });

  const { data: classesData = { data: [] }, isLoading: isLoadingClasses } =
    useQuery({
      queryKey: ["classes"],
      queryFn: () => getClasses(1, 100),
      staleTime: 5 * 60 * 1000,
    });

  const {
    data: academicYearsData = { data: [] },
    isLoading: isLoadingAcademicYears,
  } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
    staleTime: 5 * 60 * 1000,
  });

  const academicYears = academicYearsData?.data || [];
  const classes = classesData?.data || [];

  useEffect(() => {
    if (selectedFeeType && !isLoadingClasses && !isLoadingAcademicYears) {
      const [currency, amount] = selectedFeeType.amount.split(" ");
      const formattedData = {
        name: selectedFeeType.name,
        currency,
        amount,
        description: selectedFeeType.description || "",
        fee_structures:
          selectedFeeType.fee_structures?.length > 0
            ? selectedFeeType.fee_structures
            : [{ academic_year_id: "", class_id: "", description: "" }],
      };
      reset(formattedData);
      if (formattedData.fee_structures?.length > 0) {
        setIsFeeStructuresExpanded(true);
      }
    }
  }, [selectedFeeType, isLoadingClasses, isLoadingAcademicYears, reset]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const formattedData = {
        ...data,
        amount: `${data.currency} ${data.amount}`,
        description: data.description || null,
        fee_structures: data.fee_structures.filter(
          (fs) => fs.academic_year_id && fs.class_id
        ),
      };

      if (id) {
        await editMutation.mutateAsync({
          id,
          updatedData: {
            ...formattedData,
            version: selectedFeeType.version,
          },
        });
      } else {
        await createMutation.mutateAsync(formattedData);
      }
      navigate("/feetypes/all");
    } catch (error) {
      console.error("Failed to save fee type:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoadingFeeType || isLoadingClasses || isLoadingAcademicYears) {
    return <LoadingDots />;
  }

  return (
    <div className="max-w-2xl mx-auto bg-white shadow-sm rounded-lg">
      <div className="p-6">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            {id ? "Edit Fee Type" : "Create Fee Type"}
          </h2>

          {/* Name Field */}
          <div className="space-y-2">
            <label htmlFor="name" className="form_label">
              Name
            </label>
            <input
              type="text"
              id="name"
              {...register("name", { required: "Name is required" })}
              className={`form_input ${errors.name ? requiredFieldClass : ""}`}
            />
            {errors.name && (
              <p className={errorMessageClass}>{errors.name.message}</p>
            )}
          </div>

          {/* Amount Fields */}
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label htmlFor="currency" className="form_label">
                Currency
              </label>
              <select
                id="currency"
                {...register("currency")}
                className="form_input"
              >
                {currencies.map((currency) => (
                  <option key={currency.value} value={currency.value}>
                    {currency.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-2">
              <label htmlFor="amount" className="form_label">
                Amount
              </label>
              <input
                type="number"
                id="amount"
                step="0.01"
                {...register("amount", { required: "Amount is required" })}
                className={`form_input ${
                  errors.amount ? requiredFieldClass : ""
                }`}
              />
              {errors.amount && (
                <p className={errorMessageClass}>{errors.amount.message}</p>
              )}
            </div>
          </div>

          {/* Description Field */}
          <div className="space-y-2">
            <label htmlFor="description" className="form_label">
              Description
            </label>
            <textarea
              id="description"
              {...register("description")}
              className="form_input h-24"
            />
          </div>

          {/* Fee Structures Section */}
          <div className="border rounded-lg p-4">
            <button
              type="button"
              className="flex items-center justify-between w-full text-left text-lg font-medium text-gray-900 focus:outline-none"
              onClick={() =>
                setIsFeeStructuresExpanded(!isFeeStructuresExpanded)
              }
            >
              <span>Fee Structures</span>
              {isFeeStructuresExpanded ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>

            {isFeeStructuresExpanded && (
              <div className="mt-4 space-y-4">
                {fields.map((field, index) => (
                  <div key={field.id} className="flex space-x-4">
                    <div className="flex-1">
                      <label className="form_label">Academic Year</label>
                      <select
                        {...register(
                          `fee_structures.${index}.academic_year_id`
                        )}
                        className="form_input"
                      >
                        <option value="">Select Academic Year</option>
                        {academicYears.map((year) => (
                          <option key={year.id} value={year.id}>
                            {year.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex-1">
                      <label className="form_label">Class</label>
                      <select
                        {...register(`fee_structures.${index}.class_id`)}
                        className="form_input"
                      >
                        <option value="">Select Class</option>
                        {classes.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {cls.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="flex-1">
                      <label className="form_label">Description</label>
                      <input
                        type="text"
                        {...register(`fee_structures.${index}.description`)}
                        className="form_input"
                        placeholder="Optional description"
                      />
                    </div>

                    <div className="flex items-end">
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="mb-2 px-2 py-1 text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={() =>
                    append({
                      academic_year_id: "",
                      class_id: "",
                      description: "",
                    })
                  }
                  className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Add Fee Structure
                </button>
              </div>
            )}
          </div>

          {/* Submit and Cancel Buttons */}
          <div className="flex justify-end gap-x-2">
            <button
              type="button"
              onClick={() => navigate("/feetypes/all")}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? "Saving..." : "Save Fee Type"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeeTypeForm;
