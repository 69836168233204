import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserById } from "../../Api/userServices";
import { useUserQuery } from "../../hooks/user/useUserQuery";
import { useUserProfileImage } from "../../hooks/user/useUserProfileImage";
import { uploadAttachment } from "../../Api/attachmentServices";
import LoadingDots from "../common/LoadingDots";
import { useAuth } from "../../context/authContext";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const UserForm = ({ isProfile = false }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createMutation, editMutation } = useUserQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const { user: currentUser } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
      username: "",
      first_name: "",
      last_name: "",
      phone: "",
      roles: ["user"],
      status: "active",
      password: "",
    },
  });

  const { data: userData, isLoading } = useQuery({
    queryKey: ["users", isProfile ? "me" : id],
    queryFn: () => (isProfile ? Promise.resolve(currentUser) : getUserById(id)),
    enabled: isProfile || !!id,
  });

  const { data: profilePicture, isLoading: isLoadingProfilePicture } =
    useUserProfileImage(isProfile ? userData?.id : id);

  const isAdmin = currentUser?.roles?.includes("admin");

  React.useEffect(() => {
    if (userData) {
      // Ensure roles is properly set from the array
      const formData = {
        ...userData,
        roles: userData.roles?.[0] || "user", // Take the first role from the array
      };
      reset(formData);
    }
  }, [userData, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      const processedData = {
        ...data,
        roles: [data.roles], // Ensure roles is an array
      };

      if (id || isProfile) {
        // Remove password if it's empty in edit mode
        if (!processedData.password) {
          delete processedData.password;
        }

        editMutation.mutate(
          { id: isProfile ? userData.id : id, updatedData: processedData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              if (!isProfile) navigate("/users/all");
            },
            onError: () => {
              setIsSubmitting(false);
            },
          }
        );
      } else {
        createMutation.mutate(processedData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/users/all");
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitting(false);
    }
  };

  if (isLoading || isLoadingProfilePicture) {
    return <LoadingDots />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto p-6 bg-white shadow-sm rounded-lg"
    >
      {/* Profile Picture Section */}
      {(id || isProfile) && (
        <div className="mb-8">
          <div className="flex items-start">
            <div className="relative">
              {profilePicture ? (
                <div className="relative">
                  <img
                    src={profilePicture}
                    alt="Profile"
                    className="w-24 h-24 rounded-full object-cover cursor-pointer hover:opacity-90 transition-opacity"
                    onClick={() => setShowImageModal(true)}
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                    <span className="text-white bg-black bg-opacity-50 px-2 py-1 rounded text-sm">
                      Click to enlarge
                    </span>
                  </div>
                </div>
              ) : (
                <div className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center">
                  <svg
                    className="w-12 h-12 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </div>
              )}
              <label className="absolute bottom-0 right-0 bg-blue-600 rounded-full p-2 cursor-pointer hover:bg-blue-700 transition-colors">
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    const userId = isProfile ? userData?.id : id;
                    if (file && userId) {
                      setUploadingPhoto(true);
                      uploadAttachment(userId, file, "profile_picture")
                        .then(() => {
                          queryClient.invalidateQueries([
                            "userProfileImage",
                            userId,
                          ]);
                          setUploadingPhoto(false);
                        })
                        .catch((error) => {
                          console.error("Error uploading photo:", error);
                          alert("Failed to upload photo. Please try again.");
                          setUploadingPhoto(false);
                        });
                    }
                  }}
                />
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </label>
              {uploadingPhoto && (
                <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
                  <LoadingDots />
                </div>
              )}
            </div>
            <div className="ml-6">
              <h3 className="text-lg font-medium text-gray-900">
                Profile Picture
              </h3>
              <p className="text-sm text-gray-500">
                Upload a new profile picture
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Image Modal */}
      {showImageModal && profilePicture && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setShowImageModal(false)}
        >
          <div className="relative bg-white p-2 rounded-lg max-w-2xl max-h-[90vh]">
            <img
              src={profilePicture}
              alt="Profile"
              className="max-w-full max-h-[80vh] object-contain"
            />
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => setShowImageModal(false)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Email & Username */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label className="form_label">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            className={`form_input ${errors.email && requiredFieldClass}`}
            {...register("email", { required: "Email is required" })}
          />
          {errors.email && (
            <span className={errorMessageClass}>{errors.email.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">
            Username <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={`form_input ${errors.username && requiredFieldClass}`}
            {...register("username", { required: "Username is required" })}
          />
          {errors.username && (
            <span className={errorMessageClass}>{errors.username.message}</span>
          )}
        </div>
      </div>

      {/* First Name & Last Name */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label className="form_label">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={`form_input ${errors.first_name && requiredFieldClass}`}
            {...register("first_name", { required: "First name is required" })}
          />
          {errors.first_name && (
            <span className={errorMessageClass}>
              {errors.first_name.message}
            </span>
          )}
        </div>

        <div>
          <label className="form_label">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={`form_input ${errors.last_name && requiredFieldClass}`}
            {...register("last_name", { required: "Last name is required" })}
          />
          {errors.last_name && (
            <span className={errorMessageClass}>
              {errors.last_name.message}
            </span>
          )}
        </div>
      </div>

      {/* Phone */}
      <div>
        <label className="form_label">
          Phone <span className="text-red-500">*</span>
        </label>
        <input
          type="tel"
          className={`form_input ${errors.phone && requiredFieldClass}`}
          {...register("phone", { required: "Phone is required" })}
        />
        {errors.phone && (
          <span className={errorMessageClass}>{errors.phone.message}</span>
        )}
      </div>

      {/* Password - Only show in create mode or if editing */}
      {!id && !isProfile && (
        <div>
          <label className="form_label">
            Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            className={`form_input ${errors.password && requiredFieldClass}`}
            {...register("password", {
              required: !id && !isProfile && "Password is required",
            })}
          />
          {errors.password && (
            <span className={errorMessageClass}>{errors.password.message}</span>
          )}
        </div>
      )}

      {/* Roles */}
      <div>
        <label className="form_label">
          Role <span className="text-red-500">*</span>
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-2">
          {["admin", "student", "teacher", "financier", "parent"].map(
            (role) => (
              <div key={role} className="flex items-center space-x-2">
                <input
                  type="radio"
                  value={role}
                  {...register("roles")}
                  defaultChecked={userData?.roles?.includes(role)}
                  disabled={!isAdmin}
                  className="w-4 h-4 text-blue-600 rounded-full focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                />
                <label className="text-sm text-gray-600 capitalize">
                  {role === "teacher" ? "staff" : role}
                </label>
              </div>
            )
          )}
        </div>
        {!isAdmin && (
          <p className="text-sm text-gray-500 mt-1">
            Only administrators can modify roles.
          </p>
        )}
        {errors.roles && (
          <span className={errorMessageClass}>{errors.roles.message}</span>
        )}
      </div>

      {/* Status */}
      <div className="mt-4">
        <label className="form_label">
          Status <span className="text-red-500">*</span>
        </label>
        <select
          className={`form_input ${errors.status && requiredFieldClass}`}
          {...register("status", { required: "Status is required" })}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="pending">Pending</option>
        </select>
        {errors.status && (
          <span className={errorMessageClass}>{errors.status.message}</span>
        )}
      </div>

      {/* Submit Button */}
      <div className="mt-8 flex justify-end space-x-4">
        <button
          type="button"
          onClick={() => (isAdmin ? navigate("/users/all") : navigate("/"))}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {isSubmitting
            ? "Saving..."
            : isProfile
            ? "Update Profile"
            : id
            ? "Update User"
            : "Create User"}
        </button>
      </div>
    </form>
  );
};

export default UserForm;
