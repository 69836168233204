import axiosInstance from "../lib/axiosConfig";

export const getTerms = async (page = 1, limit = 10) => {
  const offset = page && !isNaN(page) ? (page - 1) * limit : 0;
  const response = await axiosInstance.get(`terms/`, {
    params: {
      offset,
      limit,
    },
  });

  return {
    data: response.data.data,
    currentPage: response.data.metadata.current_page,
    totalPages: response.data.metadata.total_pages,
    totalCount: response.data.metadata.count,
  };
};

export const getAllTerms = async () => {
  const response = await axiosInstance.get(`terms/`, {
    params: {
      offset: 0,
      limit: 1000, // Using a large number to get all records
    },
  });
  return response.data.data;
};

export const getTermById = async (id) => {
  const response = await axiosInstance.get(`terms/${id}`);
  return response.data;
};

export const createTerm = async (termData) => {
  const response = await axiosInstance.post("terms/", termData);
  return response.data;
};

export const updateTerm = async (id, termData) => {
  const response = await axiosInstance.patch(`terms/${id}`, termData);
  return response.data;
};

export const deleteTerm = async (id) => {
  const response = await axiosInstance.delete(`terms/${id}`);
  return response.data;
};
