import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TableActions } from "../../components/common/TableActions";
import confirmDelete from "../../lib/confirmDelete";

export const useTableColumns = (deleteMutation) => {
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/parent/edit/${id}`);
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error),
        });
      });
    });
  };

  return useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        id: "dob",
        header: "Date of Birth",
        accessorKey: "dob",
      },
      {
        id: "email",
        header: "Email",
        accessorKey: "email",
      },
      {
        id: "gender",
        header: "Gender",
        accessorKey: "gender",
      },
      {
        id: "phone",
        header: "Phone",
        accessorKey: "phone",
      },
      {
        id: "action",
        header: "Action",
        enableSorting: false,
        canHide: false,
        cell: ({ row }) => (
          <TableActions
            onEdit={() => handleEdit(row.original.id)}
            onDelete={() => handleDelete(row.original.id)}
          />
        ),
      },
    ],
    [deleteMutation, handleDelete]
  );
};
