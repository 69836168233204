import axios from "axios";

// Get the API URL from runtime config
const API_URL = window.REACT_APP_CONFIG?.API_URL;
console.log("API_URL", API_URL);
// Create an Axios instance with dynamic baseURL
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the token if available
axiosInstance.interceptors.request.use(
  (config) => {
    // Don't try to add token for auth endpoints
    if (config.url?.includes("auth/token")) {
      return config;
    }

    try {
      const userStr = localStorage.getItem("user");
      if (userStr) {
        const user = JSON.parse(userStr);
        if (user?.access_token) {
          config.headers.Authorization = `Bearer ${user.access_token}`;
        }
      }
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Skip 401 handling for login endpoint
    if (
      error.response?.status === 401 &&
      !error.config.url?.includes("auth/token")
    ) {
      localStorage.clear(); // Clear all stored data
      window.location.href = "/login"; // Force redirect to login
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
