import React, { useState, useEffect } from "react";
import { getCurrentNepaliDate } from "../../utils/dateConverter";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import {
  getAttendances,
  markAttendance,
  updateAttendance,
  verifyAttendance,
} from "../../Api/attendanceServices";

const AttendanceCard = ({ attendance, isCheckingOut, isAdmin, onVerify }) => (
  <div
    className={`bg-white rounded-lg shadow-lg p-6 mb-4 transition-colors duration-300 ${
      isCheckingOut ? "animate-highlight" : ""
    }`}
  >
    <div className="space-y-4">
      <div className="flex justify-between items-start">
        <div className="space-y-2">
          <div className="flex items-center space-x-4">
            <p className="text-gray-600">
              <span className="font-semibold">Date:</span> {attendance.date}
            </p>
            {attendance.teacher && (
              <p className="text-blue-600 font-medium">
                {`${attendance.teacher.first_name} ${attendance.teacher.last_name}`}
              </p>
            )}
            {attendance.student && (
              <p className="text-green-600 font-medium">
                {`${attendance.student.first_name} ${attendance.student.last_name}`}
              </p>
            )}
          </div>
          <div className="flex items-center space-x-4">
            <p>
              <span className="font-semibold">Mode:</span>{" "}
              <span className="capitalize">{attendance.mode}</span>
            </p>
            <p>
              <span className="font-semibold">Status:</span>{" "}
              <span className="capitalize">{attendance.status}</span>
            </p>
          </div>
        </div>
        {isAdmin && !attendance.is_verified && (
          <button
            onClick={() => onVerify(attendance.id)}
            className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center space-x-1 ml-4"
          >
            <svg
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span>Verify</span>
          </button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <p>
            <span className="font-semibold">Check-in Time:</span>{" "}
            {new Date(attendance.check_in_time).toLocaleTimeString()}
          </p>
          {attendance.check_out_time && (
            <p>
              <span className="font-semibold">Check-out Time:</span>{" "}
              {new Date(attendance.check_out_time).toLocaleTimeString()}
            </p>
          )}
        </div>

        <div className="space-y-2">
          <p>
            <span className="font-semibold">Location:</span>{" "}
            {`${attendance.check_in_latitude}, ${attendance.check_in_longitude}`}
          </p>
          <div className="flex items-center">
            <span className="font-semibold mr-2">Verified:</span>{" "}
            {attendance.is_verified ? (
              <svg
                className="h-5 w-5 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            ) : (
              "No"
            )}
          </div>
        </div>
      </div>

      {attendance.notes && (
        <p className="mt-2 text-gray-600">
          <span className="font-semibold">Notes:</span> {attendance.notes}
        </p>
      )}
    </div>
  </div>
);

const Attendance = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGettingLocation, setIsGettingLocation] = useState(false);
  const [checkingOutId, setCheckingOutId] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  // Get user role from localStorage
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const isAdmin = user?.roles?.includes("admin");

  // Fetch past attendances
  const { data: attendanceResponse, refetch: refetchAttendances, isLoading } = useQuery({
    queryKey: ["attendances", currentPage],
    queryFn: async () => {
      const result = await getAttendances({
        offset: (currentPage - 1) * ITEMS_PER_PAGE,
        limit: ITEMS_PER_PAGE,
      });
      return result;
    },
    keepPreviousData: true,
    staleTime: 0, // Consider data stale immediately
    cacheTime: 5 * 60 * 1000, // Cache for 5 minutes
  });

  // Reset to first page when refetching all data
  const handleRefetch = async () => {
    setCurrentPage(1);
    await refetchAttendances();
  };

  // Effect to refetch when page changes
  useEffect(() => {
    refetchAttendances();
  }, [currentPage, refetchAttendances]);

  const pastAttendances = attendanceResponse?.data || [];
  const metadata = attendanceResponse?.metadata || {};
  const totalPages = metadata.total_pages || 1;

  // Check if the last attendance is a check-in without checkout
  const lastAttendance = pastAttendances[0];
  const canCheckOut =
    lastAttendance?.mode === "in" && !lastAttendance?.check_out_time;

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser"));
        return;
      }

      setIsGettingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setIsGettingLocation(false);
        },
        (error) => {
          setIsGettingLocation(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              reject(
                new Error("Please allow location access to mark attendance")
              );
              break;
            case error.POSITION_UNAVAILABLE:
              reject(new Error("Location information is unavailable"));
              break;
            case error.TIMEOUT:
              reject(new Error("Location request timed out"));
              break;
            default:
              reject(new Error("An unknown error occurred"));
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    });
  };

  const handleMarkAttendance = async () => {
    if (isSubmitting || isGettingLocation) return;
    setIsSubmitting(true);

    try {
      const location = await getCurrentLocation();
      const currentDate = getCurrentNepaliDate();
      const now = new Date();

      if (canCheckOut && lastAttendance) {
        setCheckingOutId(lastAttendance.id);
        // Update the last attendance with check-out time
        await updateAttendance(lastAttendance.id, {
          check_out_time: now.toISOString(),
          check_out_latitude: location.latitude,
          check_out_longitude: location.longitude,
          mode: "out",
          version: lastAttendance.version,
        });
        toast.success("Check-out marked successfully!");
        setTimeout(() => setCheckingOutId(null), 1000); // Reset after animation
      } else {
        // Create new check-in
        const payload = {
          date: currentDate,
          check_in_time: now.toISOString(),
          check_out_time: null,
          status: "present",
          check_in_latitude: location.latitude,
          check_in_longitude: location.longitude,
          check_out_latitude: null,
          check_out_longitude: null,
          mode: "in",
          is_verified: false,
          notes: "",
          teacher_id: null,
          student_id: null,
        };
        await markAttendance(payload);
        toast.success("Check-in marked successfully!");
      }
      refetchAttendances();
    } catch (error) {
      console.error("Error marking attendance:", error);
      if (error.message && !error.response) {
        toast.error(error.message);
      } else if (error.response?.status === 409) {
        toast.error(
          "This record has been modified. Please refresh and try again."
        );
      } else if (
        error.response?.status === 422 &&
        error.response?.data?.detail
      ) {
        const detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail.forEach((err) => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          toast.error(error.response.data.detail);
        }
      } else {
        toast.error(
          error.response?.data?.message ||
            "Failed to mark attendance. Please try again."
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerifyAttendance = async (id) => {
    if (isVerifying) return;
    setIsVerifying(true);

    try {
      const attendance = pastAttendances.find((a) => a.id === id);
      if (!attendance) {
        throw new Error("Attendance record not found");
      }

      await verifyAttendance(id, attendance.version);
      toast.success("Attendance verified successfully!");
      refetchAttendances();
    } catch (error) {
      console.error("Error verifying attendance:", error);
      if (error.response?.status === 409) {
        toast.error(
          "This record has been modified. Please refresh and try again."
        );
      } else {
        toast.error(
          error.response?.data?.message ||
            "Failed to verify attendance. Please try again."
        );
      }
    } finally {
      setIsVerifying(false);
    }
  };

  const buttonDisabled = isSubmitting || isGettingLocation;
  const buttonText = isGettingLocation
    ? "Getting Location..."
    : isSubmitting
    ? canCheckOut
      ? "Marking Check-out..."
      : "Marking Check-in..."
    : canCheckOut
    ? "Mark Check-out"
    : "Mark Check-in";

  return (
    <div className="container mx-auto px-6 py-8">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Attendance</h1>

      {!isAdmin && (
        <button
          onClick={handleMarkAttendance}
          disabled={buttonDisabled}
          className={`flex items-center gap-2 px-6 py-2 rounded-lg font-medium mb-8 ${
            buttonDisabled
              ? "bg-gray-300 cursor-not-allowed"
              : canCheckOut
              ? "bg-red-600 hover:bg-red-700 text-white"
              : "bg-blue-600 hover:bg-blue-700 text-white"
          }`}
        >
          {buttonDisabled ? (
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
          ) : canCheckOut ? (
            <svg
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
          ) : (
            <svg
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
          )}
          {buttonText}
        </button>
      )}

      {pastAttendances?.length > 0 && (
        <div className="space-y-4">
          <p className="text-xs text-gray-500 mb-2">
            Check in as you arrive and check out before you leave.
          </p>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            Past Attendances
          </h2>
          <style>
            {`
              @keyframes highlight {
                0% { background-color: white; }
                50% { background-color: #93c5fd; }
                100% { background-color: white; }
              }
              .animate-highlight {
                animation: highlight 1s ease-in-out;
              }
            `}
          </style>
          {pastAttendances.map((attendance) => (
            <AttendanceCard
              key={attendance.id}
              attendance={attendance}
              isCheckingOut={attendance.id === checkingOutId}
              isAdmin={isAdmin}
              onVerify={handleVerifyAttendance}
            />
          ))}

          {/* Pagination */}
          <div className="mt-6 flex flex-col space-y-4">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <div className="text-sm text-gray-700 mb-4 sm:mb-0">
                Showing{" "}
                <span className="font-medium">
                  {(currentPage - 1) * ITEMS_PER_PAGE + 1}
                </span>{" "}
                to{" "}
                <span className="font-medium">
                  {Math.min(currentPage * ITEMS_PER_PAGE, metadata.count || 0)}
                </span>{" "}
                of <span className="font-medium">{metadata.count || 0}</span> results
              </div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                {/* Previous Page */}
                <button
                  onClick={() => setCurrentPage((page) => Math.max(1, page - 1))}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium ${
                    currentPage === 1
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-white text-gray-500 hover:bg-gray-50"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Page Numbers - Show differently based on screen size and total pages */}
                {totalPages <= 7 ? (
                  // If 7 or fewer pages, show all
                  [...Array(totalPages)].map((_, idx) => {
                    const pageNumber = idx + 1;
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => {
                          if (currentPage !== pageNumber) {
                            setCurrentPage(pageNumber);
                          }
                        }}
                        disabled={isLoading}
                        className={`relative hidden sm:inline-flex items-center px-4 py-2 border text-sm font-medium ${
                          currentPage === pageNumber
                            ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                        } ${isLoading ? "cursor-wait" : ""}`}
                      >
                        {pageNumber}
                      </button>
                    );
                  })
                ) : (
                  // If more than 7 pages, show smart pagination
                  <>
                    {/* First page */}
                    <button
                      onClick={() => {
                        if (currentPage !== 1) {
                          setCurrentPage(1);
                        }
                      }}
                      disabled={isLoading}
                      className={`relative hidden sm:inline-flex items-center px-4 py-2 border text-sm font-medium ${
                        currentPage === 1
                          ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      } ${isLoading ? "cursor-wait" : ""}`}
                    >
                      1
                    </button>

                    {/* Ellipsis or number */}
                    {currentPage > 3 && (
                      <span className="relative hidden sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span>
                    )}

                    {/* Current page and surrounding */}
                    {[...Array(5)].map((_, idx) => {
                      const pageNum = Math.min(
                        Math.max(currentPage - 2 + idx, 2),
                        totalPages - 1
                      );
                      if (pageNum <= 1 || pageNum >= totalPages) return null;
                      if (pageNum < currentPage - 2 || pageNum > currentPage + 2)
                        return null;
                      return (
                        <button
                          key={pageNum}
                          onClick={() => {
                            if (currentPage !== pageNum) {
                              setCurrentPage(pageNum);
                            }
                          }}
                          disabled={isLoading}
                          className={`relative hidden sm:inline-flex items-center px-4 py-2 border text-sm font-medium ${
                            currentPage === pageNum
                              ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                              : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                          } ${isLoading ? "cursor-wait" : ""}`}
                        >
                          {pageNum}
                        </button>
                      );
                    })}

                    {/* Ellipsis or number */}
                    {currentPage < totalPages - 2 && (
                      <span className="relative hidden sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span>
                    )}

                    {/* Last page */}
                    <button
                      onClick={() => {
                        if (currentPage !== totalPages) {
                          setCurrentPage(totalPages);
                        }
                      }}
                      disabled={isLoading}
                      className={`relative hidden sm:inline-flex items-center px-4 py-2 border text-sm font-medium ${
                        currentPage === totalPages
                          ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      } ${isLoading ? "cursor-wait" : ""}`}
                    >
                      {totalPages}
                    </button>
                  </>
                )}

                {/* Current page indicator for mobile */}
                <span className="relative inline-flex sm:hidden items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  {isLoading ? (
                    "Loading..."
                  ) : (
                    `Page ${currentPage} of ${totalPages}`
                  )}
                </span>

                {/* Next Page */}
                <button
                  onClick={() => setCurrentPage((page) => Math.min(totalPages, page + 1))}
                  disabled={currentPage === totalPages}
                  className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium ${
                    currentPage === totalPages
                      ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                      : "bg-white text-gray-500 hover:bg-gray-50"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Attendance;
