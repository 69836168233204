import axiosInstance from "../lib/axiosConfig";

export const getBusRoutes = async (page = 1, limit = 10) => {
  const offset = (page - 1) * limit;
  try {
    const response = await axiosInstance.get(`busroutes/`, {
      params: {
        offset,
        limit,
      },
    });

    return {
      data: response.data.data,
      currentPage: response.data.metadata.current_page,
      totalPages: response.data.metadata.total_pages,
      totalCount: response.data.metadata.count
    };
  } catch (error) {
    throw error;
  }
};

export const getBusRouteById = async (id) => {
  try {
    const response = await axiosInstance.get(`busroutes/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBusRoute = async (data) => {
  try {
    const response = await axiosInstance.post('busroutes/', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBusRoute = async (id, data) => {
  try {
    const response = await axiosInstance.patch(`busroutes/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBusRoute = async (id) => {
  try {
    const response = await axiosInstance.delete(`busroutes/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
