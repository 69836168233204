import React from "react";
import { useParams } from "react-router-dom";
import BusRouteForm from "../../components/BusRoute/BusRouteForm";

const CreateBusRoute = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);

  return (
    <div className="py-6">
      <BusRouteForm id={id} />
    </div>
  );
};

export default CreateBusRoute;
