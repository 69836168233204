import axiosInstance from "../lib/axiosConfig";

export const sendMessage = async (content, receiverId, receiverName) => {
  const userStr = localStorage.getItem("user");
  const userData = userStr ? JSON.parse(userStr) : null;
  const senderId = userData?.id;
  const senderName = userData ? `${userData.first_name} ${userData.last_name}` : null;

  if (!senderId || !senderName) {
    throw new Error("Sender information not found. Please log in again.");
  }

  const response = await axiosInstance.post("messages/", {
    content,
    receiver_id: receiverId,
    sender_id: senderId,
    status: "sent",
    sender_name: senderName,
    receiver_name: receiverName
  });
  return response.data;
};

export const getAllMessages = async () => {
  const response = await axiosInstance.get("messages/all/messages");
  return response.data;
};

export const getMessageThread = async (receiverId) => {
  const response = await axiosInstance.get(`messages/${receiverId}/messages`);
  return response.data;
};
