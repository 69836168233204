import axiosInstance from "../lib/axiosConfig";

// Function to get all process steps
export const getAllProcessSteps = async ({ offset = 0, limit = 10 }) => {
  try {
    const { data } = await axiosInstance.get("processsteps/", {
      params: {
        offset,
        limit,
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching process steps:", error);
    throw error;
  }
};

// Function to create a process step
export const createProcessStep = async (processStepData) => {
  try {
    const { data } = await axiosInstance.post("processsteps/", processStepData);
    return data;
  } catch (error) {
    console.error("Error creating process step:", error);
    throw error;
  }
};

// Function to get a single process step
export const getProcessStepById = async (id) => {
  try {
    const { data } = await axiosInstance.get(`processsteps/${id}`);
    return data;
  } catch (error) {
    console.error("Error fetching process step:", error);
    throw error;
  }
};

// Function to update a process step
export const updateProcessStep = async (id, processStepData) => {
  try {
    const { data } = await axiosInstance.patch(
      `processsteps/${id}`,
      processStepData
    );
    return data;
  } catch (error) {
    console.error("Error updating process step:", error);
    throw error;
  }
};

// Function to delete a process step
export const deleteProcessStep = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`processsteps/${id}`);
    return data;
  } catch (error) {
    console.error("Error deleting process step:", error);
    throw error;
  }
};
