// src/pages/Dashboard.js
import React, { useEffect, useState } from "react";
import axiosInstance from "../lib/axiosConfig";
import LoadingDots from "../components/common/LoadingDots";
import TeacherAttendanceModal from "../components/TeacherAttendanceModal";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#E6B34D",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
];
const STATUS_COLORS = {
  drafted: "#D4AF37",
  paid: "#00C49F",
  unpaid: "#ff4d4d",
  overdue: "#ff0000",
  processing: "#00b2fe",
  failed: "#666666",
  refunded: "#8884d8",
};

const formatAmount = (amount) => {
  if (typeof amount === "string") {
    // Extract numeric value from "NPR X.XX" format
    const numericValue = amount.replace("NPR ", "").trim();
    return parseFloat(numericValue);
  }
  return amount;
};

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [processSteps, setProcessSteps] = useState([]);
  const [isTeacherModalOpen, setIsTeacherModalOpen] = useState(false);

  useEffect(() => {
    try {
      // Get user data and process steps from localStorage
      const userStr = localStorage.getItem("user");
      const processStepsStr = localStorage.getItem("process_steps");

      const userData = userStr ? JSON.parse(userStr) : null;
      const processStepsData = processStepsStr
        ? JSON.parse(processStepsStr)
        : [];

      setUser(userData);
      setProcessSteps(processStepsData);
    } catch (error) {
      console.error("Error loading user data:", error);
      setUser(null);
      setProcessSteps([]);
    }
  }, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axiosInstance.get("/dashboard");
        setDashboardData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch dashboard data");
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  // Process fee statistics for better visualization
  const processFeeStats = () => {
    if (!dashboardData?.fee_statistics) return [];

    const statusSummary = dashboardData.fee_statistics.reduce((acc, curr) => {
      if (!acc[curr.status]) {
        acc[curr.status] = {
          status: curr.status,
          count: 0,
          total_amount: 0,
        };
      }
      acc[curr.status].count += curr.count;
      acc[curr.status].total_amount += formatAmount(curr.total_amount);
      return acc;
    }, {});

    return Object.values(statusSummary);
  };

  // Process fee categories for stacked bar chart
  const processFeeCategoriesForChart = () => {
    if (!dashboardData?.fee_statistics) return { data: [], statuses: [] };

    // Group by category
    const categoryGroups = dashboardData.fee_statistics.reduce((acc, curr) => {
      const baseCategory = curr.category.split(" - ")[0]; // Handle categories like "Monthly Fee - 8"
      if (!acc[baseCategory]) {
        acc[baseCategory] = {
          category: baseCategory,
          total_count: 0,
        };
      }
      acc[baseCategory][curr.status] = formatAmount(curr.total_amount);
      acc[baseCategory].total_count += curr.count;
      return acc;
    }, {});

    // Get unique statuses
    const statuses = [
      ...new Set(dashboardData.fee_statistics.map((item) => item.status)),
    ];

    return {
      data: Object.values(categoryGroups),
      statuses,
    };
  };

  // Check if a chart should be visible based on process steps
  const isChartVisible = (chartCode) => {
    // Admin can see everything
    if (user?.roles?.includes("admin")) {
      return true;
    }

    // Check if the chart's code exists in process steps
    return processSteps.some((step) => step.code === chartCode);
  };

  if (loading) return <LoadingDots />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!dashboardData) return null;

  const feeStatusData = processFeeStats();
  const { data: feeCategoryData, statuses: feeStatuses } =
    processFeeCategoriesForChart();

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Dashboard</h2>

      <div className="grid grid-cols-1 gap-6">
        {/* First Row */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Gender Distribution */}
          {isChartVisible("student_stats") && (
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4">
                Gender Distribution
              </h3>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={dashboardData.gender_count}
                    dataKey="count"
                    nameKey="gender"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ name, percent }) =>
                      `${name} ${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {dashboardData.gender_count?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* Class Distribution */}
          {isChartVisible("class_stats") && (
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4">Students by Class</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dashboardData.class_count}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="class" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#8884d8" name="Students" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>

        {/* Second Row - Fee Status and Teacher Attendance */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Fee Status Distribution */}
          {isChartVisible("fee_stats") && (
            <div className="bg-white p-4 rounded-lg shadow md:col-span-2">
              <h3 className="text-lg font-semibold mb-4">
                Fee Status Distribution
              </h3>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={feeStatusData}
                    dataKey="count"
                    nameKey="status"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ name, percent }) =>
                      `${name} ${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {feeStatusData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          STATUS_COLORS[entry.status] ||
                          COLORS[index % COLORS.length]
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value, name) => [
                      value,
                      name.charAt(0).toUpperCase() + name.slice(1),
                    ]}
                  />
                  <Legend
                    formatter={(value) =>
                      value.charAt(0).toUpperCase() + value.slice(1)
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* Teacher Attendance */}
          {isChartVisible("teacher_attendance") && (
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4">
                Staffs Present Today
              </h3>
              <div className="flex items-center justify-center h-48">
                <div className="relative group">
                  <div
                    className="text-5xl font-bold text-blue-600 cursor-pointer"
                    onClick={() => setIsTeacherModalOpen(true)}
                  >
                    {dashboardData?.teacher_attendance_count?.count || 0}
                  </div>
                  {/* Tooltip */}
                  <div className="absolute z-10 invisible group-hover:visible bg-gray-800 text-white p-2 rounded mt-2 w-48 -left-16">
                    <p className="text-sm font-semibold mb-2">Present Staffs:</p>
                    {dashboardData?.teacher_attendance_count?.teachers
                      .filter((teacher) => teacher.status === "present")
                      .map((teacher, index) => (
                        <p key={index} className="text-sm">
                          {teacher.first_name} {teacher.last_name}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
              <TeacherAttendanceModal
                isOpen={isTeacherModalOpen}
                onClose={() => setIsTeacherModalOpen(false)}
                teachers={dashboardData?.teacher_attendance_count?.teachers || []}
              />
            </div>
          )}
        </div>

        {/* Third Row - Fee Category Overview */}
        <div className="grid grid-cols-1 gap-6">
          {/* Fee Categories Chart */}
          {isChartVisible("fee_category_stats") && (
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-4">
                Fee Categories Overview
              </h3>
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                  data={feeCategoryData}
                  margin={{ top: 20, right: 50, left: 20, bottom: 100 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="category"
                    angle={-45}
                    textAnchor="end"
                    height={100}
                    interval={0}
                    fontSize={12}
                  />
                  <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                  <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                  <Tooltip
                    formatter={(value, name) => {
                      if (name === "total_count") return [value, "Total Count"];
                      return [
                        `NPR ${parseFloat(value || 0).toFixed(2)}`,
                        name.charAt(0).toUpperCase() + name.slice(1),
                      ];
                    }}
                  />
                  <Legend
                    wrapperStyle={{ paddingTop: "20px" }}
                    formatter={(value) =>
                      value === "total_count"
                        ? "Total Count"
                        : value.charAt(0).toUpperCase() + value.slice(1)
                    }
                  />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="total_count"
                    stroke="#8884d8"
                    name="total_count"
                    dot={{ fill: "#8884d8" }}
                    strokeWidth={2}
                  />
                  {feeStatuses.map((status, index) => (
                    <Bar
                      key={status}
                      yAxisId="right"
                      dataKey={status}
                      name={status}
                      fill={
                        STATUS_COLORS[status] || COLORS[index % COLORS.length]
                      }
                      stackId="amount"
                    />
                  ))}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
