import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ClearableInput = ({
  label,
  value,
  onChange,
  required = false,
  error,
  className = "",
  type = "text",
  readOnly = false,
  placeholder = "",
  children,
}) => {
  return (
    <div className={`mb-4 ${className}`}>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="flex gap-2">
        <div className="flex-1 relative">
          <input
            type={type}
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white pr-8"
            value={value || ""}
            readOnly={readOnly}
            placeholder={placeholder}
            onChange={(e) => !readOnly && onChange(e.target.value)}
          />
          {value && (
            <button
              type="button"
              onClick={() => onChange("")}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
          {children}
        </div>
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default ClearableInput;
