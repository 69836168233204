import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getFeeStructure, createFeeStructure, updateFeeStructure } from "../../Api/feeStructureServices";
import { getFeeTypes } from "../../Api/feeTypeServices";
import { getClasses } from "../../Api/classServices";
import { getAcademicYears } from "../../Api/academicYearServices";
import LoadingDots from "../common/LoadingDots";
import { toast } from "react-toastify";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const FeeStructureForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      description: "",
      fee_type_id: "",
      academic_year_id: "",
      class_id: "",
    },
  });

  // Fetch fee structure data for edit mode
  const { data: selectedFeeStructure, isLoading: isLoadingFeeStructure } =
    useQuery({
      queryKey: ["feeStructure", id],
      queryFn: () => getFeeStructure(id),
      enabled: !!id,
    });

  // Fetch fee types for dropdown
  const { data: feeTypes = [], isLoading: isLoadingFeeTypes } = useQuery({
    queryKey: ["feeTypes"],
    queryFn: async () => {
      const response = await getFeeTypes(1, 100);
      return response.data || [];
    },
  });

  // Fetch classes for dropdown
  const { data: classes = [], isLoading: isLoadingClasses } = useQuery({
    queryKey: ["classes"],
    queryFn: async () => {
      const response = await getClasses(1, 100);
      return response.data || [];
    },
  });

  // Fetch academic years for dropdown
  const { data: academicYears = [], isLoading: isLoadingAcademicYears } =
    useQuery({
      queryKey: ["academicYears"],
      queryFn: async () => {
        const response = await getAcademicYears();
        return response.data || [];
      },
    });

  // Create mutation
  const createMutation = useMutation({
    mutationFn: createFeeStructure,
    onSuccess: () => {
      queryClient.invalidateQueries(["feeStructures"]);
      toast.success("Fee structure created successfully");
      navigate("/feestructures");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to create fee structure");
    },
  });

  // Update mutation
  const updateMutation = useMutation({
    mutationFn: ({ id, data }) => updateFeeStructure(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["feeStructures"]);
      toast.success("Fee structure updated successfully");
      navigate("/feestructures");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to update fee structure");
    },
  });

  useEffect(() => {
    if (selectedFeeStructure && !isLoadingFeeTypes && !isLoadingClasses && !isLoadingAcademicYears) {
      reset({
        description: selectedFeeStructure.description || "",
        fee_type_id: selectedFeeStructure.fee_type_id?.toString() || "",
        academic_year_id: selectedFeeStructure.academic_year_id?.toString() || "",
        class_id: selectedFeeStructure.class_id?.toString() || "",
      });
    }
  }, [selectedFeeStructure, isLoadingFeeTypes, isLoadingClasses, isLoadingAcademicYears, reset]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      if (id) {
        await updateMutation.mutateAsync({
          id,
          data: {
            ...data,
            version: selectedFeeStructure?.version || 0,
          },
        });
      } else {
        await createMutation.mutateAsync(data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoadingFeeStructure || isLoadingFeeTypes || isLoadingClasses || isLoadingAcademicYears) {
    return <LoadingDots />;
  }

  return (
    <div className="max-w-2xl mx-auto bg-white shadow-sm rounded-lg">
      <div className="p-6">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            {id ? "Edit Fee Structure" : "Create Fee Structure"}
          </h2>

          {/* Fee Type Field */}
          <div className="space-y-2">
            <label htmlFor="fee_type_id" className="form_label">
              Fee Type
            </label>
            <select
              id="fee_type_id"
              className={`form_input ${
                errors.fee_type_id ? requiredFieldClass : ""
              }`}
              {...register("fee_type_id", { required: "Fee Type is required" })}
            >
              <option value="">Select Fee Type</option>
              {feeTypes.map((feeType) => (
                <option key={feeType.id} value={feeType.id}>
                  {feeType.name}
                </option>
              ))}
            </select>
            {errors.fee_type_id && (
              <p className={errorMessageClass}>{errors.fee_type_id.message}</p>
            )}
          </div>

          {/* Academic Year Field */}
          <div className="space-y-2">
            <label htmlFor="academic_year_id" className="form_label">
              Academic Year
            </label>
            <select
              id="academic_year_id"
              className={`form_input ${
                errors.academic_year_id ? requiredFieldClass : ""
              }`}
              {...register("academic_year_id", {
                required: "Academic Year is required",
              })}
            >
              <option value="">Select Academic Year</option>
              {academicYears.map((year) => (
                <option key={year.id} value={year.id}>
                  {year.name}
                </option>
              ))}
            </select>
            {errors.academic_year_id && (
              <p className={errorMessageClass}>{errors.academic_year_id.message}</p>
            )}
          </div>

          {/* Class Field */}
          <div className="space-y-2">
            <label htmlFor="class_id" className="form_label">
              Class
            </label>
            <select
              id="class_id"
              className={`form_input ${
                errors.class_id ? requiredFieldClass : ""
              }`}
              {...register("class_id", { required: "Class is required" })}
            >
              <option value="">Select Class</option>
              {classes.map((classItem) => (
                <option key={classItem.id} value={classItem.id}>
                  {classItem.name}
                </option>
              ))}
            </select>
            {errors.class_id && (
              <p className={errorMessageClass}>{errors.class_id.message}</p>
            )}
          </div>

          {/* Description Field */}
          <div className="space-y-2">
            <label htmlFor="description" className="form_label">
              Description
            </label>
            <textarea
              id="description"
              className={`form_input ${
                errors.description ? requiredFieldClass : ""
              }`}
              {...register("description")}
            />
            {errors.description && (
              <p className={errorMessageClass}>{errors.description.message}</p>
            )}
          </div>

          {/* Form Actions */}
          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={() => navigate("/feestructures")}
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <LoadingDots text={id ? "Updating" : "Creating"} />
              ) : id ? (
                "Update Fee Structure"
              ) : (
                "Create Fee Structure"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeeStructureForm;
