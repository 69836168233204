import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getEnrollments } from "../../Api/enrollmentServices";
import { useTableColumns } from "../../hooks/enrollment/useTableColumns";
import Table from "../common/Table";
import QuickEnrollmentForm from "./QuickEnrollmentForm";
import usePagination from "../../hooks/usePagination";
import LoadingDots from "../common/LoadingDots";

const ListEnrollment = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const columns = useTableColumns();
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } =
    usePagination();

  const {
    data = {
      data: [],
      metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 },
    },
    isLoading,
  } = useQuery({
    queryKey: ["enrollments", offset, limit],
    queryFn: () => getEnrollments({ offset, limit }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const totalItems = data.metadata?.count || 0;
  const totalPages = data.metadata?.total_pages || 1;

  const pagination = {
    currentPage,
    totalPages,
    nextPage: () => nextPage(totalPages),
    prevPage,
    goToPage: (page) => goToPage(page, totalPages),
  };

  const handleLink = () => "/enrollments/create";

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="container mx-auto p-4">
      <QuickEnrollmentForm
        onSuccess={() => {
          queryClient.invalidateQueries(["enrollments"]);
        }}
      />
      <div className="mt-8">
        <Table
          data={data.data || []}
          columns={columns}
          isLoading={isLoading}
          pagination={pagination}
          handleLink={handleLink}
          tableName="Enrollment"
          baseEditPath="/enrollments/edit"
        />
      </div>
    </div>
  );
};

export default ListEnrollment;
