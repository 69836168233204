// src/routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import ListStudent from "./pages/Student/ListStudent";
import CreateStudent from "./pages/Student/CreateStudent";
import StudentForm from "./components/Student/StudentForm";
import { AuthProvider } from "./context/authContext";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import ListParent from "./pages/Parent/ListParent";
import CreateParent from "./pages/Parent/CreateParent";
import ParentForm from "./components/Parent/ParentForm";
import ListClass from "./pages/Class/ListClass";
import CreateClass from "./pages/Class/CreateClass";
import EditClass from "./pages/Class/EditClass";
import ClassForm from "./components/Class/ClassForm";
import ListSubject from "./pages/Subject/ListSubject";
import CreateSubject from "./pages/Subject/CreateSubject";
import SubjectForm from "./components/Subject/SubjectForm";
import ListNotice from "./pages/Notice/ListNotice";
import CreateNotice from "./pages/Notice/CreateNotice";
import NoticeForm from "./components/Notice/NoticeForm";
import ListUsers from "./pages/Users/ListUsers";
import CreateUser from "./pages/Users/CreateUser";
import UserForm from "./components/User/UserForm";
import UserActions from "./pages/UserActions/UserActions";
import ListBusRoute from "./pages/BusRoute/ListBusRoute";
import CreateBusRoute from "./pages/BusRoute/CreateBusRoute";
import BusRouteForm from "./components/BusRoute/BusRouteForm"; // Import BusRouteForm
import ListAcademicYear from "./pages/AcademicYear/ListAcademicYear";
import CreateAcademicYear from "./pages/AcademicYear/CreateAcademicYear";
import AcademicYearForm from "./components/AcademicYear/AcademicYearForm";
import ListTerm from "./pages/Term/ListTerm";
import CreateTerm from "./pages/Term/CreateTerm";
import TermForm from "./components/Term/TermForm";
import ListFeeType from "./pages/FeeType/ListFeeType";
import CreateFeeType from "./pages/FeeType/CreateFeeType";
import FeeTypeForm from "./components/FeeType/FeeTypeForm";
import ListFeeStructure from "./pages/FeeStructure/ListFeeStructure";
import FeeStructure from "./pages/FeeStructure/FeeStructure";
import ListEnrollment from "./pages/Enrollment/ListEnrollment";
import CreateEnrollment from "./pages/Enrollment/CreateEnrollment";
import ListMarks from "./pages/Marks/ListMarks";
import Results from "./pages/Results/Results";
import Payments from "./pages/Payments/Payments";
import Calendar from "./pages/Calendar/Calendar";
import Messages from "./pages/Messages/Messages";
import ProcessSteps from "./pages/ProcessSteps/ProcessSteps";
import ListFees from "./pages/Fees/ListFees";
import CreateFee from "./pages/Fees/CreateFee";
import FeesForm from "./components/Fees/FeesForm";
import ListTeachers from "./pages/Teacher/ListTeachers";
import Teacher from "./pages/Teacher/Teacher";
import Attendance from "./pages/Attendance/Attendance";

const AppRoutes = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/students/all" element={<ListStudent />} />
          <Route path="/students/create" element={<CreateStudent />} />
          <Route path="/students/edit/:id" element={<StudentForm />} />
          <Route path="/profile" element={<UserForm isProfile={true} />} />

          {/* Grouped Students Routes */}
          <Route path="students">
            <Route path="all" element={<ListStudent />} />
            <Route path="create" element={<CreateStudent />} />
            <Route path="edit/:id" element={<StudentForm />} />
          </Route>

          {/* Grouped Parents Routes */}
          <Route path="parents">
            <Route path="all" element={<ListParent />} />
            <Route path="create" element={<CreateParent />} />
            <Route path="edit/:id" element={<ParentForm />} />
          </Route>

          {/* Grouped Classes Routes */}
          <Route path="classes">
            <Route path="all" element={<ListClass />} />
            <Route path="create" element={<CreateClass />} />
            <Route path="edit/:id" element={<EditClass />} />
          </Route>

          {/* Grouped Subjects Routes */}
          <Route path="subjects">
            <Route path="all" element={<ListSubject />} />
            <Route path="create" element={<CreateSubject />} />
            <Route path="edit/:id" element={<SubjectForm />} />
          </Route>

          {/* Attendance Route */}
          <Route path="attendances" element={<Attendance />} />

          {/* Grouped Teachers Routes */}
          <Route path="teachers">
            <Route path="all" element={<ListTeachers />} />
            <Route path="create" element={<Teacher />} />
            <Route path="edit/:id" element={<Teacher />} />
          </Route>

          {/* Grouped Bus Routes */}
          <Route path="busroutes">
            <Route path="all" element={<ListBusRoute />} />
            <Route path="create" element={<CreateBusRoute />} />
            <Route path="edit/:id" element={<BusRouteForm />} />
          </Route>

          {/* Grouped Fees Routes */}
          <Route path="fees">
            <Route path="all" element={<ListFees />} />
            <Route path="create" element={<CreateFee />} />
            <Route path="edit/:id" element={<FeesForm isEdit={true} />} />
          </Route>

          {/* Grouped Academic Year Routes */}
          <Route path="academicyears">
            <Route index element={<ListAcademicYear />} />
            <Route path="create" element={<CreateAcademicYear />} />
            <Route path="edit/:id" element={<AcademicYearForm />} />
          </Route>

          {/* Academic Year Routes */}
          <Route path="/academicyears" element={<ListAcademicYear />} />
          <Route
            path="/academicyears/create"
            element={<CreateAcademicYear />}
          />
          <Route
            path="/academicyears/edit/:id"
            element={<AcademicYearForm isEdit />}
          />

          {/* Term Routes */}
          <Route path="terms">
            <Route path="all" element={<ListTerm />} />
            <Route path="create" element={<CreateTerm />} />
            <Route path="edit/:id" element={<TermForm isEdit />} />
          </Route>

          {/* Grouped Fee Types Routes */}
          <Route path="feetypes">
            <Route path="all" element={<ListFeeType />} />
            <Route path="create" element={<CreateFeeType />} />
            <Route path="edit/:id" element={<FeeTypeForm />} />
          </Route>

          {/* Grouped Fee Structures Routes */}
          <Route path="feestructures">
            <Route index element={<ListFeeStructure />} />
            <Route path="create" element={<FeeStructure />} />
            <Route path="edit/:id" element={<FeeStructure />} />
          </Route>

          {/* Grouped Enrollments Routes */}
          <Route path="/enrollments/all" element={<ListEnrollment />} />
          <Route path="/enrollments/create" element={<CreateEnrollment />} />
          <Route path="/enrollments/edit/:id" element={<CreateEnrollment />} />

          {/* Marks Routes */}
          <Route path="/marks" element={<ListMarks />} />

          {/* Results Routes */}
          <Route path="results" element={<Results />} />

          {/* Payments Routes */}
          <Route path="/payments" element={<Payments />} />

          {/* Calendar Routes */}
          <Route path="/calendar" element={<Calendar />} />

          {/* Notices Routes */}
          <Route path="notices">
            <Route path="all" element={<ListNotice />} />
            <Route path="create" element={<CreateNotice />} />
            <Route path="edit/:id" element={<NoticeForm />} />
          </Route>

          {/* Messages Routes */}
          <Route path="/messages" element={<Messages />} />

          {/* Process Steps Routes */}
          <Route
            path="/processsteps"
            element={
              <ProtectedRoute>
                <ProcessSteps />
              </ProtectedRoute>
            }
          />

          {/* Users Routes */}
          <Route path="users">
            <Route path="all" element={<ListUsers />} />
            <Route path="create" element={<CreateUser />} />
            <Route path="edit/:id" element={<UserForm />} />
          </Route>

          <Route path="/useractions" element={<UserActions />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default AppRoutes;
