import axiosInstance from "../lib/axiosConfig";

export const getEnrollments = async ({ offset = 0, limit = 10 }) => {
  try {
    const response = await axiosInstance.get(`enrollments/`, {
      params: {
        offset,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching enrollments:", error);
    throw error;
  }
};

export const getEnrollmentById = async (id) => {
  const response = await axiosInstance.get(`enrollments/${id}`);
  return response.data;
};

export const createEnrollment = async (enrollmentData) => {
  const response = await axiosInstance.post("enrollments/", enrollmentData);
  return response.data;
};

export const updateEnrollment = async (id, enrollmentData) => {
  const response = await axiosInstance.patch(
    `enrollments/${id}`,
    enrollmentData
  );
  return response.data;
};

export const deleteEnrollment = async (id) => {
  const response = await axiosInstance.delete(`enrollments/${id}`);
  return response.data;
};

// Additional services for form data
export const getStudents = async (searchQuery = "") => {
  const response = await axiosInstance.get("students/", {
    params: { search: searchQuery },
  });
  return response.data.data;
};

export const getClasses = async () => {
  const response = await axiosInstance.get("classes/");
  console.log("Classes response:", response.data);
  return response.data;
};

export const getClassSubjects = async (classId, academicYearId) => {
  const response = await axiosInstance.get("classsubjects/", {
    params: {
      filter: [`class_id~${classId}`, `academic_year_id~${academicYearId}`].map(
        (f) => f
      ),
      offset: 0,
      limit: 100,
    },
    paramsSerializer: (params) => {
      const searchParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => searchParams.append(key, v));
        } else {
          searchParams.append(key, value);
        }
      });
      return searchParams.toString();
    },
  });
  return response.data.data;
};

export const getAcademicYears = async () => {
  const response = await axiosInstance.get("academicyears/");
  return response.data;
};

export const getEnrollmentsByYearAndClass = async (yearId, classId) => {
  const response = await axiosInstance.get(`year/${yearId}/class/${classId}`);
  return response.data;
};

export const getSections = async (classId) => {
  const response = await axiosInstance.get(`classes/${classId}`);
  return response.data.sections || [];
};

export const uploadEnrollments = async (formData) => {
  try {
    const response = await axiosInstance.post("enrollments/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to upload enrollments"
    );
  }
};
