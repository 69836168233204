import { useMemo } from "react";
import { TableActions } from "../../components/common/TableActions";

export const useTableColumns = ({ handleEdit, handleDelete }) => {
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Code",
        accessorKey: "code",
      },
      {
        header: "Description",
        accessorKey: "description",
      },
      {
        header: "Roles",
        accessorKey: "roles",
        cell: (info) => (
          <div className="flex flex-wrap gap-1">
            {info.getValue().map((role) => (
              <span
                key={role}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
              >
                {role}
              </span>
            ))}
          </div>
        ),
      },
      {
        id: "action",
        header: "Action",
        enableSorting: false,
        cell: ({ row }) => (
          <TableActions
            onEdit={() => handleEdit(row.original)}
            onDelete={() => handleDelete(row.original.id)}
          />
        ),
      },
    ],
    [handleEdit, handleDelete]
  );

  return columns;
};
