import { useQuery } from "@tanstack/react-query";
import { getProfileImage } from "../../Api/attachmentServices";

export const useUserProfileImage = (userId) => {
  return useQuery({
    queryKey: ["userProfileImage", userId],
    queryFn: () => getProfileImage(userId),
    enabled: !!userId,
  });
};
