import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBusRoutes } from "../../Api/busRouteServices";
import { useTableColumns } from "../../hooks/busRoute/useTableColumns";
import Table from "../../components/common/Table";
import LoadingDots from "../../components/common/LoadingDots";

const ListBusRoute = () => {
  const navigate = useNavigate();
  const columns = useTableColumns();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: busRoutesData, isLoading } = useQuery({
    queryKey: ["busRoutes", currentPage],
    queryFn: () => getBusRoutes(currentPage),
  });

  const pagination = {
    currentPage: busRoutesData?.currentPage || 1,
    totalPages: busRoutesData?.totalPages || 1,
    nextPage: () =>
      setCurrentPage((old) =>
        Math.min(old + 1, busRoutesData?.totalPages || 1)
      ),
    prevPage: () => setCurrentPage((old) => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="container mx-auto p-4">
      <Table
        data={busRoutesData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={() => "/busroutes/create"}
        tableName="Bus Route"
        baseEditPath="/busroutes/edit"
      />
    </div>
  );
};

export default ListBusRoute;
