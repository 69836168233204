import React, { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getAcademicYears } from "../../Api/enrollmentServices";
import { getAllTerms } from "../../Api/termServices";
import { getTermResult } from "../../Api/resultServices";
import { searchStudents, getStudentById } from "../../Api/studentsServices";
import LoadingDots from "../../components/common/LoadingDots";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { useAuth } from "../../context/authContext";

const Results = () => {
  const [showResult, setShowResult] = useState(false);
  const [termResult, setTermResult] = useState(null);
  const [fetchingResult, setFetchingResult] = useState(false);
  const [studentSearch, setStudentSearch] = useState("");
  const { user } = useAuth();

  const isStudent = user?.roles?.includes("student");
  const canSearchStudents = user?.roles?.some((role) =>
    ["admin", "teacher"].includes(role)
  );

  const isStudentWithAccount = isStudent && !!user?.student_id;

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      academic_year_id: null,
      term_id: null,
      student_id: null,
    },
  });

  const selectedAcademicYear = watch("academic_year_id");
  const selectedTerm = watch("term_id");
  const selectedStudent = watch("student_id");

  const { data: academicYears, isLoading: loadingAcademicYears } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
  });

  const { data: terms, isLoading: loadingTerms } = useQuery({
    queryKey: ["terms"],
    queryFn: getAllTerms,
  });

  const { data: studentData } = useQuery({
    queryKey: ["student", user?.student_id],
    queryFn: () => getStudentById(user.student_id),
    enabled: isStudentWithAccount,
  });

  const { data: students, isLoading: loadingStudents } = useQuery({
    queryKey: ["students", studentSearch],
    queryFn: () => searchStudents(studentSearch),
    enabled: !isStudentWithAccount && studentSearch.length >= 2,
  });

  const studentSelfOption =
    isStudentWithAccount && studentData
      ? [
          {
            value: studentData.id,
            label: `${studentData.first_name} ${studentData.last_name}`,
          },
        ]
      : [];

  const searchStudentOptions =
    !isStudentWithAccount && students?.data
      ? students.data.map((student) => ({
          value: student.id,
          label: `${student.first_name} ${student.last_name}`,
        }))
      : [];

  const studentOptions = isStudentWithAccount
    ? studentSelfOption
    : searchStudentOptions;

  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      setStudentSearch(inputValue);
    }, 300),
    []
  );

  const handleFetchResult = async (data) => {
    if (!data.academic_year_id || !data.term_id || !data.student_id) {
      toast.info("Please select all fields");
      return;
    }

    try {
      setFetchingResult(true);
      const response = await getTermResult(
        data.academic_year_id.value,
        data.term_id.value,
        data.student_id.value
      );
      setTermResult(response);
      setShowResult(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail?.[0]?.msg || "Error fetching result";
      toast.error(errorMessage);
    } finally {
      setFetchingResult(false);
    }
  };

  const academicYearOptions = academicYears?.data?.length
    ? academicYears.data.map((year) => ({
        value: year.id,
        label: year.name,
      }))
    : [];

  const termOptions = terms?.length
    ? terms.map((term) => ({
        value: term.id,
        label: term.name,
      }))
    : [];

  const requiredFieldClass = "border-red-500";
  const errorMessageClass = "text-red-500 text-xs mt-1";

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
      <div className="space-y-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Results</h1>
          </div>
        </div>

        <div className="space-y-6">
          <form
            onSubmit={handleSubmit(handleFetchResult)}
            className="space-y-4"
          >
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Academic Year
                </label>
                <Controller
                  name="academic_year_id"
                  control={control}
                  rules={{ required: "Academic Year is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={academicYearOptions}
                      isLoading={loadingAcademicYears}
                      className={
                        errors.academic_year_id ? requiredFieldClass : ""
                      }
                    />
                  )}
                />
                {errors.academic_year_id && (
                  <p className={errorMessageClass}>
                    {errors.academic_year_id.message}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="term_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Term
                </label>
                <Controller
                  name="term_id"
                  control={control}
                  rules={{ required: "Term is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={termOptions}
                      isLoading={loadingTerms}
                      className={errors.term_id ? requiredFieldClass : ""}
                    />
                  )}
                />
                {errors.term_id && (
                  <p className={errorMessageClass}>{errors.term_id.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Student
                </label>
                <Controller
                  name="student_id"
                  control={control}
                  rules={{ required: "Student is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={studentOptions.find(
                        (option) => option.value === field.value?.value
                      )}
                      options={studentOptions}
                      isLoading={isStudentWithAccount ? false : loadingStudents}
                      onInputChange={(inputValue) => {
                        if (!isStudentWithAccount) {
                          debouncedSearch(inputValue);
                        }
                      }}
                      isSearchable={!isStudentWithAccount}
                      isClearable={!isStudentWithAccount}
                      placeholder={
                        isStudentWithAccount
                          ? "Your account"
                          : "Search for a student..."
                      }
                      noOptionsMessage={({ inputValue }) =>
                        !inputValue
                          ? "Type to search..."
                          : inputValue.length < 2
                          ? "Type at least 2 characters to search"
                          : "No students found"
                      }
                      className={errors.student_id ? requiredFieldClass : ""}
                    />
                  )}
                />
                {errors.student_id && (
                  <p className={errorMessageClass}>
                    {errors.student_id.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={fetchingResult}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {fetchingResult ? "Loading..." : "Show Result"}
              </button>
            </div>
          </form>

          {fetchingResult && <LoadingDots />}

          {showResult && termResult && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {termResult.metadata.student_name} -{" "}
                  {termResult.metadata.term_name}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Total Marks: {termResult.metadata.total_marks}/
                  {termResult.metadata.total_full_marks} (
                  {termResult.metadata.total_percentage}%) | GPA:{" "}
                  {termResult.metadata.gpa}
                </p>
              </div>
              <div className="border-t border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Subject
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Marks
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Percentage
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Grade
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Grade Point
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {termResult.data.map((subject, index) => (
                      <tr key={`${subject.subject}-${subject.type}-${index}`}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {subject.subject}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {subject.type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {subject.obtained_marks}/{subject.full_marks}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {subject.percentage}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {subject.grade}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {subject.grade_point}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Results;
