import axiosInstance from "../lib/axiosConfig";

export const getEvents = async () => {
  try {
    const response = await axiosInstance.get(`events/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEvent = async (id) => {
  try {
    const response = await axiosInstance.get(`events/${id}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createEvent = async (eventData) => {
  try {
    const response = await axiosInstance.post(`events/`, eventData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEvent = async (id, eventData) => {
  try {
    const response = await axiosInstance.patch(`events/${id}`, eventData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteEvent = async (id) => {
  try {
    await axiosInstance.delete(`events/${id}/`);
  } catch (error) {
    throw error;
  }
};

export const getEventsByDateRange = async (startDate, endDate) => {
  try {
    const response = await axiosInstance.get(
      `events/bydaterange/${startDate}/${endDate}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
