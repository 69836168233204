import axiosInstance from "../lib/axiosConfig";

// Function to get all parents
export const getAllParents = async ({ offset = 0, limit = 10 }) => {
  try {
    const { data } = await axiosInstance.get("parents/", {
      params: {
        offset,
        limit,
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching parents:", error);
    throw error;
  }
};

// Function to create a parent
export const createParent = async (parentData) => {
  try {
    const response = await axiosInstance.post("parents/", parentData);
    return response.data;
  } catch (error) {
    console.error("Error creating parent:", error);
    throw error;
  }
};

// Function to delete a parent
export const deleteParent = async (id) => {
  try {
    const response = await axiosInstance.delete(`parents/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting parent:", error);
    throw error;
  }
};

// Function to edit a parent
export const editParent = async (id, updatedData) => {
  try {
    const response = await axiosInstance.patch(`parents/${id}`, updatedData);
    return response.data;
  } catch (error) {
    console.error("Error editing parent:", error);
    throw error;
  }
};

// Function to get a single parent
export const getParentById = async (id) => {
  try {
    const response = await axiosInstance.get(`parents/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching parent:", error);
    throw error;
  }
};

// Function to search parents by name
export const searchParents = async (
  searchQuery,
  { offset = 0, limit = 10 } = {}
) => {
  try {
    const { data } = await axiosInstance.get("parents/", {
      params: {
        filter: `first_name~${searchQuery}`,
        offset,
        limit,
      },
    });
    return data;
  } catch (error) {
    console.error("Error searching parents:", error);
    throw error;
  }
};
