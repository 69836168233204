import React, { useState, useEffect } from "react";
import {
  getAllMessages,
  sendMessage,
  getMessageThread,
} from "../../Api/messagesService";
import axiosInstance from "../../lib/axiosConfig";
import LoadingDots from "../../components/common/LoadingDots";

function Messages() {
  const [messages, setMessages] = useState([]);
  const [newMessageContent, setNewMessageContent] = useState("");
  const [threadContent, setThreadContent] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [threadOpen, setThreadOpen] = useState(false);
  const [threadMessages, setThreadMessages] = useState([]);
  const [loadingThread, setLoadingThread] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const messagesEndRef = React.useRef(null);

  const truncateMessage = (text, maxLength = 200) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [threadMessages]);

  useEffect(() => {
    // Get current user from localStorage
    const userStr = localStorage.getItem("user");
    if (userStr) {
      setCurrentUser(JSON.parse(userStr));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const messagesResponse = await getAllMessages();
        setMessages(messagesResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load messages. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const searchUsers = async (query) => {
    if (!query) {
      setUsers([]);
      return;
    }

    setLoadingUsers(true);
    try {
      const response = await axiosInstance.get(
        `users/?offset=0&limit=10&filter=first_name~${query}`
      );
      // Filter out current user from search results
      const filteredUsers = (response.data.data || []).filter(
        (user) => user.id !== currentUser?.id
      );
      setUsers(filteredUsers);
    } catch (error) {
      console.error("Error searching users:", error);
      setUsers([]);
      setError("Failed to search users. Please try again.");
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchUsers(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleSendMessage = async () => {
    if (!newMessageContent.trim() || !selectedUser) return;

    try {
      const receiverName = `${selectedUser.first_name} ${selectedUser.last_name}`;
      await sendMessage(newMessageContent, selectedUser.id, receiverName);
      setNewMessageContent("");
      setSelectedUser(null);
      setSearchQuery("");
      // Refresh messages
      const messagesResponse = await getAllMessages();
      setMessages(messagesResponse);
    } catch (error) {
      console.error("Error sending message:", error);
      setError(error.message || "Failed to send message. Please try again.");
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleOpenThread = async (message) => {
    setLoadingThread(true);
    setThreadOpen(true);
    const otherUserId =
      message.sender_id === currentUser?.id
        ? message.receiver_id
        : message.sender_id;
    const otherUserName =
      message.sender_id === currentUser?.id
        ? message.receiver_name
        : message.sender_name;
    setSelectedThread({ id: otherUserId, name: otherUserName });

    try {
      const threadMessages = await getMessageThread(otherUserId);
      setThreadMessages(threadMessages);
    } catch (error) {
      console.error("Error loading thread:", error);
      setError("Failed to load message thread. Please try again.");
    } finally {
      setLoadingThread(false);
    }
  };

  const handleCloseThread = () => {
    setThreadOpen(false);
    setThreadMessages([]);
    setSelectedThread(null);
  };

  const handleSendThreadMessage = async () => {
    if (!threadContent.trim() || !selectedThread) return;

    try {
      await sendMessage(threadContent, selectedThread.id, selectedThread.name);
      setThreadContent("");

      // Refresh thread messages
      const threadMessages = await getMessageThread(selectedThread.id);
      setThreadMessages(threadMessages);

      // Refresh main message list
      const messagesResponse = await getAllMessages();
      setMessages(messagesResponse);
    } catch (error) {
      console.error("Error sending message:", error);
      setError(error.message || "Failed to send message. Please try again.");
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      const messagesResponse = await getAllMessages();
      setMessages(messagesResponse);
    } catch (error) {
      setError(
        error.message || "Failed to refresh messages. Please try again."
      );
    } finally {
      setRefreshing(false);
    }
  };

  if (loading) {
    return <LoadingDots />;
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-lg"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="h-6 w-6 text-red-500 mr-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div>
              <p className="font-bold">Error</p>
              <p className="text-sm">{error}</p>
            </div>
            <button onClick={handleCloseError} className="ml-auto">
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* New Message Form */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <div className="flex items-center gap-2 mb-4">
          <h1 className="text-2xl font-semibold">Messages</h1>
          <button
            onClick={handleRefresh}
            disabled={refreshing}
            className="p-1 rounded-full hover:bg-gray-100 disabled:opacity-50"
            title="Refresh Messages"
          >
            {refreshing ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900" />
            ) : (
              <svg
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            )}
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h2 className="text-xl font-semibold mb-4">New Message</h2>

            <div className="relative mb-4">
              {selectedUser ? (
                <div className="mb-2">
                  <div className="flex items-center justify-between bg-blue-50 p-3 rounded-lg border border-blue-200">
                    <span className="text-sm text-blue-800">
                      To: {selectedUser.first_name} {selectedUser.last_name}
                    </span>
                    <button
                      onClick={() => {
                        setSelectedUser(null);
                        setSearchQuery("");
                      }}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <svg
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ) : (
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search Recipient"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              )}
              {loadingUsers && !selectedUser && (
                <div className="absolute right-3 top-2">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500" />
                </div>
              )}
              {users.length > 0 && !selectedUser && (
                <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg">
                  {users.map((user) => (
                    <div
                      key={user.id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        setSelectedUser(user);
                        setSearchQuery("");
                        setUsers([]); // Clear the search results
                      }}
                    >
                      {`${user.first_name} ${user.last_name}`}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <textarea
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mb-4"
              rows="4"
              value={newMessageContent}
              onChange={(e) => setNewMessageContent(e.target.value)}
              placeholder="Type your message"
            />

            <button
              onClick={handleSendMessage}
              disabled={!newMessageContent.trim() || !selectedUser}
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Send Message
            </button>
          </div>
        </div>
      </div>

      {/* Messages and Conversation Split View */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        {/* Message List */}
        <div className="md:col-span-4 bg-white rounded-lg shadow-lg p-4">
          <div className="space-y-2 max-h-[600px] overflow-y-auto">
            {messages.length === 0 ? (
              <div className="text-center py-8 text-gray-500">
                No messages yet
              </div>
            ) : (
              messages.map((message) => (
                <div
                  key={message.id}
                  onClick={() => handleOpenThread(message)}
                  className={`p-4 rounded-lg cursor-pointer transition-colors ${
                    selectedThread?.id ===
                    (message.sender_id === currentUser?.id
                      ? message.receiver_id
                      : message.sender_id)
                      ? "bg-blue-50 border-2 border-blue-200"
                      : "bg-white hover:bg-gray-50"
                  }`}
                >
                  <p
                    className={`text-sm ${
                      message.status === "sent" ? "font-semibold" : ""
                    }`}
                  >
                    {truncateMessage(message.content)}
                  </p>
                  <p className="text-sm text-gray-500 mt-1">
                    {message.sender_id === currentUser?.id
                      ? `To: ${message.receiver_name}`
                      : `From: ${message.sender_name}`}
                  </p>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Conversation View */}
        <div className="md:col-span-8 bg-white rounded-lg shadow-lg p-4">
          {selectedThread ? (
            <div className="h-full flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">
                  Conversation with {selectedThread.name}
                </h2>
              </div>

              {loadingThread ? (
                <div className="flex-grow flex items-center justify-center">
                  <LoadingDots />
                </div>
              ) : (
                <>
                  <div className="flex-grow overflow-y-auto max-h-[500px] space-y-4 mb-4">
                    {threadMessages.map((message) => (
                      <div
                        key={message.id}
                        className={`flex ${
                          message.sender_id === currentUser?.id
                            ? "justify-end"
                            : "justify-start"
                        }`}
                      >
                        <div
                          className={`max-w-[70%] rounded-lg p-3 ${
                            message.sender_id === currentUser?.id
                              ? "bg-blue-500 text-white"
                              : "bg-gray-100"
                          }`}
                        >
                          <p className="text-sm">{message.content}</p>
                          <p className="text-xs mt-1 opacity-75">
                            {message.sender_id === currentUser?.id
                              ? "You"
                              : message.sender_name}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>

                  <div className="mt-auto">
                    <textarea
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mb-2"
                      rows="3"
                      value={threadContent}
                      onChange={(e) => setThreadContent(e.target.value)}
                      placeholder="Type your message"
                    />
                    <button
                      onClick={handleSendThreadMessage}
                      disabled={!threadContent.trim()}
                      className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Send
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="h-[600px] flex items-center justify-center text-gray-500">
              Select a conversation to view messages
            </div>
          )}
        </div>
      </div>

      {/* Error Toast */}
      {error && (
        <div className="fixed top-4 right-4 z-50">
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-lg"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="h-6 w-6 text-red-500 mr-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div>
                <p className="font-bold">Error</p>
                <p className="text-sm">{error}</p>
              </div>
              <button onClick={handleCloseError} className="ml-auto">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Messages;
