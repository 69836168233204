import React from "react";
import TermForm from "../../components/Term/TermForm";

const CreateTerm = () => {
  return (
      <TermForm />
  );
};

export default CreateTerm;
