import axiosInstance from "../lib/axiosConfig";

export const getTermResult = async (yearId, termId, studentId) => {
  try {
    const response = await axiosInstance.get(
      `/marks/year/${yearId}/term/${termId}/student/${studentId}/gradesheet`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
