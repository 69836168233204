import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createFeeType, updateFeeType, deleteFeeType } from '../../Api/feeTypeServices';
import { toast } from 'react-toastify';

export const useFeeTypeQuery = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data) => createFeeType(data),
    onSuccess: () => {
      // Invalidate all fee type queries
      queryClient.invalidateQueries({ 
        queryKey: ['feetypes'],
        refetchType: 'all'
      });
      toast.success('Fee type created successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error creating fee type');
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateFeeType(id, updatedData),
    onSuccess: () => {
      // Invalidate all fee type queries
      queryClient.invalidateQueries({ 
        queryKey: ['feetypes'],
        refetchType: 'all'
      });
      toast.success('Fee type updated successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error updating fee type');
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteFeeType(id),
    onSuccess: () => {
      // Invalidate all fee type queries
      queryClient.invalidateQueries({ 
        queryKey: ['feetypes'],
        refetchType: 'all'
      });
      toast.success('Fee type deleted successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error deleting fee type');
    },
  });

  return {
    createMutation,
    editMutation,
    deleteMutation,
  };
};
