import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAcademicYearById } from "../../Api/academicYearServices";
import { useAcademicYearQuery } from "../../hooks/academicYear/useAcademicYearQuery";
import LoadingDots from "../common/LoadingDots";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const AcademicYearForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useAcademicYearQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      start_date: "",
      end_date: "",
    },
  });

  const { data: academicYearData } = useQuery({
    queryKey: ["academicYear", id],
    queryFn: () => getAcademicYearById(id),
    enabled: !!id,
  });

  React.useEffect(() => {
    if (academicYearData) {
      reset(academicYearData);
    }
  }, [academicYearData, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    if (id) {
      editMutation.mutate(
        { id, updatedData: data },
        {
          onSettled: () => setIsSubmitting(false),
        }
      );
    } else {
      createMutation.mutate(data, {
        onSettled: () => setIsSubmitting(false),
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6">
        {id ? "Edit Academic Year" : "Create Academic Year"}
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
      >
        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="form_label">
                ID <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  {...register("id", {
                    required: "ID is required",
                    pattern: {
                      value: /^[0-9_-]+$/i,
                      message:
                        "ID must be alphanumeric, dashes, or underscores",
                    },
                  })}
                  className={`form_input ${errors.id && requiredFieldClass}`}
                  disabled={!!id}
                />
                {errors.id && (
                  <span className={errorMessageClass}>{errors.id.message}</span>
                )}
              </div>
            </div>

            <div>
              <label className="form_label">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`form_input ${errors.name && requiredFieldClass}`}
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && (
                <span className={errorMessageClass}>{errors.name.message}</span>
              )}
            </div>
          </div>

          {/* Second row: Start Date and End Date */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="form_label">
                Start Date <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  {...register("start_date", {
                    required: "Start date is required",
                  })}
                  className={`form_input ${
                    errors.start_date && requiredFieldClass
                  }`}
                />
                {errors.start_date && (
                  <span className={errorMessageClass}>
                    {errors.start_date.message}
                  </span>
                )}
              </div>
            </div>

            <div>
              <label className="form_label">
                End Date <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  {...register("end_date", {
                    required: "End date is required",
                  })}
                  className={`form_input ${
                    errors.end_date && requiredFieldClass
                  }`}
                />
                {errors.end_date && (
                  <span className={errorMessageClass}>
                    {errors.end_date.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3 pt-5">
          <button
            type="button"
            onClick={() => navigate("/academicyears")}
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {isSubmitting ? (
              <LoadingDots text={id ? "Updating" : "Creating"} />
            ) : id ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AcademicYearForm;
