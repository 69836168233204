import { useCallback } from "react";
import { TableActions } from "../../components/common/TableActions";

export const useTableColumns = () => {
  const columns = [
    {
      header: "Name",
      accessorFn: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone",
      accessorKey: "phone",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <span
          className={`px-2 py-1 rounded-full text-xs ${
            row.original.status === "active"
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {row.original.status}
        </span>
      ),
    },
    {
      header: "Joining Date",
      accessorKey: "joining_date",
    },
    {
      header: "Qualification",
      accessorKey: "qualification",
    },
  ];

  return columns;
};
