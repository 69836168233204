import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useUserQuery } from "../../hooks/user/useUserQuery";
import Table from "../../components/common/Table";
import { createColumnHelper } from "@tanstack/react-table";
import Select from "react-select";
import debounce from "lodash/debounce";
import { useQuery } from "@tanstack/react-query";
import { searchStudents } from "../../Api/studentsServices";
import { searchParents } from "../../Api/parentServices";
import { searchTeachers } from "../../Api/teacherServices";
import axiosInstance from "../../lib/axiosConfig";
import { queryClient } from "../../config/queryClient";
import { toast } from "react-toastify";
import LoadingDots from "../../components/common/LoadingDots";

const ListUsers = () => {
  const navigate = useNavigate();
  const [studentSearch, setStudentSearch] = useState("");
  const [parentSearch, setParentSearch] = useState("");
  const [teacherSearch, setTeacherSearch] = useState("");
  const [selectedStudents, setSelectedStudents] = useState({});
  const [selectedParents, setSelectedParents] = useState({});
  const [selectedTeachers, setSelectedTeachers] = useState({});
  const [openMenus, setOpenMenus] = useState({});

  const {
    data: users,
    isLoading,
    error,
    deactivateMutation,
    pagination: { currentPage, totalPages, nextPage, prevPage, goToPage },
  } = useUserQuery();

  const { data: students, isLoading: isLoadingStudents } = useQuery({
    queryKey: ["students", studentSearch],
    queryFn: () => searchStudents(studentSearch),
    enabled: studentSearch.length >= 2,
    staleTime: 30000,
  });

  const { data: parents, isLoading: isLoadingParents } = useQuery({
    queryKey: ["parents", parentSearch],
    queryFn: () => searchParents(parentSearch),
    enabled: parentSearch.length >= 2,
    staleTime: 30000,
  });

  const { data: teachers, isLoading: isLoadingTeachers } = useQuery({
    queryKey: ["teachers", teacherSearch],
    queryFn: () => searchTeachers(teacherSearch),
    enabled: teacherSearch.length >= 2,
    staleTime: 30000,
  });

  const handleStudentSearch = useCallback(
    debounce((value, userId) => {
      setStudentSearch(value);
      setOpenMenus((prev) => ({
        ...prev,
        [userId]: value.length >= 2,
      }));
    }, 300),
    []
  );

  const handleParentSearch = useCallback(
    debounce((value, userId) => {
      setParentSearch(value);
      setOpenMenus((prev) => ({
        ...prev,
        [userId]: value.length >= 2,
      }));
    }, 300),
    []
  );

  const handleTeacherSearch = useCallback(
    debounce((value, userId) => {
      setTeacherSearch(value);
      setOpenMenus((prev) => ({
        ...prev,
        [userId]: value.length >= 2,
      }));
    }, 300),
    []
  );

  const handleStudentSelect = (userId, selectedOption) => {
    setSelectedStudents((prev) => ({
      ...prev,
      [userId]: selectedOption,
    }));
    setStudentSearch("");
    setOpenMenus((prev) => ({
      ...prev,
      [userId]: false,
    }));
  };

  const handleParentSelect = (userId, selectedOption) => {
    setSelectedParents((prev) => ({
      ...prev,
      [userId]: selectedOption,
    }));
    setParentSearch("");
    setOpenMenus((prev) => ({
      ...prev,
      [userId]: false,
    }));
  };

  const handleTeacherSelect = (userId, selectedOption) => {
    setSelectedTeachers((prev) => ({
      ...prev,
      [userId]: selectedOption,
    }));
    setTeacherSearch("");
    setOpenMenus((prev) => ({
      ...prev,
      [userId]: false,
    }));
  };

  const studentOptions = students?.data?.length
    ? students.data.map((student) => ({
        value: student.id,
        label: `${student.first_name} ${student.last_name}`,
        version: student.version,
      }))
    : [];

  const parentOptions = parents?.data?.length
    ? parents.data.map((parent) => ({
        value: parent.id,
        label: `${parent.first_name} ${parent.last_name}`,
        version: parent.version,
      }))
    : [];

  const teacherOptions = teachers?.data?.length
    ? teachers.data.map((teacher) => ({
        value: teacher.id,
        label: `${teacher.first_name} ${teacher.last_name}`,
        version: teacher.version,
      }))
    : [];

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => `${row.first_name} ${row.last_name}`, {
      id: "name",
      header: "Name",
      cell: (info) => (
        <div className="flex items-center">
          <span
            className={`w-2 h-2 rounded-full mr-2 ${
              info.row.original.status === "active"
                ? "bg-green-500"
                : "bg-gray-400"
            }`}
          ></span>
          <div className="text-sm text-gray-900">{info.getValue()}</div>
        </div>
      ),
    }),
    columnHelper.accessor("email", {
      header: "Email",
      cell: (info) => info.getValue(),
      minWidth: 200,
    }),
    columnHelper.accessor("username", {
      header: "Username",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor("phone", {
      header: "Phone",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("roles", {
      header: "Roles",
      cell: (info) => (
        <div className="flex flex-wrap gap-1">
          {info.getValue().map((role) => (
            <span
              key={role}
              className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
            >
              {role}
            </span>
          ))}
        </div>
      ),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) => (
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
            info.getValue() === "active"
              ? "bg-green-100 text-green-800"
              : info.getValue() === "inactive"
              ? "bg-red-100 text-red-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("id", {
      header: "Actions",
      cell: (info) => {
        const hasStudentRole = info.row.original.roles.includes("student");
        const hasParentRole = info.row.original.roles.includes("parent");
        const hasTeacherRole = info.row.original.roles.includes("teacher");
        const userId = info.getValue();

        const handleActivate = async (userType) => {
          const selectedEntity =
            userType === "student"
              ? selectedStudents[userId]
              : userType === "parent"
              ? selectedParents[userId]
              : selectedTeachers[userId];

          if (!selectedEntity) {
            toast.error("Please select a user from the dropdown first");
            return;
          }

          const requestBody = {
            version: selectedEntity.version,
            user_id: userId,
          };

          try {
            if (userType === "student") {
              await axiosInstance.patch(
                `students/adduser/${selectedEntity.value}`,
                requestBody
              );
              toast.success("Student activated successfully");
            } else if (userType === "parent") {
              await axiosInstance.patch(
                `parents/adduser/${selectedEntity.value}`,
                requestBody
              );
              toast.success("Parent activated successfully");
            } else if (userType === "teacher") {
              await axiosInstance.patch(
                `teachers/adduser/${selectedEntity.value}`,
                requestBody
              );
              toast.success("Teacher activated successfully");
            }
            // Refresh the data after activation
            queryClient.invalidateQueries(["users"]);
          } catch (error) {
            console.error("Error activating user:", error);
            const errorMessage =
              error.response?.data?.detail?.[0]?.msg ||
              error.response?.data?.message ||
              "Failed to activate user. Please try again.";
            toast.error(errorMessage);
          }
        };

        return (
          <div
            className="inline-flex items-center gap-2"
            onClick={(e) => e.stopPropagation()}
          >
            {hasStudentRole && info.row.original.status !== "active" && (
              <div className="w-48">
                <Select
                  isClearable
                  isSearchable
                  placeholder="Search student..."
                  options={studentOptions}
                  onInputChange={(value) => handleStudentSearch(value, userId)}
                  onChange={(option) => handleStudentSelect(userId, option)}
                  isLoading={isLoadingStudents}
                  value={selectedStudents[userId] || null}
                  className="text-sm"
                  filterOption={null}
                  menuIsOpen={openMenus[userId]}
                />
              </div>
            )}
            {hasParentRole && info.row.original.status !== "active" && (
              <div className="w-48">
                <Select
                  isClearable
                  isSearchable
                  placeholder="Search parent..."
                  options={parentOptions}
                  onInputChange={(value) => handleParentSearch(value, userId)}
                  onChange={(option) => handleParentSelect(userId, option)}
                  isLoading={isLoadingParents}
                  value={selectedParents[userId] || null}
                  className="text-sm"
                  filterOption={null}
                  menuIsOpen={openMenus[userId]}
                />
              </div>
            )}
            {hasTeacherRole && info.row.original.status !== "active" && (
              <div className="w-48">
                <Select
                  isClearable
                  isSearchable
                  placeholder="Search teacher..."
                  options={teacherOptions}
                  onInputChange={(value) => handleTeacherSearch(value, userId)}
                  onChange={(option) => handleTeacherSelect(userId, option)}
                  isLoading={isLoadingTeachers}
                  value={selectedTeachers[userId] || null}
                  className="text-sm"
                  filterOption={null}
                  menuIsOpen={openMenus[userId]}
                />
              </div>
            )}
            {info.row.original.status === "active" ? (
              <button
                onClick={() => deactivateMutation.mutate(userId)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full text-xs"
              >
                Deactivate
              </button>
            ) : (
              <button
                onClick={() => {
                  if (hasStudentRole) {
                    handleActivate("student");
                  } else if (hasParentRole) {
                    handleActivate("parent");
                  } else if (hasTeacherRole) {
                    handleActivate("teacher");
                  }
                }}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded-full text-xs"
                disabled={
                  (hasStudentRole && !selectedStudents[userId]) ||
                  (hasParentRole && !selectedParents[userId]) ||
                  (hasTeacherRole && !selectedTeachers[userId])
                }
              >
                Activate
              </button>
            )}
          </div>
        );
      },
    }),
  ];

  const handleLink = () => "/users/create";

  if (isLoading) {
    return <LoadingDots />;
  }

  if (error) return <div>Error loading data: {error.message}</div>;

  return (
    <div className="container mx-auto p-6">
      <Table
        data={users || []}
        columns={columns}
        isLoading={isLoading}
        handleLink={handleLink}
        pagination={{
          currentPage,
          totalPages,
          nextPage,
          prevPage,
          goToPage,
        }}
        tableName="Users"
        baseEditPath="/users/edit"
      />
    </div>
  );
};

export default ListUsers;
