import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAcademicYears } from "../../Api/academicYearServices";
import { useTableColumns } from "../../hooks/academicYear/useTableColumns";
import Table from "../../components/common/Table";
import LoadingDots from "../../components/common/LoadingDots";

const ListAcademicYear = () => {
  const columns = useTableColumns();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: academicYearsData, isLoading } = useQuery({
    queryKey: ["academicYears", currentPage],
    queryFn: () => getAcademicYears(currentPage),
    refetchOnMount: true,
  });

  const pagination = {
    currentPage: academicYearsData?.currentPage || 1,
    totalPages: academicYearsData?.totalPages || 1,
    nextPage: () =>
      setCurrentPage((old) =>
        Math.min(old + 1, academicYearsData?.totalPages || 1)
      ),
    prevPage: () => setCurrentPage((old) => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  const handleLink = () => "/academicyears/create";

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="container mx-auto p-4">
      <Table
        data={academicYearsData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Academic Year"
        baseEditPath="/academicyears/edit"
      />
    </div>
  );
};

export default ListAcademicYear;
