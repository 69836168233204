import axiosInstance from "../lib/axiosConfig";

export const getFeeStructures = async (page = 1) => {
  const response = await axiosInstance.get(`feestructures/?page=${page}`);
  return response.data;
};

export const getFeeStructure = async (id) => {
  const response = await axiosInstance.get(`feestructures/${id}/`);
  return response.data;
};

export const createFeeStructure = async (data) => {
  const response = await axiosInstance.post("feestructures/", data);
  return response.data;
};

export const updateFeeStructure = async (id, data) => {
  const response = await axiosInstance.patch(`feestructures/${id}/`, data);
  return response.data;
};

export const deleteFeeStructure = async (id) => {
  const response = await axiosInstance.delete(`feestructures/${id}/`);
  return response.data;
};
