import { useNavigate } from "react-router-dom";
import { useClassQuery } from "./useClassQuery";
import confirmDelete from "../../lib/confirmDelete";
import { TableActions } from "../../components/common/TableActions";

export const useTableColumns = () => {
  const navigate = useNavigate();
  const { deleteMutation } = useClassQuery();

  const handleEdit = (id) => {
    navigate(`/classes/edit/${id}`);
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error)
        });
      });
    });
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Code",
      accessorKey: "code",
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Sections",
      accessorKey: "sections",
      cell: ({ row }) => {
        const sections = row.original.sections || [];
        return sections.map(section => section.name).join(", ");
      },
    },
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: ({ row }) => (
        <TableActions
          onEdit={() => handleEdit(row.original.id)}
          onDelete={() => handleDelete(row.original.id)}
        />
      ),
    },
  ];

  return columns;
};
