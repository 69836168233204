import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBusRouteById } from "../../Api/busRouteServices";
import { useBusRouteQuery } from "../../hooks/busRoute/useBusRouteQuery";
import { getAcademicYears } from "../../Api/academicYearServices";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import LoadingDots from "../common/LoadingDots";
import axiosInstance from "../../lib/axiosConfig";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const BusRouteForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useBusRouteQuery();
  const [isFeesExpanded, setIsFeesExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      registration_number: "",
      description: "",
      stops: "",
      fees: [{ academic_year_id: "", fee_type_id: "" }],
    },
  });

  const stops = watch("stops");

  const {
    fields: feeFields,
    append: appendFee,
    remove: removeFee,
  } = useFieldArray({
    control,
    name: "fees",
  });

  const { data: selectedBusRoute } = useQuery({
    queryKey: ["busroutes", id],
    queryFn: () => getBusRouteById(id),
    enabled: !!id,
  });

  const { data: academicYears = [] } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
  });

  const { data: feeTypes = [] } = useQuery({
    queryKey: ["feeTypes"],
    queryFn: () => axiosInstance.get("feetypes/").then((res) => res.data),
  });

  useEffect(() => {
    if (selectedBusRoute) {
      reset({
        ...selectedBusRoute,
        stops: selectedBusRoute.stops ? selectedBusRoute.stops.join(", ") : "",
        fees: selectedBusRoute.fees || [
          { academic_year_id: "", fee_type_id: "" },
        ],
      });
      if (selectedBusRoute.fees?.length > 0) {
        setIsFeesExpanded(true);
      }
    }
  }, [selectedBusRoute, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      // Convert comma-separated stops to array
      const stopsArray = data.stops
        .split(",")
        .map((stop) => stop.trim())
        .filter((stop) => stop !== "");

      if (stopsArray.length === 0) {
        setIsSubmitting(false);
        return;
      }

      const processedData = {
        ...data,
        description: data.description || null,
        stops: stopsArray,
        fees: data.fees.map((fee) => ({
          ...fee,
          bus_route_id: id || null,
        })),
      };

      if (id) {
        editMutation.mutate(
          { id, updatedData: processedData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/busroutes/all");
            },
            onError: (error) => {
              console.error("Error updating bus route:", error);
              setIsSubmitting(false);
            },
          }
        );
      } else {
        createMutation.mutate(processedData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/busroutes/all");
          },
          onError: (error) => {
            console.error("Error creating bus route:", error);
            setIsSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setIsSubmitting(false);
    }
  };

  if (id && !selectedBusRoute) {
    return <LoadingDots />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
    >
      <h2 className="text-2xl font-bold mb-6">
        {id ? "Edit Bus Route" : "Create New Bus Route"}
      </h2>

      <div className="space-y-6">
        <div>
          <label className="form_label">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            className={`form_input ${errors.name && requiredFieldClass}`}
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && (
            <span className={errorMessageClass}>{errors.name.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">
            Registration Number <span className="text-red-500">*</span>
          </label>
          <input
            className={`form_input ${
              errors.registration_number && requiredFieldClass
            }`}
            {...register("registration_number", {
              required: "Registration number is required",
            })}
          />
          {errors.registration_number && (
            <span className={errorMessageClass}>
              {errors.registration_number.message}
            </span>
          )}
        </div>

        <div>
          <label className="form_label">
            Description <span className="text-gray-500 text-sm">Optional</span>
          </label>
          <textarea className="form_input" {...register("description")} />
        </div>

        <div>
          <label className="form_label">
            Stops <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={`form_input ${errors.stops && requiredFieldClass}`}
            {...register("stops", {
              required: "Stops are required",
              validate: (value) => {
                const stops = value
                  .split(",")
                  .map((s) => s.trim())
                  .filter((s) => s);
                return stops.length > 0 || "At least one stop is required";
              },
            })}
            placeholder="Enter stops separated by commas (e.g., Stop 1, Stop 2, Stop 3)"
          />
          {errors.stops && (
            <span className={errorMessageClass}>{errors.stops.message}</span>
          )}
          <p className="mt-1 text-sm text-gray-500">
            Separate stops with commas (e.g., Stop 1, Stop 2, Stop 3)
          </p>
        </div>

        <div className="border rounded-lg overflow-hidden mt-8">
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
            onClick={() => setIsFeesExpanded(!isFeesExpanded)}
          >
            <span className="text-lg font-semibold">Fees</span>
            {isFeesExpanded ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </button>

          {isFeesExpanded && (
            <div className="p-4 space-y-4">
              {feeFields.map((field, index) => (
                <div key={field.id} className="p-4 border rounded-lg space-y-4">
                  <div className="flex justify-between items-center">
                    <h4 className="font-medium">Fee #{index + 1}</h4>
                    {feeFields.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeFee(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    )}
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="form_label">
                        Academic Year <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`form_input ${
                          errors.fees?.[index]?.academic_year_id &&
                          requiredFieldClass
                        }`}
                        {...register(`fees.${index}.academic_year_id`, {
                          required: "Academic year is required",
                        })}
                      >
                        <option value="">Select Academic Year</option>
                        {academicYears?.data?.map((year) => (
                          <option key={year.id} value={year.id}>
                            {year.name}
                          </option>
                        ))}
                      </select>
                      {errors.fees?.[index]?.academic_year_id && (
                        <span className={errorMessageClass}>
                          {errors.fees[index].academic_year_id.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label className="form_label">
                        Fee Type <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`form_input ${
                          errors.fees?.[index]?.fee_type_id &&
                          requiredFieldClass
                        }`}
                        {...register(`fees.${index}.fee_type_id`, {
                          required: "Fee type is required",
                        })}
                      >
                        <option value="">Select Fee Type</option>
                        {feeTypes?.data?.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name} - {type.amount}
                          </option>
                        ))}
                      </select>
                      {errors.fees?.[index]?.fee_type_id && (
                        <span className={errorMessageClass}>
                          {errors.fees[index].fee_type_id.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  appendFee({ academic_year_id: "", fee_type_id: "" })
                }
                className="w-full p-2 bg-gray-50 hover:bg-gray-100 text-gray-600 rounded-lg"
              >
                + Add Fee
              </button>
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate("/busroutes/all")}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? (
              <LoadingDots text={id ? "Updating" : "Creating"} />
            ) : id ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default BusRouteForm;
