import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getToken } from "../Api/userServices";
import { toast } from "react-toastify";
import axiosInstance from "../lib/axiosConfig";

// Create AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [user, setUser] = useState(() => {
    try {
      // Initialize user from localStorage if available
      const storedUser = localStorage.getItem("user");
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error("Error loading stored user:", error);
      return null;
    }
  });

  const loginMutation = useMutation({
    mutationFn: (credentials) => getToken(credentials),
    onSuccess: (data) => {
      try {
        // Store the access token in the user object
        const userData = {
          ...data.user,
          access_token: data.access_token,
        };
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));

        if (data.process_steps) {
          localStorage.setItem(
            "process_steps",
            JSON.stringify(data.process_steps)
          );
        } else {
          localStorage.setItem("process_steps", JSON.stringify([]));
        }

        navigate("/dashboard");
      } catch (error) {
        console.error("Error saving user data:", error);
        toast.error("Error saving user data. Please try logging in again.");
        throw error; // Propagate the error
      }
    },
    onError: (error) => {
      console.error("Login failed:", error);
      const errorMessage =
        error.response?.data?.detail?.[0]?.msg ||
        error.response?.data?.message ||
        "Invalid credentials. Please try again.";
      toast.error(errorMessage);
      throw error; // Propagate the error to the component
    },
  });

  // Handle login using React Query mutation
  const login = async (credentials) => {
    return loginMutation.mutateAsync(credentials);
  };

  const registerUser = async (data) => {
    try {
      const response = await axiosInstance.post("/users/create", data);

      toast.success("Registration successful! Please login.");
      navigate("/login");
    } catch (error) {
      console.error("Error registering user:", error);
      const errorMessage =
        error.response?.data?.detail?.[0]?.msg ||
        error.response?.data?.message ||
        "Error registering user. Please try again.";
      toast.error(errorMessage);
    }
  };

  // Handle logout
  const logout = () => {
    setUser(null);
    localStorage.clear(); // Clear all localStorage items
    queryClient.clear(); // Clear React Query cache
    window.location.href = "/login"; // Force a full page reload to login
  };

  // Optionally, you can add logic to refresh tokens here

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        registerUser,
        isLoading: loginMutation.isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
