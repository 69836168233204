import React, { useState, useEffect, useRef } from "react";
import { Search } from "lucide-react";
import { findAddress } from "../../Api/addressServices";

const AddressField = ({ register, setValue, errors, initialValue, watch }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userTyping, setUserTyping] = useState(false);
  const wrapperRef = useRef(null);

  // Watch the municipality field
  const municipalityValue = watch ? watch("address.municipality") : "";

  // Fetch suggestions from API
  const fetchMunicipalitySuggestions = async (query) => {
    try {
      const data = await findAddress(query);
      return data || [];
    } catch (error) {
      console.error("Error fetching municipality suggestions:", error);
      throw new Error("Failed to fetch suggestions");
    }
  };

  // Handle clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Fetch suggestions when municipality input changes
  useEffect(() => {
    let timeoutId;

    const fetchSuggestions = async () => {
      if (municipalityValue && municipalityValue.length > 2 && userTyping) {
        setIsLoading(true);
        setError(null);

        try {
          const data = await fetchMunicipalitySuggestions(municipalityValue);
          setSuggestions(data);
          setShowSuggestions(true);
        } catch (err) {
          setError(err.message);
          setSuggestions([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    };

    // Debounce the API call
    timeoutId = setTimeout(fetchSuggestions, 500);

    return () => clearTimeout(timeoutId);
  }, [municipalityValue, userTyping]);

  const handleInputFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleSelectSuggestion = (address) => {
    setValue("address.municipality", address.municipality);
    setValue("address.district", address.district);
    setValue("address.province", address.province);
    setValue("address.ward", address.ward || "");
    setShowSuggestions(false);
  };

  return (
    <div className="grid grid-cols-2 gap-6 mb-4" ref={wrapperRef}>
      <div className="relative">
        <label className="form_label">Municipality</label>
        <div className="relative">
          <input
            type="text"
            className="form_input"
            autoComplete="off"
            {...register("address.municipality")}
            onChange={(e) => {
              register("address.municipality").onChange(e);
              setUserTyping(true);
            }}
            onFocus={handleInputFocus}
          />
          <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          {isLoading && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-10">
              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900"></div>
            </div>
          )}
          {showSuggestions && suggestions.length > 0 && (
            <ul className="absolute z-50 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto shadow-lg left-0 right-0">
              {suggestions.map((address, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSelectSuggestion(address)}
                >
                  {address.municipality}, {address.district}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div>
        <label className="form_label">Ward</label>
        <input
          type="number"
          className="form_input"
          {...register("address.ward")}
        />
      </div>

      <div>
        <label className="form_label">District</label>
        <input
          type="text"
          className="form_input"
          {...register("address.district")}
          readOnly
        />
      </div>

      <div>
        <label className="form_label">Province</label>
        <input
          type="text"
          className="form_input"
          {...register("address.province")}
          readOnly
        />
      </div>

      <div className="col-span-2">
        <label className="form_label">Tole</label>
        <input
          type="text"
          className="form_input"
          {...register("address.tole")}
          placeholder="Enter tole name (optional)"
        />
      </div>
    </div>
  );
};

export default AddressField;
