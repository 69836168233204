import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getEnrollmentsByYearAndClass } from "../../Api/enrollmentServices";
import { getAllSubjects } from "../../Api/subjectServices";
import { getAllTerms } from "../../Api/termServices";
import { getMarks, deleteMarks } from "../../Api/marksServices";
import LoadingDots from "../../components/common/LoadingDots";
import { useMarksQuery } from "../../hooks/marks/useMarksQuery";
import { useNavigate } from "react-router-dom";
import confirmDelete from "../../lib/confirmDelete";
import { Trash } from "lucide-react";
import { toast } from "react-toastify";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const typeOptions = [
  { value: "theory", label: "Theory" },
  { value: "practical", label: "Practical" },
];

const MarksForm = ({ academicYearId, classId }) => {
  const navigate = useNavigate();
  const { createMutation } = useMarksQuery();
  const [existingMarks, setExistingMarks] = useState([]);
  const [fetchingMarks, setFetchingMarks] = useState(false);
  const [showEntryForm, setShowEntryForm] = useState(true);
  const [localEnrollments, setLocalEnrollments] = useState([]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subject_id: null,
      term_id: null,
      type: typeOptions[0],
      full_marks: "",
      marks: [],
    },
  });

  const selectedSubject = watch("subject_id");
  const selectedTerm = watch("term_id");

  const { data: subjects, isLoading: loadingSubjects } = useQuery({
    queryKey: ["subjects"],
    queryFn: getAllSubjects,
  });

  const { data: terms, isLoading: loadingTerms } = useQuery({
    queryKey: ["terms"],
    queryFn: getAllTerms,
  });

  const { data: enrollments, isLoading: loadingEnrollments } = useQuery({
    queryKey: ["enrollments", academicYearId, classId],
    queryFn: () => getEnrollmentsByYearAndClass(academicYearId, classId),
    enabled: !!academicYearId && !!classId,
  });

  useEffect(() => {
    if (enrollments) {
      setLocalEnrollments(enrollments);
      setShowEntryForm(true); // Reset form visibility when enrollments change
      setExistingMarks([]); // Clear existing marks
    }
  }, [enrollments]);

  // Reset form state when component unmounts or when academicYearId/classId changes
  useEffect(() => {
    return () => {
      setShowEntryForm(true);
      setExistingMarks([]);
      setLocalEnrollments([]);
    };
  }, [academicYearId, classId]);

  const handleFetchMarks = async () => {
    const subject = watch("subject_id");
    const term = watch("term_id");

    if (!subject || !term) {
      toast.info("Please select both subject and term");
      return;
    }

    try {
      setFetchingMarks(true);
      const response = await getMarks({
        offset: 0,
        limit: 10,
        subject_id: subject.value,
        term_id: term.value,
      });

      if (response && response.data && response.data.length > 0) {
        setExistingMarks(response.data);
        setShowEntryForm(false);
      } else {
        setExistingMarks([]);
        setShowEntryForm(true); // Show form when no existing marks found
        toast.info("No existing marks found for the selected criteria");
      }
    } catch (error) {
      console.error("Error fetching marks:", error);
      toast.error("Error fetching marks. Please try again.");
      setShowEntryForm(true); // Show form on error
    } finally {
      setFetchingMarks(false);
    }
  };

  const handleNewEntry = () => {
    setExistingMarks([]);
    setShowEntryForm(true);
    setLocalEnrollments(enrollments || []); // Reset local enrollments to original state
  };

  const handleDelete = (markId, event) => {
    event.preventDefault();
    event.stopPropagation();
    confirmDelete(async () => {
      try {
        await deleteMarks(markId);
        setExistingMarks((prevMarks) =>
          prevMarks.filter((mark) => mark.id !== markId)
        );
      } catch (error) {
        toast.error("Error deleting mark:", error);
      }
    });
  };

  const handleRemoveStudent = (enrollmentId) => {
    setLocalEnrollments((prev) =>
      prev.filter((enr) => enr.id !== enrollmentId)
    );
  };

  const onSubmit = async (data) => {
    try {
      if (!localEnrollments || localEnrollments.length === 0) {
        toast.error("No enrollments found to submit marks for");
        return;
      }

      const marksPayload = localEnrollments.map((enrollment, index) => ({
        enrollment_id: enrollment.id,
        subject_id: data.subject_id?.value,
        term_id: data.term_id?.value,
        type: data.type?.value,
        full_marks: parseInt(data.full_marks || 0),
        obtained_marks: parseInt(data.marks?.[index]?.obtained_marks || 0),
      }));

      await createMutation.mutateAsync(marksPayload);
      navigate("/marks");
    } catch (error) {
      console.error("Error submitting marks:", error);
      toast.error(error.message || "Error submitting marks. Please try again.");
    }
  };

  if (loadingSubjects || loadingTerms || loadingEnrollments) {
    return <LoadingDots />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Subject <span className="text-red-500">*</span>
          </label>
          <Controller
            name="subject_id"
            control={control}
            rules={{ required: "Subject is required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={subjects?.map((subject) => ({
                  value: subject.id,
                  label: subject.name,
                }))}
                placeholder="Select Subject"
                className={`basic-single ${
                  errors.subject_id ? "select-error" : ""
                }`}
                classNamePrefix="select"
              />
            )}
          />
          {errors.subject_id && (
            <p className={errorMessageClass}>{errors.subject_id.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Term <span className="text-red-500">*</span>
          </label>
          <Controller
            name="term_id"
            control={control}
            rules={{ required: "Term is required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={terms?.map((term) => ({
                  value: term.id,
                  label: term.name,
                }))}
                placeholder="Select Term"
                className={`basic-single ${
                  errors.term_id ? "select-error" : ""
                }`}
                classNamePrefix="select"
              />
            )}
          />
          {errors.term_id && (
            <p className={errorMessageClass}>{errors.term_id.message}</p>
          )}
        </div>
      </div>

      <div className="flex justify-end space-x-4 mb-6">
        <button
          type="button"
          onClick={handleFetchMarks}
          disabled={fetchingMarks}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
        >
          {fetchingMarks ? (
            <LoadingDots text="Fetching" />
          ) : (
            "Fetch Existing Marks"
          )}
        </button>
      </div>

      {existingMarks.length > 0 ? (
        <div className="mt-4">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Student Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Class
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Section
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subject
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Year
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Term
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Obtained Marks
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Full Marks
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-300">
                {existingMarks.map((mark, index) => (
                  <tr
                    key={mark.id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.student_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.class_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.section_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.subject_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.academic_year_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.term_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{mark.type}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.obtained_marks}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {mark.full_marks}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        type="button"
                        onClick={(e) => handleDelete(mark.id, e)}
                        className="p-1 bg-red-50 text-red-600 rounded-full hover:bg-red-100"
                      >
                        <Trash size={16} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            onClick={handleNewEntry}
            className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Enter New Marks
          </button>
        </div>
      ) : (
        showEntryForm && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="form_label">
                  Type <span className="text-red-500">*</span>
                </label>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: "Type is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={typeOptions}
                      placeholder="Select Type"
                      className={`basic-single ${
                        errors.type ? "select-error" : ""
                      }`}
                      classNamePrefix="select"
                    />
                  )}
                />
                {errors.type && (
                  <p className={errorMessageClass}>{errors.type.message}</p>
                )}
              </div>

              <div>
                <label className="form_label">
                  Full Marks <span className="text-red-500">*</span>
                </label>
                <Controller
                  name="full_marks"
                  control={control}
                  rules={{
                    required: "Full marks is required",
                    pattern: {
                      value: /^\d+(\.\d{0,2})?$/,
                      message:
                        "Please enter a valid number with up to 2 decimal places",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      className={`form_input ${
                        errors.full_marks ? requiredFieldClass : ""
                      }`}
                      placeholder="Enter full marks"
                    />
                  )}
                />
                {errors.full_marks && (
                  <p className={errorMessageClass}>
                    {errors.full_marks.message}
                  </p>
                )}
              </div>
            </div>

            {!loadingEnrollments &&
              localEnrollments &&
              localEnrollments.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Student Marks
                  </h3>
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="form_label">Student Name</label>
                      </div>
                      <div>
                        <label className="form_label">
                          Obtained Marks <span className="text-red-500">*</span>
                        </label>
                      </div>
                    </div>
                    {localEnrollments.map((enrollment, index) => (
                      <div
                        key={enrollment.id}
                        className="grid grid-cols-2 gap-4 items-center"
                      >
                        <div className="flex-grow flex items-center justify-between">
                          <span className="form_label">
                            {enrollment.student_name}
                          </span>
                          <button
                            type="button"
                            onClick={() => handleRemoveStudent(enrollment.id)}
                            className="p-1 bg-red-50 text-red-600 rounded-full hover:bg-red-100 ml-2"
                          >
                            <Trash size={16} />
                          </button>
                        </div>
                        <div className="flex-grow">
                          <Controller
                            name={`marks.${index}.obtained_marks`}
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Obtained marks is required",
                              pattern: {
                                value: /^\d+(\.\d{0,2})?$/,
                                message:
                                  "Please enter a valid number with up to 2 decimal places",
                              },
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                className={`form_input ${
                                  errors.marks?.[index]?.obtained_marks
                                    ? requiredFieldClass
                                    : ""
                                }`}
                                placeholder="Enter obtained marks"
                              />
                            )}
                          />
                          {errors.marks?.[index]?.obtained_marks && (
                            <p className={errorMessageClass}>
                              {errors.marks[index].obtained_marks.message}
                            </p>
                          )}
                          <input
                            type="hidden"
                            {...register(`marks.${index}.enrollment_id`)}
                            value={enrollment.id}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                disabled={createMutation.isLoading}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                {createMutation.isLoading ? "Submitting..." : "Submit Marks"}
              </button>
            </div>
          </>
        )
      )}
    </form>
  );
};

export default MarksForm;
