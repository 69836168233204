import { useNavigate } from "react-router-dom";
import { useAcademicYearQuery } from "./useAcademicYearQuery";
import confirmDelete from "../../lib/confirmDelete";
import dayjs from "dayjs";
import { TableActions } from "../../components/common/TableActions";

export const useTableColumns = () => {
  const navigate = useNavigate();
  const { deleteMutation } = useAcademicYearQuery();

  const handleEdit = (id) => {
    navigate(`/academicyears/edit/${id}`);
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error),
        });
      });
    });
  };

  const columns = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Start Date",
      accessorKey: "start_date",
      cell: (info) => dayjs(info.getValue()).format("YYYY-MM-DD"),
    },
    {
      header: "End Date",
      accessorKey: "end_date",
      cell: (info) => dayjs(info.getValue()).format("YYYY-MM-DD"),
    },
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: ({ row }) => (
        <TableActions
          onEdit={() => handleEdit(row.original.id)}
          onDelete={() => handleDelete(row.original.id)}
        />
      ),
    },
  ];

  return columns;
};
