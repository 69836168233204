import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getTeachers } from "../../Api/teacherServices";
import { useTableColumns } from "./useTableColumns";
import usePagination from "../usePagination";

export const useTeachersTable = () => {
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } =
    usePagination();
  const [searchQuery, setSearchQuery] = useState("");

  // Fetching teachers with pagination
  const {
    data = {
      data: [],
      metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 },
    },
    error,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["teachers", offset, limit, searchQuery],
    queryFn: async () => {
      const result = await getTeachers({ offset, limit });
      return result;
    },
    keepPreviousData: true,
  });

  const totalItems = data.metadata?.count || 0;
  const totalPages = Math.ceil(totalItems / limit);

  // Table columns setup
  const columns = useTableColumns();

  const pagination = {
    currentPage,
    totalPages,
    nextPage: () => nextPage(totalPages),
    prevPage,
    goToPage: (page) => goToPage(page, totalPages),
  };

  return {
    data,
    columns,
    error,
    isLoading,
    isFetching,
    pagination,
    nextPage,
    prevPage,
    currentPage,
    totalPages,
    setSearchQuery,
  };
};
