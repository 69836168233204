import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useClassQuery } from "../../hooks/class/useClassQuery";
import { getClassById } from "../../Api/classServices";
import { getAcademicYears } from "../../Api/academicYearServices";
import { getAllStudents } from "../../Api/studentsServices";
import { getTeachers } from "../../Api/teacherServices";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import LoadingDots from "../common/LoadingDots";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const ClassForm = ({ id }) => {
  const [isSectionsExpanded, setIsSectionsExpanded] = useState(false);
  const [isAcademicYearsExpanded, setIsAcademicYearsExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { editMutation, createMutation } = useClassQuery();
  const navigate = useNavigate();

  const { data: selectedClass } = useQuery({
    queryKey: ["classes", id],
    queryFn: () => getClassById(id),
    enabled: !!id,
  });

  const { data: academicYears = [], isLoading: isLoadingAcademicYears } =
    useQuery({
      queryKey: ["academicYears"],
      queryFn: getAcademicYears,
      staleTime: 5 * 60 * 1000,
    });

  const { data: students = [], isLoading: isLoadingStudents } = useQuery({
    queryKey: ["students"],
    queryFn: () => getAllStudents({ limit: 1000 }),
    staleTime: 5 * 60 * 1000,
  });

  const { data: teachers = [], isLoading: isLoadingTeachers } = useQuery({
    queryKey: ["teachers"],
    queryFn: getTeachers,
    staleTime: 5 * 60 * 1000,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      sections: [{ name: "", description: "" }],
      class_academic_years: [
        {
          academic_year_id: "",
          captain_id: "",
          vice_captain_id: "",
          teacher_id: null,
        },
      ],
    },
  });

  const {
    fields: sectionFields,
    append: appendSection,
    remove: removeSection,
  } = useFieldArray({
    control,
    name: "sections",
  });

  const {
    fields: academicYearFields,
    append: appendAcademicYear,
    remove: removeAcademicYear,
  } = useFieldArray({
    control,
    name: "class_academic_years",
  });

  useEffect(() => {
    if (selectedClass) {
      reset(selectedClass);
      if (selectedClass.sections?.length > 0) {
        setIsSectionsExpanded(true);
      }
      if (selectedClass.class_academic_years?.length > 0) {
        setIsAcademicYearsExpanded(true);
      }
    }
  }, [selectedClass, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      // Convert empty strings to null
      const processedData = {
        ...data,
        description: data.description || null,
        sections: data.sections.map((section) => ({
          ...section,
          description: section.description || null,
        })),
        class_academic_years: data.class_academic_years.map((year) => ({
          ...year,
          academic_year_id: parseInt(year.academic_year_id),
          captain_id: year.captain_id ? parseInt(year.captain_id) : null,
          vice_captain_id: year.vice_captain_id
            ? parseInt(year.vice_captain_id)
            : null,
          teacher_id: year.teacher_id ? parseInt(year.teacher_id) : null,
        })),
      };

      if (id) {
        editMutation.mutate(
          { id, updatedData: processedData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/classes/all");
            },
            onError: () => {
              setIsSubmitting(false);
            },
          }
        );
      } else {
        createMutation.mutate(processedData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/classes/all");
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
    >
      <h2 className="text-2xl font-bold mb-6">
        {id ? "Edit Class" : "Create New Class"}
      </h2>

      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="form_label">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              className={`form_input ${errors.name && requiredFieldClass}`}
              {...register("name", { required: "Name is required" })}
            />
            {errors.name && (
              <span className={errorMessageClass}>{errors.name.message}</span>
            )}
          </div>

          <div>
            <label className="form_label">
              Code <span className="text-red-500">*</span>
            </label>
            <input
              className={`form_input ${errors.code && requiredFieldClass}`}
              {...register("code", { required: "Code is required" })}
            />
            {errors.code && (
              <span className={errorMessageClass}>{errors.code.message}</span>
            )}
          </div>
        </div>

        <div>
          <label className="form_label">
            Description <span className="text-gray-500 text-sm">Optional</span>
          </label>
          <textarea className="form_input" {...register("description")} />
        </div>

        <div className="mb-8">
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            onClick={() => setIsSectionsExpanded(!isSectionsExpanded)}
          >
            <h3 className="text-lg font-semibold">Sections</h3>
            {isSectionsExpanded ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </button>

          {isSectionsExpanded && (
            <div className="mt-4 space-y-4">
              {sectionFields.map((field, index) => (
                <div key={field.id} className="p-4 border rounded-lg space-y-4">
                  <div className="flex justify-between items-center">
                    <h4 className="font-medium">#{index + 1}</h4>
                    <button
                      type="button"
                      onClick={() => removeSection(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="form_label">
                        Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        className={`form_input ${
                          errors.sections?.[index]?.name && requiredFieldClass
                        }`}
                        {...register(`sections.${index}.name`, {
                          required: "Section name is required",
                        })}
                      />
                      {errors.sections?.[index]?.name && (
                        <span className={errorMessageClass}>
                          {errors.sections[index].name.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label className="form_label">
                        Description{" "}
                        <span className="text-gray-500 text-sm">Optional</span>
                      </label>
                      <textarea
                        className="form_input"
                        {...register(`sections.${index}.description`)}
                      />
                    </div>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() => appendSection({ name: "", description: "" })}
                className="mt-4 w-full py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Add Section
              </button>
            </div>
          )}
        </div>

        <div className="mb-8">
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            onClick={() => setIsAcademicYearsExpanded(!isAcademicYearsExpanded)}
          >
            <h3 className="text-lg font-semibold">Academic Years</h3>
            {isAcademicYearsExpanded ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </button>

          {isAcademicYearsExpanded && (
            <div className="mt-4 space-y-4">
              {academicYearFields.map((field, index) => (
                <div key={field.id} className="p-4 border rounded-lg">
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-medium">#{index + 1}</h4>
                    <button
                      type="button"
                      onClick={() => removeAcademicYear(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                      <label className="form_label">
                        Academic Year <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`form_input ${
                          errors.class_academic_years?.[index]
                            ?.academic_year_id && requiredFieldClass
                        }`}
                        {...register(
                          `class_academic_years.${index}.academic_year_id`,
                          {
                            required: "Academic year is required",
                          }
                        )}
                      >
                        <option value="">Select Academic Year</option>
                        {academicYears?.data?.map((year) => (
                          <option key={year.id} value={year.id}>
                            {year.name}
                          </option>
                        ))}
                      </select>
                      {errors.class_academic_years?.[index]
                        ?.academic_year_id && (
                        <span className={errorMessageClass}>
                          {
                            errors.class_academic_years[index].academic_year_id
                              .message
                          }
                        </span>
                      )}
                    </div>

                    <div className="col-span-1">
                      <label className="form_label">
                        Teacher{" "}
                        <span className="text-gray-500 text-sm">Optional</span>
                      </label>
                      <select
                        className="form_input"
                        {...register(
                          `class_academic_years.${index}.teacher_id`
                        )}
                      >
                        <option value="">Select Teacher</option>
                        {teachers?.data?.map((teacher) => (
                          <option key={teacher.id} value={teacher.id}>
                            {teacher.first_name} {teacher.last_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-span-1">
                      <label className="form_label">
                        Captain{" "}
                        <span className="text-gray-500 text-sm">Optional</span>
                      </label>
                      <select
                        className="form_input"
                        {...register(
                          `class_academic_years.${index}.captain_id`
                        )}
                      >
                        <option value="">Select Captain</option>
                        {students?.data?.map((student) => (
                          <option key={student.id} value={student.id}>
                            {student.first_name} {student.last_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-span-1">
                      <label className="form_label">
                        Vice Captain{" "}
                        <span className="text-gray-500 text-sm">Optional</span>
                      </label>
                      <select
                        className="form_input"
                        {...register(
                          `class_academic_years.${index}.vice_captain_id`
                        )}
                      >
                        <option value="">Select Vice Captain</option>
                        {students?.data?.map((student) => (
                          <option key={student.id} value={student.id}>
                            {student.first_name} {student.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  appendAcademicYear({
                    academic_year_id: "",
                    captain_id: "",
                    vice_captain_id: "",
                    teacher_id: null,
                  })
                }
                className="mt-4 w-full py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Add Academic Year
              </button>
            </div>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={() => navigate("/classes/all")}
            className="px-4 py-2 text-gray-600 bg-gray-100 rounded hover:bg-gray-200"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-blue-300"
            disabled={isSubmitting}
          >
            {isSubmitting ? <LoadingDots /> : id ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ClassForm;
