import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createMarks } from "../../Api/marksServices";
import { toast } from "react-toastify";

export const useMarksQuery = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: createMarks,
    onSuccess: () => {
      queryClient.invalidateQueries(["marks"]);
      toast.success("Marks submitted successfully!");
    },
    onError: (error) => {
      if (error.response?.data?.detail) {
        const detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail.forEach(err => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          toast.error(detail.msg || JSON.stringify(detail));
        }
        return; // Exit early to prevent showing generic error
      }
      toast.error(error.response?.data?.message || "Failed to submit marks. Please try again.");
    },
  });

  return {
    createMutation,
  };
};

export default useMarksQuery;
