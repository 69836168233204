import axiosInstance from "../lib/axiosConfig";

// Function to get all students
export const getAllStudents = async ({ offset = 0, limit = 15 }) => {
  try {
    const { data } = await axiosInstance.get("students/", {
      params: {
        offset,
        limit,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

// Function to create a student
export const createStudent = async (newStudent) => {
  try {
    const response = await axiosInstance.post("students/", newStudent);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// // Function to delete a student
export const deleteStudent = async (studentId) => {
  try {
    const response = await axiosInstance.delete(`students/${studentId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to edit a student
export const editStudent = async (studentId, updatedData) => {
  try {
    const response = await axiosInstance.patch(
      `students/${studentId}`,
      updatedData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to get a single student
export const getStudentById = async (studentId) => {
  try {
    const response = await axiosInstance.get(`students/${studentId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to search students by name
export const searchStudents = async (
  searchQuery,
  { offset = 0, limit = 10 } = {}
) => {
  try {
    const { data } = await axiosInstance.get("students/", {
      params: {
        offset,
        limit,
        filter: `first_name~${searchQuery}`,
      },
    });
    console.log("Search response:", data); // Debug log
    return data;
  } catch (error) {
    throw error;
  }
};
