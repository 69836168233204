import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllParents, deleteParent, editParent, createParent } from "../../Api/parentServices";
import usePagination from "../usePagination";
import { toast } from "react-toastify";

// Handles data fetching and pagination
export const useParentQuery = (onSuccessCallback) => {
  const queryClient = useQueryClient();
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } = usePagination();

  // Fetching parents with pagination
  const { data = { data: [], metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 } }, error, isLoading, isFetching } = useQuery({
    queryKey: ["parents", offset, limit],
    queryFn: () => getAllParents({ offset, limit }),
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const totalItems = data.metadata?.count || 0;
  const totalPages = Math.ceil(totalItems / limit);

  const handleError = (error) => {
    if (error.response?.status === 422 && error.response?.data?.detail) {
      const detail = error.response.data.detail;
      if (Array.isArray(detail)) {
        detail.forEach(err => {
          const message = err.msg || JSON.stringify(err);
          toast.error(message);
        });
      } else {
        toast.error(error.response.data.detail);
      }
    } else {
      toast.error(error.response?.data?.message || error.response?.data?.detail || "Operation failed. Please try again.");
    }
  };

  // Delete mutation
  const deleteMutation = useMutation({
    mutationFn: deleteParent,
    onSuccess: () => {
      queryClient.invalidateQueries(["parents"]);
    },
    onError: handleError,
  });

  // Edit mutation
  const editMutation = useMutation({
    mutationFn: ({id, updatedData}) => editParent(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(["parents"]);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: handleError,
  });

  // Create Mutation
  const createMutation = useMutation({
    mutationFn: createParent,
    onSuccess: () => {
      queryClient.invalidateQueries(["parents"]);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: handleError,
  });

  return {
    data: data.data,
    metadata: data.metadata,
    isLoading,
    isFetching,
    error,
    pagination: {
      currentPage,
      totalPages,
      nextPage,
      prevPage,
      goToPage,
    },
    deleteMutation,
    editMutation,
    createMutation,
  };
};