import React from "react";
import NoticeList from "../../components/Notice/NoticeList";

const ListNotice = () => {
  return (
    <div>
      <NoticeList />
    </div>
  );
};

export default ListNotice;
