import React from "react";
import { useNavigate } from "react-router-dom";
import { useFeeTypeQuery } from "../../hooks/feeType/useFeeTypeQuery";
import FeeTypeForm from "../../components/FeeType/FeeTypeForm";

const CreateFeeType = () => {
  const navigate = useNavigate();
  const { createMutation } = useFeeTypeQuery();

  const handleSubmit = async (data) => {
    await createMutation.mutateAsync(data);
    navigate("/feetypes/all");
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6"></div>
      <FeeTypeForm onSubmit={handleSubmit} />
    </div>
  );
};

export default CreateFeeType;
