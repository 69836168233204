import React from "react";
import ListEnrollment from "../../components/Enrollment/ListEnrollment";

const ListEnrollmentPage = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="mt-8">
        <ListEnrollment />
      </div>
    </div>
  );
};

export default ListEnrollmentPage;
