import axiosInstance from "../lib/axiosConfig";

export const createMarks = async (marksData) => {
  const response = await axiosInstance.post("marks/", marksData);
  return response.data;
};

export const deleteMarks = async (markId) => {
  const response = await axiosInstance.delete(`marks/${markId}`);
  return response.data;
};

export const getMarks = async ({
  offset = 0,
  limit = 10,
  subject_id,
  term_id,
}) => {
  const filters = [];
  if (subject_id) filters.push(`subject_id=${subject_id}`);
  if (term_id) filters.push(`term_id=${term_id}`);

  const response = await axiosInstance.get(`marks/`, {
    params: {
      offset,
      limit,
      filter: filters,
    },
    paramsSerializer: {
      indexes: null,
    },
  });

  return response.data;
};
