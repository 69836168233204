import React, { useEffect, useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import debounce from "lodash/debounce";
import { useEnrollmentQuery } from "../../hooks/enrollment/useEnrollmentQuery";
import {
  getEnrollmentById,
  getClasses,
  getClassSubjects,
  getAcademicYears,
  getStudents,
  getSections,
} from "../../Api/enrollmentServices";
import { searchStudents } from "../../Api/studentsServices";
import LoadingDots from "../common/LoadingDots";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const EnrollmentForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useEnrollmentQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(true);
  const [isSubjectsExpanded, setIsSubjectsExpanded] = useState(true);
  const [studentSearch, setStudentSearch] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      student_id: null,
      class_id: null,
      academic_year_id: null,
      section_id: null,
      subjects: [],
    },
  });

  const { data: selectedEnrollment, isLoading: isLoadingEnrollment } = useQuery(
    {
      queryKey: ["enrollment", id],
      queryFn: () => getEnrollmentById(id),
      enabled: !!id,
    }
  );

  const { data: academicYears, isLoading: isLoadingAcademicYears } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
  });

  const {
    data: classSubjects,
    isLoading: isLoadingSubjects,
    error: subjectError,
  } = useQuery({
    queryKey: [
      "classSubjects",
      selectedClass?.value,
      selectedAcademicYear?.value,
    ],
    queryFn: () =>
      getClassSubjects(selectedClass?.value, selectedAcademicYear?.value),
    enabled: !!(selectedClass && selectedAcademicYear),
  });

  const { data: classes, isLoading: isLoadingClasses } = useQuery({
    queryKey: ["classes"],
    queryFn: getClasses,
  });

  const { data: students, isLoading: isLoadingStudents } = useQuery({
    queryKey: ["students", studentSearch],
    queryFn: () => searchStudents(studentSearch),
    enabled: studentSearch.length >= 2,
  });

  const { data: sections, isLoading: isLoadingSections } = useQuery({
    queryKey: ["sections", selectedClass?.value],
    queryFn: () => getSections(selectedClass?.value),
    enabled: !!selectedClass,
  });

  const classOptions = classes?.data?.length
    ? classes?.data?.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }))
    : [];

  const academicYearOptions = academicYears?.data?.length
    ? academicYears?.data?.map((year) => ({
        value: year.id,
        label: year.name,
      }))
    : [];

  const subjectOptions = classSubjects?.length
    ? classSubjects?.map((subject) => ({
        value: subject.subject_id,
        label: subject.subject_name || subject.name,
      }))
    : [];

  const sectionOptions = sections?.length
    ? sections?.map((section) => ({
        value: section.id,
        label: section.name,
      }))
    : [];

  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      setStudentSearch(inputValue);
    }, 300),
    []
  );

  useEffect(() => {
    if (selectedEnrollment) {
      reset({
        student_id: {
          value: selectedEnrollment.student_id,
          label: selectedEnrollment.student_name,
        },
        class_id: {
          value: selectedEnrollment.class_id,
          label: selectedEnrollment.class_name,
        },
        academic_year_id: {
          value: selectedEnrollment.academic_year_id,
          label: selectedEnrollment.academic_year_name,
        },
        section_id: {
          value: selectedEnrollment.section_id,
          label: selectedEnrollment.section_name,
        },
        subjects: selectedEnrollment.subjects.map((subject) => ({
          value: subject.subject_id,
          label: subject.subject_name,
        })),
      });
      setSelectedClass({
        value: selectedEnrollment.class_id,
        label: selectedEnrollment.class_name,
      });
      setSelectedAcademicYear({
        value: selectedEnrollment.academic_year_id,
        label: selectedEnrollment.academic_year_name,
      });
    }
  }, [selectedEnrollment, reset]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const formData = {
        student_id: data.student_id.value,
        class_id: data.class_id.value,
        academic_year_id: data.academic_year_id.value,
        section_id: data.section_id?.value || null,
        subjects: data.subjects.map((subject) => {
          if (id) {
            // For edit mode, find the existing subject details
            const existingSubject = selectedEnrollment.subjects.find(
              (s) => s.subject_id === subject.value
            );
            if (existingSubject) {
              return {
                id: existingSubject.id,
                version: existingSubject.version,
                enrollment_id: existingSubject.enrollment_id,
                subject_id: existingSubject.subject_id,
                subject_name: existingSubject.subject_name,
              };
            }
          }
          // For new subjects or create mode
          return {
            subject_id: subject.value,
            subject_name: subject.label,
          };
        }),
      };

      if (id) {
        formData.id = id;
        formData.version = selectedEnrollment?.version;
        editMutation.mutate(
          {
            id,
            data: formData,
          },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/enrollments/all");
            },
            onError: () => {
              setIsSubmitting(false);
            },
          }
        );
      } else {
        createMutation.mutate(formData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/enrollments/all");
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitting(false);
    }
  };

  if (isLoadingEnrollment) {
    return <LoadingDots />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow relative"
    >
      <h2 className="text-2xl font-bold mb-6">
        {id ? "Edit Enrollment" : "Create New Enrollment"}
      </h2>

      <div className="space-y-6">
        {/* Enrollment Details Section */}
        <div className="border rounded-lg overflow-hidden">
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
            onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}
          >
            <span className="text-lg font-semibold">Enrollment Details</span>
            {isDetailsExpanded ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
            )}
          </button>

          {isDetailsExpanded && (
            <div className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Student <span className="text-red-500">*</span>
                </label>
                <Controller
                  name="student_id"
                  control={control}
                  rules={{ required: "Student is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={
                        students?.data?.map((student) => ({
                          value: student.id,
                          label: `${student.first_name} ${student.last_name}`,
                        })) || []
                      }
                      placeholder="Search Student..."
                      className={errors.student_id ? requiredFieldClass : ""}
                      isLoading={isLoadingStudents}
                      onInputChange={(value) => {
                        if (value.length >= 2) {
                          debouncedSearch(value);
                        }
                      }}
                      filterOption={() => true}
                      noOptionsMessage={({ inputValue }) =>
                        inputValue.length < 2
                          ? "Type at least 2 characters to search"
                          : "No students found"
                      }
                    />
                  )}
                />
                {errors.student_id && (
                  <p className={errorMessageClass}>
                    {errors.student_id.message}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Class
                  </label>
                  <Controller
                    name="class_id"
                    control={control}
                    rules={{ required: "Class is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={classOptions}
                        isLoading={isLoadingClasses}
                        className={errors.class_id ? requiredFieldClass : ""}
                        onChange={(selected) => {
                          field.onChange(selected);
                          setSelectedClass(selected);
                          setValue("section_id", null);
                        }}
                      />
                    )}
                  />
                  {errors.class_id && (
                    <p className={errorMessageClass}>
                      {errors.class_id.message}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Section
                  </label>
                  <Controller
                    name="section_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={sectionOptions}
                        isLoading={isLoadingSections}
                        isDisabled={!selectedClass}
                        isClearable
                        className={errors.section_id ? requiredFieldClass : ""}
                      />
                    )}
                  />
                  {errors.section_id && (
                    <p className={errorMessageClass}>
                      {errors.section_id.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Academic Year <span className="text-red-500">*</span>
                </label>
                <Controller
                  name="academic_year_id"
                  control={control}
                  rules={{ required: "Academic Year is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={errors.academic_year_id ? "select-error" : ""}
                      classNames={{
                        control: (state) =>
                          `${
                            errors.academic_year_id
                              ? requiredFieldClass
                              : state.isFocused
                              ? "border-indigo-500 ring-1 ring-indigo-500"
                              : ""
                          } min-h-[42px]`,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: 42,
                        }),
                        menu: (base) => ({
                          ...base,
                          boxShadow:
                            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                          backgroundColor: "white",
                          zIndex: 9999,
                        }),
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="Select Academic Year"
                      isClearable
                      options={academicYearOptions}
                      onChange={(val) => {
                        field.onChange(val);
                        setSelectedAcademicYear(val);
                        setValue("subjects", []);
                      }}
                      isLoading={isLoadingAcademicYears}
                    />
                  )}
                />
                {errors.academic_year_id && (
                  <p className={errorMessageClass}>
                    {errors.academic_year_id.message}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Subjects Section */}
        <div className="border rounded-lg overflow-hidden">
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
            onClick={() => setIsSubjectsExpanded(!isSubjectsExpanded)}
          >
            <span className="text-lg font-semibold">Subjects</span>
            {isSubjectsExpanded ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
            )}
          </button>

          {isSubjectsExpanded && (
            <div className="p-4">
              {isLoadingSubjects ? (
                <LoadingDots />
              ) : subjectError ? (
                <p className={errorMessageClass}>Failed to load subjects.</p>
              ) : (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Select Subjects <span className="text-red-500">*</span>
                  </label>
                  <Controller
                    name="subjects"
                    control={control}
                    rules={{ required: "At least one subject is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        className={errors.subjects ? "select-error" : ""}
                        classNames={{
                          control: (state) =>
                            `${
                              errors.subjects
                                ? requiredFieldClass
                                : state.isFocused
                                ? "border-indigo-500 ring-1 ring-indigo-500"
                                : ""
                            } min-h-[42px]`,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: 42,
                          }),
                          menu: (base) => ({
                            ...base,
                            boxShadow:
                              "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                            backgroundColor: "white",
                            zIndex: 9999,
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder={
                          selectedClass && selectedAcademicYear
                            ? "Select Subjects"
                            : "First select a class and academic year..."
                        }
                        options={subjectOptions}
                        isDisabled={!selectedClass || !selectedAcademicYear}
                      />
                    )}
                  />
                  {errors.subjects && (
                    <p className={errorMessageClass}>
                      {errors.subjects.message}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          onClick={() => navigate("/enrollments/all")}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
        >
          {isSubmitting ? (
            <LoadingDots text={id ? "Updating" : "Creating"} />
          ) : id ? (
            "Update"
          ) : (
            "Create"
          )}
        </button>
      </div>
    </form>
  );
};

export default EnrollmentForm;
