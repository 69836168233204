import { useMemo } from 'react';
import { useFeeTypeQuery } from './useFeeTypeQuery';
import { useNavigate } from "react-router-dom";
import confirmDelete from "../../lib/confirmDelete";
import { TableActions } from "../../components/common/TableActions";

export const useTableColumns = () => {
  const navigate = useNavigate();
  const { deleteMutation } = useFeeTypeQuery();

  const handleEdit = (id) => {
    navigate(`/feetypes/edit/${id}`);
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error),
        });
      });
    });
  };

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
      },
      {
        header: 'Description',
        accessorKey: 'description',
      },
      {
        id: 'action',
        header: 'Action',
        enableSorting: false,
        cell: ({ row }) => (
          <TableActions
            onEdit={() => handleEdit(row.original.id)}
            onDelete={() => handleDelete(row.original.id)}
          />
        ),
      },
    ],
    [deleteMutation]
  );

  return columns;
};
