import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createFee,
  deleteFee,
  getAllFees,
  updateFee,
  getFeesByYearAndParent,
} from "../../Api/feesServices";
import { useState } from "react";
import { toast } from "react-toastify";
import usePagination from "../usePagination";

export const useFeesQuery = (yearId = null, parentId = null) => {
  const queryClient = useQueryClient();
  const { limit, offset, setOffset, goToPage, nextPage, prevPage } = usePagination();

  const {
    data = {
      data: [],
      metadata: {
        count: 0,
        offset: 0,
        limit: 10,
        current_page: 1,
        total_pages: 1,
        previous: null,
        next: null,
        filters: [],
        sorts: [],
        search: null
      }
    },
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["fees", { offset, limit, yearId, parentId }],
    queryFn: () => {
      if (!yearId) {
        return getAllFees({ offset, limit });
      }
      return getFeesByYearAndParent({ yearId, parentId, offset, limit });
    },
    keepPreviousData: true,
    staleTime: 5000,
    enabled: !!yearId,
  });

  // Use metadata directly from the API response
  const { current_page, total_pages } = data.metadata;

  // Wrap pagination functions to use total_pages from metadata
  const handleNextPage = () => nextPage(total_pages);
  const handlePrevPage = () => prevPage();
  const handleGoToPage = (page) => goToPage(page, total_pages);

  const createMutation = useMutation({
    mutationFn: createFee,
    onSuccess: () => {
      queryClient.invalidateQueries(["fees"]);
    },
    onError: (error) => {
      if (error.response?.data?.detail) {
        const detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail.forEach((err) => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          toast.error(detail.msg || JSON.stringify(detail));
        }
        return;
      }
      toast.error(
        error.response?.data?.message ||
          "Failed to create fee. Please try again."
      );
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ id, data }) => updateFee(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["fees"]);
    },
    onError: (error) => {
      if (error.response?.data?.detail) {
        const detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail.forEach((err) => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          toast.error(detail.msg || JSON.stringify(detail));
        }
        return;
      }
      toast.error(
        error.response?.data?.message ||
          "Failed to update fee. Please try again."
      );
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteFee,
    onSuccess: () => {
      queryClient.invalidateQueries(["fees"]);
      toast.success("Fee deleted successfully!");
    },
    onError: (error) => {
      console.error("Error deleting fee:", error);
      toast.error(
        error.response?.data?.message ||
          "Failed to delete fee. Please try again."
      );
    },
  });

  return {
    fees: data.data,
    isLoading,
    isError,
    createMutation,
    updateMutation,
    deleteMutation,
    currentPage: current_page,
    totalPages: total_pages,
    nextPage: handleNextPage,
    prevPage: handlePrevPage,
    goToPage: handleGoToPage
  };
};

export default useFeesQuery;
