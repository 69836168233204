import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTeacher, updateTeacher, deleteTeacher } from "../Api/teacherServices";
import { toast } from "react-toastify";

export const useTeacherQuery = () => {
  const queryClient = useQueryClient();

  const invalidateTeacherQueries = () => {
    // Invalidate the list queries
    queryClient.invalidateQueries({
      queryKey: ["teachers"],
      refetchType: 'all'
    });
  };

  const createMutation = useMutation({
    mutationFn: createTeacher,
    onSuccess: () => {
      invalidateTeacherQueries();
      toast.success("Teacher created successfully");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Error creating teacher");
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, ...data }) => updateTeacher(id, data),
    onSuccess: () => {
      invalidateTeacherQueries();
      toast.success("Teacher updated successfully");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Error updating teacher");
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteTeacher,
    onSuccess: () => {
      invalidateTeacherQueries();
      toast.success("Teacher deleted successfully");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Error deleting teacher");
    },
  });

  return {
    createMutation,
    editMutation,
    deleteMutation,
  };
};
