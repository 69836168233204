import React from "react";
import { useParams } from "react-router-dom";
import EnrollmentForm from "../../components/Enrollment/EnrollmentForm";

const CreateEnrollmentPage = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);

  return (
    <div className="mt-8">
      <EnrollmentForm id={id} />
    </div>
  );
};

export default CreateEnrollmentPage;
