import React, { useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import debounce from "lodash/debounce";
import {
  getClasses,
  getAcademicYears,
  getSections,
} from "../../Api/enrollmentServices";
import { searchStudents } from "../../Api/studentsServices";
import { useEnrollmentQuery } from "../../hooks/enrollment/useEnrollmentQuery";

const QuickEnrollmentForm = ({ onSuccess }) => {
  const { createMutation } = useEnrollmentQuery();
  const [selectedClass, setSelectedClass] = useState(null);
  const [studentSearch, setStudentSearch] = useState("");
  
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      student_id: null,
      class_id: null,
      academic_year_id: null,
      section_id: null,
    },
  });

  const { data: academicYears, isLoading: isLoadingAcademicYears } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
  });

  const { data: classes, isLoading: isLoadingClasses } = useQuery({
    queryKey: ["classes"],
    queryFn: getClasses,
  });

  const { data: students, isLoading: isLoadingStudents } = useQuery({
    queryKey: ["students", studentSearch],
    queryFn: () => searchStudents(studentSearch),
    enabled: studentSearch.length >= 2,
  });

  const { data: sections, isLoading: isLoadingSections } = useQuery({
    queryKey: ["sections", selectedClass?.value],
    queryFn: () => getSections(selectedClass?.value),
    enabled: !!selectedClass,
  });

  const debouncedSearch = useCallback(
    debounce((inputValue) => {
      setStudentSearch(inputValue);
    }, 300),
    []
  );

  const classOptions = classes?.data?.length
    ? classes.data.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }))
    : [];

  const academicYearOptions = academicYears?.data?.length
    ? academicYears.data.map((year) => ({
        value: year.id,
        label: year.name,
      }))
    : [];

  const studentOptions = students?.data?.length
    ? students.data.map((student) => ({
        value: student.id,
        label: `${student.first_name} ${student.last_name}`,
      }))
    : [];

  const sectionOptions = sections?.length
    ? sections.map((section) => ({
        value: section.id,
        label: section.name,
      }))
    : [];

  const onSubmit = async (data) => {
    try {
      const formData = {
        student_id: data.student_id.value,
        class_id: data.class_id.value,
        academic_year_id: data.academic_year_id.value,
        section_id: data.section_id?.value || null,
        subjects: [], // Empty subjects array for quick enrollment
      };

      await createMutation.mutateAsync(formData, {
        onSuccess: () => {
          reset();
          // Clear the form
          setValue("student_id", null);
          setValue("class_id", null);
          setValue("academic_year_id", null);
          setValue("section_id", null);
          setSelectedClass(null);
          setStudentSearch("");
          // Call the parent's onSuccess callback
          if (onSuccess) onSuccess();
        },
      });
    } catch (error) {
      console.error("Error creating enrollment:", error);
    }
  };

  if (isLoadingAcademicYears || isLoadingClasses) {
    return null;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 mb-4 bg-white p-4 rounded-lg shadow"
    >
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <Controller
            name="student_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={studentOptions}
                placeholder="Search Student..."
                className="min-w-[200px]"
                isLoading={isLoadingStudents}
                onInputChange={(value) => {
                  if (value.length >= 2) {
                    debouncedSearch(value);
                  }
                }}
                filterOption={() => true}
                noOptionsMessage={({ inputValue }) => 
                  inputValue.length < 2 
                    ? "Type at least 2 characters to search" 
                    : "No students found"
                }
              />
            )}
          />
        </div>
        <div className="flex-1">
          <Controller
            name="class_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={classOptions}
                placeholder="Select Class"
                className="min-w-[200px]"
                onChange={(selected) => {
                  field.onChange(selected);
                  setSelectedClass(selected);
                  setValue("section_id", null);
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <Controller
            name="section_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={sectionOptions}
                placeholder="Select Section"
                className="min-w-[200px]"
                isDisabled={!selectedClass}
                isLoading={isLoadingSections}
              />
            )}
          />
        </div>
        <div className="flex-1">
          <Controller
            name="academic_year_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={academicYearOptions}
                placeholder="Select Academic Year"
                className="min-w-[200px]"
              />
            )}
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Add
        </button>
      </div>
    </form>
  );
};

export default QuickEnrollmentForm;
