import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createBusRoute, updateBusRoute, deleteBusRoute } from '../../Api/busRouteServices';
import { toast } from 'react-toastify';

export const useBusRouteQuery = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: (data) => createBusRoute(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['busroutes'] });
      toast.success('Bus route created successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error creating bus route');
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateBusRoute(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['busroutes'] });
      toast.success('Bus route updated successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error updating bus route');
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteBusRoute(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['busroutes'] });
      toast.success('Bus route deleted successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error deleting bus route');
    },
  });

  return {
    createMutation,
    editMutation,
    deleteMutation,
  };
};
