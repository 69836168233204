import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import debounce from "lodash/debounce";
import useFeesQuery from "../../hooks/fees/useFeesQuery";
import LoadingDots from "../common/LoadingDots";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { getAcademicYears } from "../../Api/enrollmentServices";
import { searchStudents } from "../../Api/studentsServices";
import { getFeeTypes } from "../../Api/feeTypeServices";
import { getTerms } from "../../Api/termServices";
import { getFeeById } from "../../Api/feesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NEPALI_MONTHS = [
  "Baisakh",
  "Jestha",
  "Ashadh",
  "Shrawan",
  "Bhadra",
  "Ashwin",
  "Kartik",
  "Mangsir",
  "Poush",
  "Magh",
  "Falgun",
  "Chaitra",
];

const monthOptions = NEPALI_MONTHS.map((month) => ({
  value: month.toLowerCase(),
  label: month,
}));

const statusOptions = [
  { value: "drafted", label: "Drafted" },
  { value: "unpaid", label: "Unpaid" },
  { value: "paid", label: "Paid" },
  { value: "pending", label: "Pending" },
  { value: "overdue", label: "Overdue" },
  { value: "processing", label: "Processing" },
  { value: "failed", label: "Failed" },
  { value: "refunded", label: "Refunded" },
  { value: "cancelled", label: "Cancelled" },
];

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";
const requiredLabel = " *";

const FeesForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, updateMutation } = useFeesQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(true);
  const [studentSearch, setStudentSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [currentPage] = useState(1);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: {
      academic_year_id: null,
      status: statusOptions[0],
      student_id: null,
      fee_type_id: null,
      term_id: null,
      discount_id: null,
      month: null,
      notes: "",
    },
  });

  // Query hooks
  const { data: academicYearsResponse } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
  });

  const { data: termsResponse } = useQuery({
    queryKey: ["terms"],
    queryFn: () => getTerms(),
  });

  const { data: feeTypesResponse } = useQuery({
    queryKey: ["feeTypes"],
    queryFn: () => getFeeTypes(),
  });

  const { data: studentsResponse } = useQuery({
    queryKey: ["students", debouncedSearch],
    queryFn: () => searchStudents(debouncedSearch),
    enabled: debouncedSearch.length >= 2,
  });

  // Get fee by id for edit mode
  const { data: feeData, isLoading: isLoadingFee } = useQuery({
    queryKey: ["fee", id],
    queryFn: () => getFeeById(id),
    enabled: !!id,
    retry: false,
  });

  // Extract data from responses
  const academicYears = academicYearsResponse?.data || [];
  const terms = termsResponse?.data || [];
  const feeTypes = feeTypesResponse?.data || [];
  const students = studentsResponse?.data || [];

  // Set form values when editing
  useEffect(() => {
    if (id && feeData && academicYears.length && feeTypes.length) {
      const selectedStatus = statusOptions.find(
        (option) => option.value === feeData.status
      );
      const selectedMonth = monthOptions.find(
        (option) => option.value === feeData.month?.toLowerCase()
      );

      const selectedAcademicYear = academicYears.find(
        (year) => year.id === feeData.academic_year_id
      );

      const selectedFeeType = feeTypes.find(
        (type) => type.id === feeData.fee_type_id
      );

      const selectedTerm = terms.find(
        (term) => term.id === feeData.term_id
      );

      const formData = {
        ...feeData,
        status: selectedStatus || statusOptions[0],
        month: selectedMonth,
        academic_year_id: selectedAcademicYear ? {
          value: selectedAcademicYear.id,
          label: selectedAcademicYear.name
        } : null,
        fee_type_id: selectedFeeType ? {
          value: selectedFeeType.id,
          label: `${selectedFeeType.name} - ${selectedFeeType.amount}`
        } : null,
        term_id: selectedTerm ? {
          value: selectedTerm.id,
          label: selectedTerm.name
        } : null
      };

      // Set student data if we have the student name
      if (feeData.student_name && feeData.student_id) {
        formData.student_id = {
          value: feeData.student_id,
          label: feeData.student_name
        };
        setStudentSearch(feeData.student_name);
        setDebouncedSearch(feeData.student_name);
      }

      reset(formData);
    }
  }, [id, feeData, academicYears, feeTypes, terms, reset]);

  // Only show loading in edit mode when fee data is being fetched
  if (id && (isLoadingFee || !feeData)) {
    return (
      <div className="flex justify-center items-center h-48">
        <LoadingDots />
      </div>
    );
  }

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const formData = {
        ...data,
        status: data.status?.value,
        student_id: data.student_id?.value,
        fee_type_id: data.fee_type_id?.value,
        term_id: data.term_id?.value || null,
        academic_year_id: data.academic_year_id?.value,
        month: data.month?.value || null,
        notes: data.notes || "",
      };

      if (id) {
        await updateMutation.mutateAsync({
          id,
          data: {
            ...formData,
            version: feeData.version,
          },
        });
        toast.success("Fee updated successfully!");
      } else {
        await createMutation.mutateAsync(formData);
        toast.success("Fee created successfully!");
      }
      navigate("/fees/all");
    } catch (error) {
      console.error("Error:", error);
      if (error.response?.data?.detail) {
        const detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail.forEach((err) => {
            const message = err.msg || JSON.stringify(err);
            toast.error(message);
          });
        } else {
          toast.error(detail.msg || JSON.stringify(detail));
        }
      } else {
        toast.error(error.response?.data?.message || "Failed to save fee. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const feeTypeOptions = feeTypes.map((feeType) => ({
    value: feeType.id,
    label: `${feeType.name} - ${feeType.amount}`,
  }));

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Fee Details
            </h2>
            <button
              type="button"
              onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}
              className="text-gray-500 hover:text-gray-700"
            >
              {isDetailsExpanded ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
          </div>

          {isDetailsExpanded && (
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <label
                  htmlFor="academic_year_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Academic Year{requiredLabel}
                </label>
                <div className="mt-2">
                  <Controller
                    name="academic_year_id"
                    control={control}
                    rules={{ required: "Academic year is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={academicYears.map((year) => ({
                          value: year.id,
                          label: year.name,
                        }))}
                        className={
                          errors.academic_year_id ? requiredFieldClass : ""
                        }
                        placeholder="Select Academic Year"
                      />
                    )}
                  />
                  {errors.academic_year_id && (
                    <p className={errorMessageClass}>
                      {errors.academic_year_id.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Status{requiredLabel}
                </label>
                <div className="mt-2">
                  <Controller
                    name="status"
                    control={control}
                    rules={{ required: "Status is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={statusOptions}
                        className={errors.status ? requiredFieldClass : ""}
                        placeholder="Select Status"
                      />
                    )}
                  />
                  {errors.status && (
                    <p className={errorMessageClass}>{errors.status.message}</p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="student_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Student{requiredLabel}
                </label>
                <div className="mt-2">
                  <Controller
                    name="student_id"
                    control={control}
                    rules={{ required: "Student is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={students.map((student) => ({
                          value: student.id,
                          label: `${student.first_name} ${student.last_name}`,
                        }))}
                        onInputChange={(value) => {
                          setStudentSearch(value);
                          if (value.length >= 2) {
                            debounce(() => setDebouncedSearch(value), 300)();
                          }
                        }}
                        className={errors.student_id ? requiredFieldClass : ""}
                        placeholder="Search Student"
                        noOptionsMessage={({ inputValue }) =>
                          inputValue.length < 2
                            ? "Type 2 or more characters to search"
                            : "No students found"
                        }
                      />
                    )}
                  />
                  {errors.student_id && (
                    <p className={errorMessageClass}>
                      {errors.student_id.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="fee_type_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Fee Type{requiredLabel}
                </label>
                <div className="mt-2">
                  <Controller
                    name="fee_type_id"
                    control={control}
                    rules={{ required: "Fee type is required" }}
                    render={({ field }) => (
                      <div>
                        <Select
                          {...field}
                          options={feeTypeOptions}
                          className={`mt-1 ${
                            errors.fee_type_id ? requiredFieldClass : ""
                          }`}
                          placeholder="Select fee type"
                          isClearable
                        />
                        {errors.fee_type_id && (
                          <p className={errorMessageClass}>
                            {errors.fee_type_id.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="term_id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Term
                </label>
                <div className="mt-2">
                  <Controller
                    name="term_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={terms.map((term) => ({
                          value: term.id,
                          label: term.name,
                        }))}
                        className={errors.term_id ? requiredFieldClass : ""}
                        placeholder="Select Term"
                        isClearable
                      />
                    )}
                  />
                  {errors.term_id && (
                    <p className={errorMessageClass}>
                      {errors.term_id.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="month"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Month
                </label>
                <div className="mt-2">
                  <Controller
                    name="month"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={monthOptions}
                        className={errors.month ? requiredFieldClass : ""}
                        placeholder="Select month..."
                        isClearable
                      />
                    )}
                  />
                  {errors.month && (
                    <p className={errorMessageClass}>{errors.month.message}</p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-2">
                <div className="space-y-2">
                  <label htmlFor="notes" className="form_label">
                    Notes
                  </label>
                  <textarea
                    id="notes"
                    {...register("notes", { required: false })}
                    className="form_input h-24"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate("/fees/all")}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {isSubmitting ? <LoadingDots /> : id ? "Update Fee" : "Create Fee"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeesForm;
