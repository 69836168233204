import React, { useState, useCallback } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllParents, searchParents } from "../../Api/parentServices";
import { useTableColumns } from "../../hooks/parent/useTableColumns";
import { useParentQuery } from "../../hooks/parent/useParentQuery";
import Table from "../../components/common/Table";
import { Controller, useForm } from "react-hook-form";
import { createStudentParent } from "../../Api/studentParentServices";
import { toast } from "react-toastify";
import Select from "react-select";
import debounce from "lodash/debounce";
import { searchStudents } from "../../Api/studentsServices";
import LoadingDots from "../../components/common/LoadingDots";

const ListParents = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [studentSearch, setStudentSearch] = useState("");
  const [parentSearch, setParentSearch] = useState("");
  const { deleteMutation } = useParentQuery();
  const columns = useTableColumns(deleteMutation);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      student_id: null,
      parent_id: null,
    },
  });
  const queryClient = useQueryClient();

  const { data: parentsData, isLoading } = useQuery({
    queryKey: ["parents", currentPage],
    queryFn: () => getAllParents({ offset: (currentPage - 1) * 10, limit: 10 }),
    refetchOnMount: true,
  });

  const { data: students, isLoading: isLoadingStudents } = useQuery({
    queryKey: ["students", studentSearch],
    queryFn: () => searchStudents(studentSearch),
    enabled: studentSearch.length >= 2,
  });

  const { data: searchedParents, isLoading: isLoadingParents } = useQuery({
    queryKey: ["searchParents", parentSearch],
    queryFn: () => searchParents(parentSearch),
    enabled: parentSearch.length >= 2,
  });

  const createStudentParentMutation = useMutation({
    mutationFn: createStudentParent,
    onSuccess: () => {
      toast.success("Student-Parent association created successfully");
      reset();
      queryClient.invalidateQueries(["studentParents"]);
    },
    onError: (error) => {
      toast.error("Failed to create student-parent association");
      console.error("Error:", error);
    },
  });

  const pagination = {
    currentPage: parentsData?.metadata?.currentPage || 1,
    totalPages: parentsData?.metadata?.total_pages || 1,
    nextPage: () =>
      setCurrentPage((old) =>
        Math.min(old + 1, parentsData?.metadata?.total_pages || 1)
      ),
    prevPage: () => setCurrentPage((old) => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  const handleLink = () => "/parents/create";

  const debouncedStudentSearch = useCallback(
    debounce((inputValue) => {
      setStudentSearch(inputValue);
    }, 500),
    []
  );

  const debouncedParentSearch = useCallback(
    debounce((inputValue) => {
      setParentSearch(inputValue);
    }, 500),
    []
  );

  const onSubmit = (values) => {
    createStudentParentMutation.mutate({
      student_id: values.student_id.value,
      parent_id: values.parent_id.value,
    });
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white rounded-lg shadow-sm p-6 mb-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex gap-4 items-end"
        >
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Student
            </label>
            <Controller
              name="student_id"
              control={control}
              rules={{ required: "Student is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={
                    students?.data?.map((student) => ({
                      value: student.id,
                      label: student.first_name + " " + student.last_name,
                    })) || []
                  }
                  onInputChange={debouncedStudentSearch}
                  isLoading={isLoadingStudents}
                  placeholder="Search student..."
                  className="w-full"
                  isClearable
                />
              )}
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Parent
            </label>
            <Controller
              name="parent_id"
              control={control}
              rules={{ required: "Parent is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={
                    searchedParents?.data?.map((parent) => ({
                      value: parent.id,
                      label: parent.first_name + " " + parent.last_name,
                    })) || []
                  }
                  onInputChange={debouncedParentSearch}
                  isLoading={isLoadingParents}
                  placeholder="Search parent..."
                  className="w-full"
                  isClearable
                />
              )}
            />
          </div>
          <button
            type="submit"
            disabled={createStudentParentMutation.isLoading}
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {createStudentParentMutation.isLoading
              ? "Associating..."
              : "Associate Student with Parent"}
          </button>
        </form>
      </div>

      <Table
        data={parentsData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Parent"
        baseEditPath="/parents/edit"
      />
    </div>
  );
};

export default ListParents;
