import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      refetchOnMount: false,       // Disable refetching on component mount
      refetchOnReconnect: false,   // Disable refetching on reconnect
      staleTime: 300000,          // Consider data fresh for 5 minutes
      cacheTime: 3600000,         // Keep unused data in cache for 1 hour
    },
  },
});
