import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getUserFees } from '../../Api/feesServices';
import { toast } from 'react-toastify';

const Payments = () => {
  const { data: feesData, isLoading } = useQuery({
    queryKey: ['userFees'],
    queryFn: async () => {
      try {
        return await getUserFees();
      } catch (error) {
        if (error.response?.data?.detail) {
          const detail = error.response.data.detail;
          if (Array.isArray(detail)) {
            detail.forEach((err) => {
              if (err.msg) {
                toast.error(err.msg);
              }
            });
          }
        }
        throw error;
      }
    }
  });

  const groupedFees = useMemo(() => {
    if (!feesData?.data) return {};
    
    return feesData.data.reduce((acc, fee) => {
      // First level grouping by student
      if (!acc[fee.student_id]) {
        acc[fee.student_id] = {
          student_name: fee.student_name,
          fees: {
            unpaid: [],
            paid: []
          },
          totals: {
            unpaid: 0,
            paid: 0
          }
        };
      }

      // Convert amount string (e.g., "NPR 801.55") to number
      const amountNumber = parseFloat(fee.amount.split(' ')[1]);

      // Group by payment status (anything not 'paid' goes to unpaid)
      const status = fee.status === 'paid' ? 'paid' : 'unpaid';
      acc[fee.student_id].fees[status].push(fee);
      acc[fee.student_id].totals[status] += amountNumber;

      return acc;
    }, {});
  }, [feesData]);

  if (isLoading) {
    return (
      <div className="container mx-auto p-4">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
          {[1, 2].map((i) => (
            <div key={i} className="mb-8">
              <div className="h-6 bg-gray-200 rounded w-1/3 mb-4"></div>
              <div className="space-y-3">
                {[1, 2, 3].map((j) => (
                  <div key={j} className="h-4 bg-gray-200 rounded w-full"></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Fee Payments</h1>
      
      <div className="grid md:grid-cols-2 gap-6">
        {Object.entries(groupedFees).map(([studentId, data]) => (
          <div key={studentId} className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-xl font-semibold mb-4">{data.student_name}</h2>
            
            {/* Unpaid Fees Section */}
            {data.fees.unpaid.length > 0 && (
              <div className="mb-6">
                <h3 className="text-red-600 font-medium mb-3">Pending Payments</h3>
                <div className="space-y-3">
                  {data.fees.unpaid.map((fee) => (
                    <div key={fee.id} className="flex justify-between items-center p-3 bg-red-50 rounded">
                      <div>
                        <p className="font-medium">{fee.fee_type_name}</p>
                        <p className="text-sm text-gray-600">
                          {fee.month} - {fee.academic_year_name}
                        </p>
                        {fee.notes && (
                          <p className="text-sm text-gray-500 mt-1">Note: {fee.notes}</p>
                        )}
                      </div>
                      <div className="text-right">
                        <p className="font-medium">{fee.amount}</p>
                        <p className="text-sm text-gray-600 capitalize">{fee.status}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-3 p-3 bg-red-100 rounded flex justify-between font-medium">
                  <span>Total Pending</span>
                  <span>NPR {data.totals.unpaid.toFixed(2)}</span>
                </div>
              </div>
            )}

            {/* Paid Fees Section */}
            {data.fees.paid.length > 0 && (
              <div>
                <h3 className="text-green-600 font-medium mb-3">Paid Fees</h3>
                <div className="space-y-3">
                  {data.fees.paid.map((fee) => (
                    <div key={fee.id} className="flex justify-between items-center p-3 bg-green-50 rounded">
                      <div>
                        <p className="font-medium">{fee.fee_type_name}</p>
                        <p className="text-sm text-gray-600">
                          {fee.month} - {fee.academic_year_name}
                        </p>
                        {fee.notes && (
                          <p className="text-sm text-gray-500 mt-1">Note: {fee.notes}</p>
                        )}
                      </div>
                      <div className="text-right">
                        <p className="font-medium">{fee.amount}</p>
                        <p className="text-sm text-gray-600 capitalize">{fee.status}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-3 p-3 bg-green-100 rounded flex justify-between font-medium">
                  <span>Total Paid</span>
                  <span>NPR {data.totals.paid.toFixed(2)}</span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Payments;
