import { useState } from "react";

// Hook to manage pagination state and logic
const usePagination = (defaultLimit = 10) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(0);

  // Calculate current page based on offset and limit
  const currentPage = Math.floor(Number(offset) / Number(limit)) + 1;

  const goToPage = (page, totalPages) => {
    if (page >= 1 && (!totalPages || page <= totalPages)) {
      const newOffset = (page - 1) * Number(limit);
      setOffset(Math.max(0, newOffset));
    }
  };

  const nextPage = (totalPages) => {
    if (!totalPages || currentPage < totalPages) {
      setOffset((prevOffset) => Math.max(0, Number(prevOffset) + Number(limit)));
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setOffset((prevOffset) => Math.max(0, Number(prevOffset) - Number(limit)));
    }
  };

  return {
    limit: Number(limit),
    offset: Math.max(0, Number(offset)),
    currentPage,
    goToPage,
    nextPage,
    prevPage,
    setLimit: (newLimit) => setLimit(Number(newLimit)),
  };
};

export default usePagination;
