import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useEnrollmentQuery } from "./useEnrollmentQuery";
import confirmDelete from "../../lib/confirmDelete";
import { TableActions } from "../../components/common/TableActions";

export const useTableColumns = () => {
  const navigate = useNavigate();
  const { deleteMutation } = useEnrollmentQuery();

  const handleView = (id) => {
    navigate(`/enrollments/edit/${id}`);
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteMutation.mutate(id, {
          onSuccess: () => resolve(),
          onError: (error) => reject(error),
        });
      });
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Student",
        accessorKey: "student_name",
      },
      {
        header: "Class",
        accessorKey: "class_name",
      },
      {
        header: "Academic Year",
        accessorKey: "academic_year_name",
      },
      {
        id: "action",
        header: "Action",
        enableSorting: false,
        cell: ({ row }) => (
          <TableActions
            onEdit={() => handleView(row.original.id)}
            onDelete={() => handleDelete(row.original.id)}
            editIcon="eye"
          />
        ),
      },
    ],
    [deleteMutation]
  );

  return columns;
};
