import React from "react";
import { useParams } from "react-router-dom";
import ClassForm from "../../components/Class/ClassForm";

const EditClass = () => {
  const { id } = useParams();
  return <ClassForm id={id} />;
};

export default EditClass;
