import { useQuery } from "@tanstack/react-query";
import { getAvatar } from "../../Api/attachmentServices";

export const useUserAvatar = (userId) => {
  return useQuery({
    queryKey: ["userAvatar", userId],
    queryFn: () => getAvatar(userId),
    enabled: !!userId,
  });
};
