import React from "react";
import Table from "../../components/common/Table";
import LoadingDots from "../../components/common/LoadingDots";
import { useStudentsTable } from "../../hooks/student";

const ListStudents = () => {
  const {
    data: { data },
    columns,
    error,
    isLoading,
    nextPage,
    prevPage,
    currentPage,
    totalPages,
    pagination,
    setSearchQuery,
  } = useStudentsTable();

  const handleLink = () => "/students/create";

  if (isLoading) {
    return <LoadingDots />;
  }

  if (error) return <div>Error loading data: {error.message}</div>;

  return (
    <div className="container mx-auto p-6">
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Student"
        baseEditPath="/students/edit"
        setSearchQuery={setSearchQuery}
      />
    </div>
  );
};

export default ListStudents;
