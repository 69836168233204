import React, { useState } from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { getAcademicYears, getClasses } from "../../Api/enrollmentServices";
import MarksForm from "./MarksForm";
import LoadingDots from "../../components/common/LoadingDots";

const ListMarks = () => {
  const [academicYear, setAcademicYear] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const { data: academicYearsResponse, isLoading: loadingYears } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
  });

  const { data: classesResponse, isLoading: loadingClasses } = useQuery({
    queryKey: ["classes"],
    queryFn: getClasses,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (academicYear && selectedClass) {
      setShowForm(true);
    }
  };

  const isLoading = loadingYears || loadingClasses;

  if (isLoading) {
    return <LoadingDots />;
  }

  const academicYearOptions =
    academicYearsResponse?.data?.map((year) => ({
      value: year.id,
      label: year.name,
    })) || [];

  const classOptions =
    classesResponse?.data?.map((cls) => ({
      value: cls.id,
      label: cls.name,
    })) || [];

  return (
    <div className="p-4 sm:p-7">
      <div className="mb-6">
        <h1 className="block text-2xl font-bold text-gray-800">
          Marks Management
        </h1>
      </div>

      <form onSubmit={handleSubmit} className="mb-6 space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Academic Year
            </label>
            <Select
              value={academicYear}
              onChange={setAcademicYear}
              options={academicYearOptions}
              placeholder="Select Academic Year"
              className="basic-single"
              classNamePrefix="select"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Class
            </label>
            <Select
              value={selectedClass}
              onChange={setSelectedClass}
              options={classOptions}
              placeholder="Select Class"
              className="basic-single"
              classNamePrefix="select"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={!academicYear || !selectedClass}
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
              ${
                !academicYear || !selectedClass
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700"
              }`}
          >
            Load Students
          </button>
        </div>
      </form>

      {showForm && (
        <MarksForm
          academicYearId={academicYear?.value}
          classId={selectedClass?.value}
        />
      )}
    </div>
  );
};

export default ListMarks;
