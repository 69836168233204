import { TableActions } from "../../components/common/TableActions";

const useTableColumns = ({ handleEdit, handleDelete }) => {
  const getStatusColor = (status) => {
    const statusColors = {
      drafted: "bg-gray-100 text-gray-800",
      unpaid: "bg-yellow-100 text-yellow-800",
      paid: "bg-green-100 text-green-800",
      pending: "bg-blue-100 text-blue-800",
      overdue: "bg-red-100 text-red-800",
      processing: "bg-blue-100 text-blue-800",
      failed: "bg-red-100 text-red-800",
      refunded: "bg-gray-100 text-gray-800",
      cancelled: "bg-gray-100 text-gray-800",
    };
    return statusColors[status] || "bg-gray-100 text-gray-800";
  };

  const columns = [
    {
      header: "Student",
      accessorKey: "student_name",
      sortable: true,
    },
    {
      header: "Fee Type",
      accessorKey: "fee_type_name",
    },
    {
      header: "Amount",
      accessorKey: "amount",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(
            row.original.status
          )}`}
        >
          {row.original.status.toUpperCase()}
        </span>
      ),
    },
    {
      header: "Academic Year",
      accessorKey: "academic_year_name",
    },
    {
      header: "Month",
      accessorKey: "month",
    },
    {
      header: "Notes",
      accessorKey: "notes",
      cell: ({ row }) => (
        <div
          style={{
            maxWidth: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.notes}
        </div>
      ),
    },
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: ({ row }) => (
        <TableActions
          onEdit={() => handleEdit(row.original.id)}
          onDelete={() => handleDelete(row.original.id)}
        />
      ),
    },
  ];

  return columns;
};

export default useTableColumns;
