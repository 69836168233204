import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTerms } from "../../Api/termServices";
import useTableColumns from "../../hooks/term/useTableColumns";
import Table from "../../components/common/Table";
import LoadingDots from "../../components/common/LoadingDots";

const ListTerm = () => {
  const columns = useTableColumns();
  const [currentPage, setCurrentPage] = useState(1);

  const { data: termsData, isLoading } = useQuery({
    queryKey: ["terms", currentPage],
    queryFn: () => getTerms(currentPage),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const pagination = {
    currentPage: termsData?.currentPage || 1,
    totalPages: termsData?.totalPages || 1,
    nextPage: () => setCurrentPage(old => Math.min(old + 1, termsData?.totalPages || 1)),
    prevPage: () => setCurrentPage(old => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  const handleLink = () => "/terms/create";

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className="container mx-auto p-4">
      <Table
        data={termsData?.data || []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Term"
        baseEditPath="/terms/edit"
      />
    </div>
  );
};

export default ListTerm;
