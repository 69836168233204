import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getParentById } from "../../Api/parentServices";
import { useParentQuery } from "../../hooks/parent/useParentQuery";
import LoadingDots from "../common/LoadingDots";
import { toast } from "react-toastify";
import ClearableDatePicker from "../common/ClearableDatePicker";
import "nepali-datepicker-reactjs/dist/index.css";

const ParentForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dobDate, setDobDate] = useState("");

  const handleSuccess = () => {
    setIsSubmitting(false);
    if (id) {
      toast.success("Parent updated successfully!");
    } else {
      toast.success("Parent created successfully!");
    }
    navigate("/parents/all");
  };

  const handleError = (error) => {
    setIsSubmitting(false);
    if (error.response?.status === 422 && error.response?.data?.detail) {
      const detail = error.response.data.detail;
      // Handle array of error details
      if (Array.isArray(detail)) {
        detail.forEach(err => {
          const message = err.msg || JSON.stringify(err);
          toast.error(message);
        });
      } else {
        // Handle single error detail
        toast.error(error.response.data.detail);
      }
    } else {
      toast.error(error.response?.data?.message || error.response?.data?.detail || "Operation failed. Please try again.");
    }
  };

  const { editMutation, createMutation } = useParentQuery();

  const { data: selectedParent } = useQuery({
    queryKey: ["parents", id],
    queryFn: () => getParentById(id),
    enabled: !!id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      dob: null,
      gender: "",
      phone: null,
      email: null,
      notes: null,
      address: null,
    },
  });

  useEffect(() => {
    if (selectedParent) {
      reset(selectedParent);
      setDobDate(selectedParent.dob || "");
    }
  }, [selectedParent, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formattedData = {
      first_name: data.first_name,
      last_name: data.last_name,
      dob: data.dob || null,
      gender: data.gender || null,
      phone: data.phone || null,
      email: data.email || null,
      notes: data.notes || null,
      address: data.address || null,
      version: id ? selectedParent?.version : null,
    };

    Object.keys(formattedData).forEach((key) => {
      if (formattedData[key] === "") {
        formattedData[key] = null;
      }
    });

    if (id) {
      editMutation.mutate(
        {
          id,
          updatedData: formattedData,
        },
        {
          onSuccess: handleSuccess,
          onError: handleError,
        }
      );
    } else {
      createMutation.mutate(formattedData, {
        onSuccess: handleSuccess,
        onError: handleError,
      });
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold mb-6">
          {id ? "Edit Parent" : "Add Parent"}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            {/* Personal Information */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                {...register("first_name", {
                  required: "First name is required",
                })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.first_name && (
                <span className="text-red-500 text-sm">
                  {errors.first_name.message}
                </span>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                {...register("last_name", {
                  required: "Last name is required",
                })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.last_name && (
                <span className="text-red-500 text-sm">
                  {errors.last_name.message}
                </span>
              )}
            </div>

            <div>
              <ClearableDatePicker
                label="Date of Birth"
                value={dobDate}
                onChange={(date) => {
                  setDobDate(date || "");
                  setValue("dob", date);
                }}
                error={errors.dob?.message}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Gender
              </label>
              <select
                {...register("gender", { required: "Gender is required" })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errors.gender && (
                <span className="text-red-500 text-sm">
                  {errors.gender.message}
                </span>
              )}
            </div>

            {/* <div>
              <label className="block text-sm font-medium text-gray-700">
                Relationship
              </label>
              <select
                {...register("relationship", { required: "Relationship is required" })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select Relationship</option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>
                <option value="guardian">Guardian</option>
              </select>
              {errors.relationship && (
                <span className="text-red-500 text-sm">
                  {errors.relationship.message}
                </span>
              )}
            </div> */}

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                {...register("email", {
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {errors.email && (
                <span className="text-red-500 text-sm">
                  {errors.email.message}
                </span>
              )}
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone:
              </label>
              <PhoneInput
                country={"np"}
                value={watch("phone")}
                onChange={(phone) => setValue("phone", phone || null)}
                inputProps={{
                  required: false,
                }}
                className="mt-1"
              />
            </div>
          </div>

          {/* Notes */}
          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              {...register("notes", { required: false })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              rows="3"
            />
          </div>

          <div className="flex justify-end mt-6 gap-x-2">
            <button
              type="button"
              onClick={() => navigate("/parents/all")}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? (
                <LoadingDots text={id ? "Updating" : "Creating"} />
              ) : id ? (
                "Update"
              ) : (
                "Create"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ParentForm;
