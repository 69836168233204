import React, { useState } from "react";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ClearableDatePicker = ({
  label,
  value,
  onChange,
  required = false,
  error,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`mb-4 ${className}`}>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="flex gap-2">
        <div className="flex-1 relative">
          <input
            type="text"
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white pr-8 cursor-pointer"
            value={value || ""}
            readOnly
            placeholder="Select date"
            onClick={() => setIsOpen(true)}
          />
          {value && (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
                setIsOpen(false);
              }}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 z-10"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
          <div 
            className="absolute inset-0"
            onClick={() => setIsOpen(true)}
          >
            <NepaliDatePicker
              inputClassName="opacity-0 w-full h-full cursor-pointer"
              className="w-full"
              onChange={(bsDate) => {
                onChange(bsDate || null);
                setIsOpen(false);
              }}
              value={value}
              options={{ 
                calenderLocale: "en", 
                valueLocale: "en",
                isOpen: isOpen,
              }}
            />
          </div>
        </div>
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default ClearableDatePicker;
