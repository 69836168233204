import {
  Book,
  FileCheck,
  GraduationCap,
  HomeIcon,
  Notebook,
  Settings,
  Speech,
  User,
  Users,
  Wallet,
  WalletCards,
  UserCog,
  Bus,
  Calendar,
  ClipboardList,
  CheckSquare,
  MessageSquare,
  ListChecks,
  UserPen,
  Banknote,
  History,
} from "lucide-react";
import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [processSteps, setProcessSteps] = useState([]);

  useEffect(() => {
    try {
      // Get user data from localStorage or your auth state management
      const userStr = localStorage.getItem("user");
      const processStepsStr = localStorage.getItem("process_steps");

      const userData = userStr ? JSON.parse(userStr) : null;
      const processStepsData = processStepsStr
        ? JSON.parse(processStepsStr)
        : [];

      setUser(userData);
      setProcessSteps(processStepsData);
    } catch (error) {
      console.error("Error loading user data:", error);
      setUser(null);
      setProcessSteps([]);
    }
  }, []);

  const handleSettingsClick = () => {
    navigate("/profile");
  };

  // Function to check if a menu item should be visible
  const isMenuItemVisible = (menuCode) => {
    // Admin can see everything
    if (user?.roles?.includes("admin")) {
      return true;
    }

    // Check if the menu's code exists in process steps
    return processSteps.some((step) => step.code === menuCode);
  };

  return (
    <>
      {/* <!-- Sidebar --> */}
      <div
        id="hs-application-sidebar"
        className="hs-overlay  [--auto-close:lg]
      hs-overlay-open:translate-x-0
      -translate-x-full transition-all duration-300 transform
      w-[260px] h-full
      hidden
      fixed inset-y-0 start-0 z-[60]
      bg-white border-e border-gray-200
      lg:block lg:translate-x-0 lg:end-auto lg:bottom-0
     "
        role="dialog"
        tabindex="-1"
        aria-label="Sidebar"
      >
        <div className="relative flex flex-col h-full max-h-full">
          <div className="px-6 pt-4">
            {/* <!-- Logo --> */}
            <Link
              className="mt-auto p-6 flex flex-col items-center"
              to="/calendar"
              aria-label="Vidyapith"
            >
              <img
                src={window.REACT_APP_CONFIG.SCHOOL_LOGO}
                alt="School Logo"
                className="h-12"
              />
            </Link>
            {/* <!-- End Logo --> */}
          </div>

          {/* <!-- Content --> */}
          <div className="flex flex-col h-full overflow-y-auto custom-scrollbar">
            {/* <!-- Main Navigation --> */}
            <nav
              className="hs-accordion-group p-3 w-full flex flex-col flex-wrap"
              data-hs-accordion-always-open
            >
              <ul className="flex flex-col space-y-1">
                {isMenuItemVisible("dashboard") && (
                  <li>
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        `w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <HomeIcon size={20} />
                      Dashboard
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("calendar") && (
                  <li className="hs-accordion" id="calendar-accordion">
                    <NavLink
                      to="/calendar"
                      className={({ isActive }) =>
                        `w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <Calendar className="w-4 h-4" />
                      Calendar
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("students") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/students/all"
                      className={({ isActive }) =>
                        `flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <Users size={20} />
                      Students
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("parents") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/parents/all"
                      className={({ isActive }) =>
                        `flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <User size={20} />
                      Parents
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("teachers") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/teachers/all"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <UserPen size={20} />
                      Staffs
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("academic_years") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/academicyears"
                      className={({ isActive }) =>
                        `w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <Calendar className="w-4 h-4" />
                      Academic Years
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("terms") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/terms/all"
                      className={({ isActive }) =>
                        `w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <ClipboardList className="w-4 h-4" />
                      Terms
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("classes") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/classes/all"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <Notebook size={20} />
                      Classes
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("subjects") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/subjects/all"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <Book size={20} />
                      Subjects
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("bus_routes") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/busroutes/all"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <Bus size={20} />
                      Bus Routes
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("enrollments") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/enrollments/all"
                      className={({ isActive }) =>
                        `w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <ClipboardList size={18} />
                      Enrollments
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("marks") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/marks"
                      className={({ isActive }) =>
                        `flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <CheckSquare size={18} />
                      Marks
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("results") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/results"
                      className={({ isActive }) =>
                        `w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <GraduationCap size={20} />
                      Results
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("fee_types") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/feetypes/all"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <Banknote size={20} />
                      Fee Types
                    </NavLink>
                  </li>
                )}

                {/* commenting out for now, because fee structures is used from the fee types */}
                {/* {isMenuItemVisible("fee_structures") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/feestructures"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <CircleDollarSign size={20} />
                      Fee Structures
                    </NavLink>
                  </li>
                )} */}

                {isMenuItemVisible("fees") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/fees/all"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <Wallet size={20} />
                      Fees
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("payments") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/payments"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <WalletCards size={20} />
                      Payments
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("attendances") && (
                  <li className="hs-accordion" id="users-accordion">
                    <NavLink
                      to="/attendances"
                      className="w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                      <FileCheck size={20} />
                      Attendances
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("notices") && (
                  <li className="hs-accordion" id="notices-accordion">
                    <NavLink
                      to="/notices/all"
                      className={({ isActive }) =>
                        `flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <Speech size={20} />
                      Notices
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("messages") && (
                  <li className="hs-accordion" id="messages-accordion">
                    <NavLink
                      to="/messages"
                      className={({ isActive }) =>
                        `w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <MessageSquare className="w-4 h-4" />
                      Messages
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("process_steps") && (
                  <li className="hs-accordion" id="process-steps-accordion">
                    <NavLink
                      to="/processsteps"
                      className={({ isActive }) =>
                        `w-full flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100 ${
                          isActive ? "bg-gray-100" : ""
                        }`
                      }
                    >
                      <ListChecks size={20} />
                      Process Steps
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("users") && (
                  <li>
                    <NavLink
                      to="/users/all"
                      className={({ isActive }) =>
                        `${
                          isActive ? "bg-gray-100" : ""
                        } flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100`
                      }
                    >
                      <Users className="w-4 h-4" />
                      Users
                    </NavLink>
                  </li>
                )}

                {isMenuItemVisible("user_actions") && (
                  <li>
                    <NavLink
                      to="/useractions"
                      className={({ isActive }) =>
                        `${
                          isActive ? "bg-gray-100" : ""
                        } flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-slate-700 rounded-lg hover:bg-gray-100`
                      }
                    >
                      <History className="w-4 h-4" />
                      User Actions
                    </NavLink>
                  </li>
                )}

                {/* Settings is always visible */}
                <li className="hs-accordion" id="settings-accordion">
                  <button
                    type="button"
                    onClick={handleSettingsClick}
                    className="hs-accordion-toggle w-full text-start flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  >
                    <Settings size={20} />
                    Settings
                  </button>
                </li>
              </ul>
            </nav>

            {/* <!-- Bottom Logo --> */}
            <div className="mt-auto p-6 flex flex-col items-center">
              <Link
                className="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80 mb-2"
                to="/"
                aria-label="Vidyapith"
              >
                <img
                  src="https://res.cloudinary.com/dykj04rol/image/upload/v1737099442/Vidyapith-Logo_qt71ap.png"
                  alt="Vidyapith Logo"
                  className="h-12"
                />
              </Link>
              <span className="text-sm text-gray-500">Vidyapith by VPIT</span>
            </div>
          </div>
          {/* <!-- End Content --> */}
        </div>
      </div>
      {/* <!-- End Sidebar --> */}
    </>
  );
};

export default Sidebar;

<style jsx global>{`
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`}</style>;
