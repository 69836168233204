import NepaliDate from "nepali-date-converter";

export const getCurrentNepaliDate = () => {
  const date = new Date();
  const nepaliDate = new NepaliDate(date);
  const year = nepaliDate.getYear();
  const month = String(nepaliDate.getMonth() + 1).padStart(2, "0");
  const day = String(nepaliDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
