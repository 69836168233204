import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSubjectById } from "../../Api/subjectServices";
import { useSubjectQuery } from "../../hooks/subject/useSubjectQuery";
import { getClasses } from "../../Api/classServices";
import { getAcademicYears } from "../../Api/academicYearServices";
import { getTeachers } from "../../Api/teacherServices";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import LoadingDots from "../common/LoadingDots";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const SubjectForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useSubjectQuery();
  const [isClassSubjectsExpanded, setIsClassSubjectsExpanded] =
    React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      class_subjects: [{ class_id: "", academic_year_id: "", teacher_id: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "class_subjects",
  });

  const { data: subjectData, isLoading: isLoadingSubject } = useQuery({
    queryKey: ["subjects", id],
    queryFn: () => getSubjectById(id),
    enabled: !!id,
    staleTime: 0, // Always fetch fresh data when editing
    cacheTime: 5 * 60 * 1000, // Cache for 5 minutes
  });

  const { data: classes = [], isLoading: isLoadingClasses } = useQuery({
    queryKey: ["classes"],
    queryFn: () => getClasses(1, 100),
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
  });

  const { data: academicYears = [], isLoading: isLoadingAcademicYears } = useQuery({
    queryKey: ["academicYears"],
    queryFn: getAcademicYears,
    staleTime: 5 * 60 * 1000,
  });

  const { data: teachers = [], isLoading: isLoadingTeachers } = useQuery({
    queryKey: ["teachers"],
    queryFn: getTeachers,
    staleTime: 5 * 60 * 1000,
  });

  React.useEffect(() => {
    if (subjectData && !isLoadingClasses && !isLoadingAcademicYears && !isLoadingTeachers) {
      const formattedData = {
        ...subjectData,
        class_subjects: subjectData.class_subjects?.length > 0 
          ? subjectData.class_subjects 
          : [{ class_id: "", academic_year_id: "", teacher_id: "" }]
      };
      reset(formattedData);
      if (formattedData.class_subjects?.length > 0) {
        setIsClassSubjectsExpanded(true);
      }
    }
  }, [subjectData, isLoadingClasses, isLoadingAcademicYears, isLoadingTeachers, reset]);

  const onSubmit = (data) => {
    setIsSubmitting(true);
    try {
      // Convert empty strings to null
      const processedData = {
        ...data,
        description: data.description || null,
        class_subjects: data.class_subjects.map((cs) => ({
          ...cs,
          teacher_id: cs.teacher_id || null,
        })),
      };

      if (id) {
        editMutation.mutate(
          { id, updatedData: processedData },
          {
            onSuccess: () => {
              setIsSubmitting(false);
              navigate("/subjects/all");
            },
            onError: () => {
              setIsSubmitting(false);
            },
          }
        );
      } else {
        createMutation.mutate(processedData, {
          onSuccess: () => {
            setIsSubmitting(false);
            navigate("/subjects/all");
          },
          onError: () => {
            setIsSubmitting(false);
          },
        });
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
    >
      <h2 className="text-2xl font-bold mb-6">
        {id ? "Edit Subject" : "Create New Subject"}
      </h2>

      <div className="space-y-6">
        <div>
          <label className="form_label">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={`form_input ${errors.name && requiredFieldClass}`}
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && (
            <span className={errorMessageClass}>{errors.name.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">
            Code <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={`form_input ${errors.code && requiredFieldClass}`}
            {...register("code", { required: "Code is required" })}
          />
          {errors.code && (
            <span className={errorMessageClass}>{errors.code.message}</span>
          )}
        </div>

        <div>
          <label className="form_label">
            Description <span className="text-gray-500 text-sm">Optional</span>
          </label>
          <textarea className="form_input" {...register("description")} />
        </div>

        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
              {...register("has_theory")}
            />
            <label className="form_label mb-0">Has Theory</label>
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
              {...register("has_practical")}
            />
            <label className="form_label mb-0">Has Practical</label>
          </div>
        </div>

        <div className="border rounded-lg overflow-hidden">
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
            onClick={() => setIsClassSubjectsExpanded(!isClassSubjectsExpanded)}
          >
            <span className="text-lg font-semibold">Class Assignment</span>
            {isClassSubjectsExpanded ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </button>

          {isClassSubjectsExpanded && (
            <div className="p-4 space-y-4">
              {fields.map((field, index) => (
                <div key={field.id} className="p-4 border rounded-lg space-y-4">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-medium">#{index + 1}</h4>
                    {fields.length > 1 && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    )}
                  </div>

                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <label className="form_label">Academic Year</label>
                      <select
                        {...register(
                          `class_subjects.${index}.academic_year_id`
                        )}
                        className="form_input"
                      >
                        <option value="">Select Academic Year</option>
                        {academicYears?.data?.map((year) => (
                          <option key={year.id} value={year.id}>
                            {year.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="form_label">Class</label>
                      <select
                        {...register(`class_subjects.${index}.class_id`)}
                        className="form_input"
                      >
                        <option value="">Select Class</option>
                        {classes?.data?.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {cls.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="form_label">
                        Teacher{" "}
                        <span className="text-gray-500 text-sm">
                          (Optional)
                        </span>
                      </label>
                      <select
                        {...register(`class_subjects.${index}.teacher_id`)}
                        className="form_input"
                      >
                        <option value="">Select Teacher</option>
                        {teachers?.data?.map((teacher) => (
                          <option key={teacher.id} value={teacher.id}>
                            {teacher.first_name} {teacher.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ))}

              <button
                type="button"
                onClick={() =>
                  append({ class_id: "", academic_year_id: "", teacher_id: "" })
                }
                className="mt-4 w-full py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Add Another
              </button>
            </div>
          )}
        </div>

        <div className="flex justify-end gap-4 mt-4">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={isSubmitting}
            className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? (
              <LoadingDots text={id ? "Updating" : "Creating"} />
            ) : id ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SubjectForm;
