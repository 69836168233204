import React, { useState, Fragment } from "react";
import LoadingDots from "../../components/common/LoadingDots";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../lib/axiosConfig";
import Table from "../../components/common/Table";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";

const UserActions = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAction, setSelectedAction] = useState(null);
  const limit = 10;

  // Redirect if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["userActions", { page: currentPage, limit }],
    queryFn: async () => {
      const response = await axiosInstance.get(`/useractions/`, {
        params: { limit, offset: (currentPage - 1) * limit },
      });
      return {
        data: response.data.data,
        metadata: response.data.metadata,
      };
    },
    enabled: !!user, // Only run query if user is authenticated
  });

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const formatType = (type) => {
    return type.charAt(0) + type.slice(1).toLowerCase();
  };

  const handleShowDetails = (action) => {
    setSelectedAction(action);
  };

  const columns = [
    {
      header: "User",
      accessorFn: (row) => `${row.user.first_name} ${row.user.last_name}`,
      cell: ({ row }) => (
        <div className="cursor-pointer" onClick={() => handleShowDetails(row.original)}>
          {row.original.user.first_name} {row.original.user.last_name}
        </div>
      ),
    },
    {
      header: "Action Type",
      accessorFn: (row) => formatType(row.type),
      cell: ({ row }) => (
        <div className="cursor-pointer" onClick={() => handleShowDetails(row.original)}>
          {formatType(row.original.type)}
        </div>
      ),
    },
    {
      header: "Reference Type",
      accessorKey: "reference_type",
      cell: ({ row }) => (
        <div className="cursor-pointer" onClick={() => handleShowDetails(row.original)}>
          {row.original.reference_type}
        </div>
      ),
    },
    {
      header: "Date",
      accessorFn: (row) => formatDate(row.created),
      cell: ({ row }) => (
        <div className="cursor-pointer" onClick={() => handleShowDetails(row.original)}>
          {formatDate(row.original.created)}
        </div>
      ),
    },
  ];

  const pagination = {
    currentPage: data?.metadata?.current_page || 1,
    totalPages: data?.metadata?.total_pages || 1,
    nextPage: () =>
      setCurrentPage((old) => Math.min(old + 1, data?.metadata?.total_pages || 1)),
    prevPage: () => setCurrentPage((old) => Math.max(old - 1, 1)),
    goToPage: (page) => setCurrentPage(page),
  };

  if (!user) {
    return null; // Don't render anything while redirecting
  }

  if (isError) {
    return <div>Error loading user actions</div>;
  }

  if (isLoading) {
    return <LoadingDots />;
  }

  const renderPayloadContent = (payload) => {
    if (!payload) return null;

    return Object.entries(payload).map(([key, value]) => (
      <div key={key} className="mb-4">
        <h4 className="text-sm font-semibold text-gray-700 mb-1">{key}</h4>
        <div className="text-sm text-gray-600">
          {typeof value === 'object' ? (
            <pre className="bg-gray-50 p-2 rounded">
              {JSON.stringify(value, null, 2)}
            </pre>
          ) : (
            <span>{value}</span>
          )}
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-4"></div>
        <Table
          data={data?.data || []}
          columns={columns}
          isLoading={isLoading}
          tableName="User Actions"
          pagination={pagination}
        />
      </div>

      <Transition appear show={!!selectedAction} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setSelectedAction(null)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="flex items-center justify-between border-b pb-4 mb-4"
                  >
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Action Details
                    </h3>
                    <button
                      onClick={() => setSelectedAction(null)}
                      className="text-gray-400 hover:text-gray-500 transition-colors"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </Dialog.Title>

                  {selectedAction && (
                    <div className="mt-2">
                      <div className="grid grid-cols-2 gap-6 mb-6">
                        <div>
                          <h4 className="text-sm font-semibold text-gray-700 mb-1">User</h4>
                          <p className="text-sm text-gray-600">
                            {selectedAction.user.first_name} {selectedAction.user.last_name}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-sm font-semibold text-gray-700 mb-1">Action Type</h4>
                          <p className="text-sm text-gray-600">{formatType(selectedAction.type)}</p>
                        </div>
                        <div>
                          <h4 className="text-sm font-semibold text-gray-700 mb-1">Reference Type</h4>
                          <p className="text-sm text-gray-600">{selectedAction.reference_type}</p>
                        </div>
                        <div>
                          <h4 className="text-sm font-semibold text-gray-700 mb-1">Date</h4>
                          <p className="text-sm text-gray-600">{formatDate(selectedAction.created)}</p>
                        </div>
                      </div>

                      <div className="border-t pt-6">
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Payload Details</h3>
                        {renderPayloadContent(selectedAction.payload)}
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UserActions;
