import axiosInstance from "../lib/axiosConfig";

export const getAcademicYears = async () => {
  const response = await axiosInstance.get('academicyears/');
  return response.data;
};

export const getAcademicYearById = async (id) => {
  const response = await axiosInstance.get(`academicyears/${id}`);
  return response.data;
};

export const createAcademicYear = async (data) => {
  const response = await axiosInstance.post('academicyears/', data);
  return response.data;
};

export const updateAcademicYear = async (id, data) => {
  const response = await axiosInstance.patch(`academicyears/${id}`, data);
  return response.data;
};

export const deleteAcademicYear = async (id) => {
  const response = await axiosInstance.delete(`academicyears/${id}`);
  return response.data;
};
