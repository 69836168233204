import axiosInstance from "../lib/axiosConfig";

export const getAttendances = async ({ offset = 0, limit = 10 }) => {
  const response = await axiosInstance.get(
    `/attendances/me/?offset=${offset}&limit=${limit}`
  );
  return {
    data: response.data.data,
    metadata: response.data.metadata,
  };
};

export const markAttendance = async (payload) => {
  const response = await axiosInstance.post("/attendances/", payload);
  return response.data;
};

export const updateAttendance = async (id, payload) => {
  const response = await axiosInstance.patch(`/attendances/${id}`, payload);
  return response.data;
};

export const verifyAttendance = async (id, version) => {
  const response = await axiosInstance.patch(`/attendances/verify/?id_=${id}`, {
    version,
    is_verified: true,
  });
  return response.data;
};

export const getTeacherAttendanceByDate = async (date) => {
  const response = await axiosInstance.get(`/attendances/dates/${date}`);
  return response.data;
};
