import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTerm, createTerm, updateTerm } from "../../Api/termServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useTermQuery = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteTerm(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
      toast.success("Term deleted successfully!");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Something went wrong!");
    },
  });

  const createMutation = useMutation({
    mutationFn: (data) => createTerm(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
      toast.success("Term created successfully!");
      navigate("/terms");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Something went wrong!");
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateTerm(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
      toast.success("Term updated successfully!");
      navigate("/terms");
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Something went wrong!");
    },
  });

  return {
    deleteMutation,
    createMutation,
    editMutation,
  };
};
