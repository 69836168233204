import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Select from "react-select";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { createEvent, updateEvent } from "../../Api/eventServices";
import { getAcademicYears } from "../../Api/enrollmentServices";
import { toast } from "react-toastify";
import LoadingDots from "../common/LoadingDots";
import ClearableDatePicker from "../common/ClearableDatePicker";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const EventForm = ({ open, onClose, onSubmit, initialData }) => {
  const [isYearsExpanded, setIsYearsExpanded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState(
    initialData?.description || ""
  );
  const [eventName, setEventName] = useState(initialData?.name || "");
  const [eventCode, setEventCode] = useState(initialData?.code || "");
  const [eventType, setEventType] = useState(initialData?.type || "school");
  const [eventId, setEventId] = useState(initialData?.id || null);
  const [eventVersion, setEventVersion] = useState(initialData?.version ?? 0);
  const [formError, setFormError] = useState("");
  const [academicYears, setAcademicYears] = useState(
    initialData?.years?.map((year) => ({
      id: year.id,
      version: year.version,
      academic_year_id: year.academic_year_id,
      event_id: initialData.id,
      start_date: year.start_date || "",
      end_date: year.end_date || "",
    })) || [
      {
        id: null,
        version: null,
        academic_year_id: null,
        event_id: null,
        start_date: "",
        end_date: "",
      },
    ]
  );

  const { data: academicYearsResponse, isLoading: isLoadingAcademicYears } =
    useQuery({
      queryKey: ["academicYears"],
      queryFn: getAcademicYears,
      staleTime: 5 * 60 * 1000,
    });

  const academicYearOptions =
    academicYearsResponse?.data?.map((year) => ({
      value: year.id,
      label: year.name,
    })) || [];

  useEffect(() => {
    if (initialData) {
      setDescription(initialData.description || "");
      setEventName(initialData.name || "");
      setEventCode(initialData.code || "");
      setEventType(initialData.type || "school");
      setEventId(initialData.id || null);
      setEventVersion(initialData.version ?? 0);
      setAcademicYears(
        initialData.years?.map((year) => ({
          id: year.id,
          version: year.version,
          academic_year_id: year.academic_year_id,
          event_id: initialData.id,
          start_date: year.start_date || "",
          end_date: year.end_date || "",
        })) || [
          {
            id: null,
            version: null,
            academic_year_id: null,
            event_id: null,
            start_date: "",
            end_date: "",
          },
        ]
      );
    }
  }, [initialData]);

  const handleAddYear = () => {
    setAcademicYears([
      ...academicYears,
      {
        id: null,
        version: null,
        academic_year_id: null,
        event_id: eventId,
        start_date: "",
        end_date: "",
      },
    ]);
  };

  const handleRemoveYear = (index) => {
    setAcademicYears(academicYears.filter((_, i) => i !== index));
  };

  const handleYearChange = (index, field, value) => {
    const updatedYears = [...academicYears];
    updatedYears[index] = {
      ...updatedYears[index],
      [field]: value,
    };
    setAcademicYears(updatedYears);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!eventName.trim()) {
      toast.error("Event name is required");
      return;
    }
    if (!eventCode.trim()) {
      toast.error("Event code is required");
      return;
    }

    // Validate academic years
    for (const year of academicYears) {
      if (!year.academic_year_id) {
        toast.error("Academic year is required for all entries");
        return;
      }
      if (!year.start_date) {
        toast.error("Start date is required for all entries");
        return;
      }
      if (!year.end_date) {
        toast.error("End date is required for all entries");
        return;
      }
    }

    // Update event_id for all years
    const updatedYears = academicYears.map((year) => ({
      ...year,
      event_id: eventId,
    }));

    const formData = {
      id: eventId,
      version: eventVersion,
      name: eventName,
      code: eventCode,
      description,
      type: eventType,
      years: updatedYears,
    };

    setIsSubmitting(true);
    try {
      let response;
      if (initialData) {
        response = await updateEvent(eventId, formData);
        toast.success("Event updated successfully");
      } else {
        response = await createEvent(formData);
        toast.success("Event created successfully");
      }
      onSubmit(response);
      handleClose();
    } catch (error) {
      console.error("Error saving event:", error);
      toast.error(error.response?.data?.message || "Failed to save event");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setEventName("");
    setEventCode("");
    setDescription("");
    setEventType("school");
    setEventId(null);
    setEventVersion(0);
    setAcademicYears([
      {
        id: null,
        version: null,
        academic_year_id: null,
        event_id: null,
        start_date: "",
        end_date: "",
      },
    ]);
    onClose();
  };

  if (isLoadingAcademicYears) {
    return <LoadingDots />;
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-900 mb-6"
                  >
                    {initialData ? "Edit Event" : "Create New Event"}
                  </Dialog.Title>

                  {formError && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-5 w-5 text-red-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-red-700">{formError}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="space-y-6">
                    <div>
                      <label className="form_label">
                        Event Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form_input ${
                          !eventName.trim() &&
                          "border-red-500 ring-1 ring-red-500"
                        }`}
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                      {!eventName.trim() && (
                        <span className={errorMessageClass}>
                          Event name is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label className="form_label">
                        Event Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form_input ${
                          !eventCode.trim() &&
                          "border-red-500 ring-1 ring-red-500"
                        }`}
                        value={eventCode}
                        onChange={(e) => setEventCode(e.target.value)}
                      />
                      {!eventCode.trim() && (
                        <span className={errorMessageClass}>
                          Event code is required
                        </span>
                      )}
                    </div>

                    <div>
                      <label className="form_label">
                        Description{" "}
                        <span className="text-gray-500 text-sm">Optional</span>
                      </label>
                      <textarea
                        className="form_input"
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div>
                      <label className="form_label">
                        Event Type <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`form_input`}
                        value={eventType}
                        onChange={(e) => setEventType(e.target.value)}
                      >
                        <option value="school">School</option>
                        <option value="holiday">Holiday</option>
                        <option value="exam">Exam</option>
                        <option value="other">Other</option>
                      </select>
                    </div>

                    <div className="border rounded-lg overflow-visible">
                      <button
                        type="button"
                        className="flex items-center justify-between w-full p-4 bg-gray-50 hover:bg-gray-100 transition-colors"
                        onClick={() => setIsYearsExpanded(!isYearsExpanded)}
                      >
                        <span className="text-lg font-semibold">
                          Academic Years
                        </span>
                        {isYearsExpanded ? (
                          <ChevronUpIcon className="w-5 h-5" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5" />
                        )}
                      </button>

                      {isYearsExpanded && (
                        <div className="p-4 space-y-4">
                          {academicYears.map((year, index) => (
                            <div
                              key={index}
                              className="p-4 border rounded-lg relative space-y-4"
                            >
                              {academicYears.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() => handleRemoveYear(index)}
                                  className="absolute top-2 right-2 text-red-500 hover:text-red-700"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                              )}

                              <div className="relative z-[10000]">
                                <label className="form_label">
                                  Academic Year{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <Select
                                  value={academicYearOptions.find(
                                    (option) =>
                                      option.value === year.academic_year_id
                                  )}
                                  onChange={(selected) =>
                                    handleYearChange(
                                      index,
                                      "academic_year_id",
                                      selected?.value
                                    )
                                  }
                                  options={academicYearOptions}
                                  placeholder="Select Academic Year"
                                  className="relative z-[10000]"
                                  classNamePrefix="react-select"
                                  styles={{
                                    menu: (base) => ({
                                      ...base,
                                      zIndex: 10000,
                                    }),
                                  }}
                                />
                              </div>

                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <ClearableDatePicker
                                    label="Start Date"
                                    value={year.start_date}
                                    onChange={(value) => {
                                      const updatedYears = [...academicYears];
                                      updatedYears[index].start_date = value;
                                      setAcademicYears(updatedYears);
                                    }}
                                    required
                                  />
                                </div>
                                <div>
                                  <ClearableDatePicker
                                    label="End Date"
                                    value={year.end_date}
                                    onChange={(value) => {
                                      const updatedYears = [...academicYears];
                                      updatedYears[index].end_date = value;
                                      setAcademicYears(updatedYears);
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          ))}

                          <button
                            type="button"
                            onClick={handleAddYear}
                            className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Add Academic Year
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-6 flex justify-end space-x-3">
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? (
                        <LoadingDots />
                      ) : initialData ? (
                        "Update Event"
                      ) : (
                        "Create Event"
                      )}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EventForm;
