import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronDown,
  ChevronUp,
  ListFilter,
  ListFilterIcon,
  LogOut,
  Plus,
  Search,
  Upload,
  User,
} from "lucide-react";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import HideColumn from "./HideColumn";
import debounce from "lodash.debounce";

const Table = ({
  data = [],
  columns = [],
  isLoading = false,
  handleLink = () => "#",
  tableName = "",
  baseEditPath = "",
  pagination = {
    currentPage: 1,
    totalPages: 1,
    nextPage: () => {},
    prevPage: () => {},
    goToPage: () => {},
  },
  setSearchQuery = null, // If provided, use API-based filtering
}) => {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [columnVisibility, setColumnVisibility] = useState({});
  const navigate = useNavigate();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      columnVisibility,
      globalFilter: setSearchQuery ? undefined : filtering, // Only use globalFilter if not using API-based search
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setSearchQuery ? undefined : setFiltering, // Only use globalFilter if not using API-based search
  });

  // Debounce search input for API-based search
  const debouncedSearch = useCallback(
    debounce((value) => {
      console.log("Debounced search value:", value);
      if (value.length >= 2) {
        setSearchQuery(value);
      } else {
        setSearchQuery("");
      }
    }, 300),
    [setSearchQuery]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setFiltering(value);
    if (setSearchQuery) {
      // API-based search
      debouncedSearch(value);
    }
    // Built-in filtering will happen automatically through React Table
  };

  // Clean up debounce on unmount
  useEffect(() => {
    if (setSearchQuery) {
      return () => {
        debouncedSearch.cancel();
      };
    }
  }, [debouncedSearch, setSearchQuery]);

  useEffect(() => {
    // Removed the filtering effect
  }, [filtering, setSearchQuery]);

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="flex flex-col w-full relative">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
              {tableName && (
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800">
                      {tableName}
                    </h2>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      <Link
                        to={handleLink()}
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                      >
                        <Plus className="w-4 h-4" />
                        Add {tableName}
                      </Link>
                      {/* <Link
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                        href="#"
                      >
                        <Upload className="w-4 h-4" />
                        Upload CSV
                      </Link> */}
                    </div>
                  </div>
                </div>
              )}
              <div className="px-6 py-4  flex justify-between items-center border-b border-gray-200 relative">
                <div className="relative max-w-xs">
                  <label className="sr-only">Search</label>
                  <input
                    type="text"
                    name="hs-table-with-pagination-search"
                    id="hs-table-with-pagination-search"
                    className="py-3 ps-10 pe-4 block w-full border-gray-500 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                    placeholder="Search"
                    value={filtering}
                    onChange={(e) => handleSearchChange(e)}
                  />
                  <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
                    <Search className="text-gray-400" size={18} />
                  </div>
                </div>
                {/* <!-- column visibility --> */}
                <HideColumn table={table} />
              </div>

              <div className="overflow-hidden">
                <div className="overflow-x-auto">
                  <table className="min-w-full table-fixed divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase whitespace-nowrap"
                              key={header.id}
                              onClick={header.column.getToggleSortingHandler()}
                              style={{
                                minWidth:
                                  header.column.columnDef.minWidth || "150px",
                              }}
                            >
                              {header.isPlaceholder ? null : (
                                <div className="flex items-center">
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {header.column.getCanSort() && (
                                    <div className="flex flex-col items-center">
                                      <ChevronUp
                                        size={18}
                                        className={`${
                                          header.column.getIsSorted() === "asc"
                                            ? "text-blue-500"
                                            : "text-gray-400"
                                        }`}
                                        style={{ marginBottom: "-5px" }} // Adjust to bring the icons closer
                                      />
                                      <ChevronDown
                                        size={18}
                                        className={`${
                                          header.column.getIsSorted() === "desc"
                                            ? "text-blue-500"
                                            : "text-gray-400"
                                        }`}
                                        style={{ marginTop: "-5px" }} // Adjust to bring the icons closer
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {table.getRowModel().rows.map((row) => (
                        <tr
                          key={row.id}
                          className={`hover:bg-gray-100 ${
                            baseEditPath ? "cursor-pointer" : ""
                          } transition-colors duration-200`}
                          onClick={() =>
                            baseEditPath &&
                            navigate(`${baseEditPath}/${row.original.id}`)
                          }
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td
                              key={cell.id}
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* table */}
              <div className="py-1 px-4">
                <nav
                  className="flex items-center space-x-1"
                  aria-label="Pagination"
                >
                  <button
                    type="button"
                    className="p-2.5 min-w-[40px] inline-flex justify-center items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => pagination.prevPage()}
                    disabled={pagination.currentPage === 1}
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">«</span>
                    <span className="sr-only">Previous</span>
                  </button>
                  <span className="text-sm">{`Page ${pagination.currentPage} of ${pagination.totalPages}`}</span>
                  <button
                    type="button"
                    className="p-2.5 min-w-[40px] inline-flex justify-center items-center gap-x-2 text-sm rounded-full text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => pagination.nextPage(pagination.totalPages)}
                    disabled={pagination.currentPage === pagination.totalPages}
                    aria-label="Next"
                  >
                    <span className="sr-only">Next</span>
                    <span aria-hidden="true">»</span>
                  </button>
                </nav>
              </div>
              {/* table end */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
