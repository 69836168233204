import axiosInstance from "../lib/axiosConfig";

export const getSubjects = async (page = 1, limit = 10) => {
  const offset = (page - 1) * limit;
  const response = await axiosInstance.get(`subjects/`, {
    params: {
      offset,
      limit,
    },
  });

  return {
    data: response.data.data,
    currentPage: response.data.metadata.current_page,
    totalPages: response.data.metadata.total_pages,
    totalCount: response.data.metadata.count,
  };
};

export const getAllSubjects = async () => {
  const response = await axiosInstance.get(`subjects/`, {
    params: {
      offset: 0,
      limit: 1000, // Using a large number to get all records
    },
  });
  return response.data.data;
};

export const getSubjectById = async (id) => {
  const response = await axiosInstance.get(`subjects/${id}`);
  return response.data;
};

export const createSubject = async (subjectData) => {
  const response = await axiosInstance.post("subjects/", subjectData);
  return response.data;
};

export const updateSubject = async (id, subjectData) => {
  const response = await axiosInstance.patch(`subjects/${id}`, subjectData);
  return response.data;
};

export const deleteSubject = async (id) => {
  const response = await axiosInstance.delete(`subjects/${id}`);
  return response.data;
};
