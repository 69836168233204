import axiosInstance from "../lib/axiosConfig";
import qs from "qs";

export const getToken = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      "auth/token",
      qs.stringify({
        grant_type: "password",
        username: credentials.username,
        password: credentials.password,
        scope: "",
        client_id: "string",
        client_secret: "string",
      }),
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating token:", error);
    throw error;
  }
};

export const getAllUsers = async ({ offset = 0, limit = 10 }) => {
  try {
    const response = await axiosInstance.get(`users/`, {
      params: {
        offset,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    const response = await axiosInstance.get(`users/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axiosInstance.post("users/", userData);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const updateUser = async (id, userData) => {
  try {
    const response = await axiosInstance.patch(`users/${id}`, userData);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await axiosInstance.delete(`users/${id}`);
    return true;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const activateUser = async (userId, linkedId = null) => {
  try {
    const response = await axiosInstance.get(`activateaccount`, {
      params: { 
        user_id: userId,
        ...(linkedId && { linked_id: linkedId })
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error activating user:", error);
    throw error;
  }
};

export const deactivateUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`deactivateaccount`, {
      params: { user_id: userId },
    });
    return response.data;
  } catch (error) {
    console.error("Error deactivating user:", error);
    throw error;
  }
};
