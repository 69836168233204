import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getTeacherById } from "../../Api/teacherServices";
import { useTeacherQuery } from "../../hooks/useTeacherQuery";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import LoadingDots from "../common/LoadingDots";
import PhoneInput from "react-phone-input-2";
import AddressField from "../common/addressField";
import ClearableDatePicker from "../common/ClearableDatePicker";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";

// CSS classes for form styling
const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";
const formLabelClass = "form_label";
const formInputClass = "form_input";
const formSelectClass =
  "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm";

const currencies = [
  { value: "NPR", label: "Nepalese Rupee (NPR)" },
  { value: "USD", label: "US Dollar (USD)" },
  { value: "EUR", label: "Euro (EUR)" },
  { value: "INR", label: "Indian Rupee (INR)" },
];

const staffTypes = [
  { value: "teacher", label: "Teacher" },
  { value: "driver", label: "Driver" },
  { value: "librarian", label: "Librarian" },
  { value: "accountant", label: "Accountant" },
  { value: "cleaner", label: "Cleaner" },
  { value: "other", label: "Other" },
];

const studentStatus = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

const bloodGroups = [
  { value: "unknown", label: "Unknown" },
  { value: "a+", label: "A+" },
  { value: "a-", label: "A-" },
  { value: "b+", label: "B+" },
  { value: "b-", label: "B-" },
  { value: "o+", label: "O+" },
  { value: "o-", label: "O-" },
  { value: "ab+", label: "AB+" },
  { value: "ab-", label: "AB-" },
];

const religions = [
  { value: "hinduism", label: "Hinduism" },
  { value: "islam", label: "Islam" },
  { value: "buddhism", label: "Buddhism" },
  { value: "christianity", label: "Christianity" },
  { value: "kirat_mundhum", label: "Kirat Mundhum" },
  { value: "prakriti", label: "Prakriti" },
  { value: "bon", label: "Bon" },
  { value: "sikhism", label: "Sikhism" },
  { value: "jainism", label: "Jainism" },
  { value: "other", label: "Other" },
];

const castes = [
  { value: "other", label: "Other" },
  { value: "dalit", label: "Dalit" },
  { value: "janajati", label: "Janajati" },
  { value: "brahmin", label: "Brahmin" },
  { value: "chhetri", label: "Chhetri" },
];

const TeacherForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createMutation, editMutation } = useTeacherQuery();
  const [isPersonalExpanded, setIsPersonalExpanded] = useState(true);
  const [isContactExpanded, setIsContactExpanded] = useState(false);
  const [isSalariesExpanded, setIsSalariesExpanded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dobDate, setDobDate] = useState("");
  const [joiningDate, setJoiningDate] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      dob: null,
      status: "active",
      joining_date: "",
      gender: "male",
      type: "teacher",
      email: null,
      phone: "",
      address: {
        municipality: "",
        ward: "",
        district: "",
        province: "",
        tole: "",
      },
      caste: null,
      religion: null,
      nationality: null,
      blood_group: null,
      citizenship: null,
      qualification: null,
      notes: null,
      salaries: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "salaries",
  });

  const { data: teacherData, isLoading: isLoadingTeacher } = useQuery({
    queryKey: ["teachers", id],
    queryFn: () => getTeacherById(id),
    enabled: !!id && id !== "undefined",
    staleTime: 0,
    cacheTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (teacherData) {
      const formattedData = {
        ...teacherData,
        address: {
          municipality: teacherData.address?.municipality || "",
          ward: teacherData.address?.ward || "",
          district: teacherData.address?.district || "",
          province: teacherData.address?.province || "",
          tole: teacherData.address?.tole || "",
        },
        salaries:
          teacherData.salaries?.length > 0
            ? teacherData.salaries.map((salary) => {
                const [currency, amount] = salary.amount.split(" ");
                return {
                  ...salary,
                  currency,
                  amount,
                };
              })
            : [],
      };
      reset(formattedData);
      if (formattedData.dob) {
        setDobDate(formattedData.dob);
      }
      if (formattedData.joining_date) {
        setJoiningDate(formattedData.joining_date);
      }
      if (formattedData.salaries?.length > 0) {
        setIsSalariesExpanded(true);
      } else {
        setIsSalariesExpanded(false);
      }
    }
  }, [teacherData, reset]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const submissionData = {
        ...data,
        dob: dobDate || null,
        joining_date: joiningDate,
      };

      // Check if all address fields are empty
      const isAddressEmpty = Object.values(submissionData.address).every(
        (value) => !value || value.trim() === ""
      );

      // Set address to null if all fields are empty
      if (isAddressEmpty) {
        submissionData.address = null;
      }

      // Process or remove salaries based on whether the section is expanded
      if (!isSalariesExpanded) {
        submissionData.salaries = [];
      } else if (submissionData.salaries?.length) {
        submissionData.salaries = submissionData.salaries.map((salary) => ({
          ...salary,
          amount: `${salary.currency} ${parseFloat(salary.amount).toFixed(2)}`,
          allowance: salary.allowance || null,
          deduction: salary.deduction || null,
          effective_to: salary.effective_to || null,
          status: salary.status || "active",
          teacher_id: id || null,
        }));
      } else {
        submissionData.salaries = null;
      }

      if (id) {
        await editMutation.mutateAsync({ id, ...submissionData });
      } else {
        await createMutation.mutateAsync(submissionData);
      }
      navigate("/teachers/all");
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response?.status === 422) {
        const errorDetails = error.response.data.detail;
        if (Array.isArray(errorDetails) && errorDetails.length > 0) {
          errorDetails.forEach((detail) => {
            toast.error(detail.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
        } else {
          toast.error("Validation error occurred", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } else {
        toast.error("An error occurred while saving the teacher", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoadingTeacher) {
    return <LoadingDots />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-6xl mx-auto p-6 border rounded-lg"
    >
      <div className="bg-white shadow-sm rounded-lg p-6 mb-8">
        <h3 className="text-lg font-semibold mb-4">
          Staff's Detail{" "}
          <span className="text-sm text-gray-500">(* Required fields)</span>
        </h3>

        {/* Personal Information Section */}
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className={formLabelClass}>First Name *</label>
              <input
                {...register("first_name", {
                  required: "First name is required",
                })}
                className={`${formInputClass} ${
                  errors.first_name ? requiredFieldClass : ""
                }`}
              />
              {errors.first_name && (
                <p className={errorMessageClass}>{errors.first_name.message}</p>
              )}
            </div>

            <div>
              <label className={formLabelClass}>Last Name *</label>
              <input
                {...register("last_name", {
                  required: "Last name is required",
                })}
                className={`${formInputClass} ${
                  errors.last_name ? requiredFieldClass : ""
                }`}
              />
              {errors.last_name && (
                <p className={errorMessageClass}>{errors.last_name.message}</p>
              )}
            </div>

            <div>
              <ClearableDatePicker
                label="Date of Birth"
                value={dobDate}
                onChange={(date) => {
                  setDobDate(date || "");
                  setValue("dob", date);
                }}
                error={errors.dob?.message}
              />
            </div>

            <div>
              <ClearableDatePicker
                label="Joining Date"
                value={joiningDate}
                onChange={(date) => {
                  setJoiningDate(date || "");
                  setValue("joining_date", date);
                }}
                required
                error={errors.joining_date?.message}
              />
            </div>

            <div>
              <label className={formLabelClass}>Status *</label>
              <select
                {...register("status", { required: "Status is required" })}
                className={`form_input ${errors.status && requiredFieldClass}`}
              >
                {studentStatus.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.status && (
                <p className={errorMessageClass}>{errors.status.message}</p>
              )}
            </div>

            <div>
              <label className={formLabelClass}>Gender *</label>
              <select
                className={`form_input ${errors.gender && requiredFieldClass}`}
                {...register("gender", {
                  required: "Gender is required",
                })}
              >
                <option value="">Select Gender</option>
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.gender && (
                <p className={errorMessageClass}>{errors.gender.message}</p>
              )}
            </div>

            <div>
              <label className={formLabelClass}>Staff Type *</label>
              <select
                {...register("type", { required: "Staff type is required" })}
                className={`form_input ${errors.type && requiredFieldClass}`}
              >
                {staffTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.type && (
                <p className={errorMessageClass}>{errors.type.message}</p>
              )}
            </div>
          </div>
        </div>

        {/* Contact Information Section */}
        <br />
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className={formLabelClass}>Email</label>
              <input
                type="email"
                {...register("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                className={formInputClass}
              />
              {errors.email && (
                <p className={errorMessageClass}>{errors.email.message}</p>
              )}
            </div>

            <div>
              <label className={formLabelClass}>Phone</label>
              <PhoneInput
                country={"np"}
                value={watch("phone")}
                onChange={(phone) => setValue("phone", phone)}
                inputClass={formInputClass}
              />
            </div>

            <div className="sm:col-span-2">
              <AddressField
                register={register}
                setValue={setValue}
                errors={errors}
                watch={watch}
                initialValue={teacherData?.address}
              />
            </div>
          </div>
        </div>

        {/* Additional Information */}
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className={formLabelClass}>Caste</label>
              <select
                className={`form_input ${errors.caste && requiredFieldClass}`}
                {...register("caste", {})}
              >
                <option value="">Select Caste</option>
                {castes.map((caste) => (
                  <option key={caste.value} value={caste.value}>
                    {caste.label}
                  </option>
                ))}
              </select>
              {errors.caste && (
                <p className={errorMessageClass}>{errors.caste.message}</p>
              )}
            </div>

            <div>
              <label className={formLabelClass}>Religion</label>
              <select
                className={`form_input ${
                  errors.religion && requiredFieldClass
                }`}
                {...register("religion", {})}
              >
                <option value="">Select Religion</option>
                {religions.map((religion) => (
                  <option key={religion.value} value={religion.value}>
                    {religion.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className={formLabelClass}>Nationality</label>
              <input {...register("nationality")} className={formInputClass} />
            </div>

            <div>
              <label className={formLabelClass}>Blood Group</label>
              <select
                className={`form_input ${
                  errors.blood_group && requiredFieldClass
                }`}
                {...register("blood_group", {})}
              >
                <option value="">Select Blood Group</option>
                {bloodGroups.map((bloodGroup) => (
                  <option key={bloodGroup.value} value={bloodGroup.value}>
                    {bloodGroup.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className={formLabelClass}>Citizenship</label>
              <input {...register("citizenship")} className={formInputClass} />
            </div>

            <div>
              <label className={formLabelClass}>Qualification</label>
              <input
                {...register("qualification")}
                className={formInputClass}
              />
            </div>

            <div className="sm:col-span-2">
              <label className={formLabelClass}>Notes</label>
              <textarea
                {...register("notes")}
                className={formInputClass}
                rows={3}
              />
            </div>
          </div>
        </div>

        {/* Salary Information Section */}
        <div className="mb-6">
          <div
            className="flex cursor-pointer items-center justify-between mb-4"
            onClick={() => setIsSalariesExpanded(!isSalariesExpanded)}
          >
            <h4 className="text-base font-semibold text-gray-900">
              Salary Information
            </h4>
            {isSalariesExpanded ? (
              <ChevronUpIcon className="h-5 w-5 text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
            )}
          </div>

          {isSalariesExpanded && (
            <div>
              {fields.length === 0 && (
                <div className="flex justify-center mb-4">
                  <button
                    type="button"
                    onClick={() =>
                      append({
                        amount: "",
                        currency: "NPR",
                        allowance: "",
                        deduction: "",
                        effective_from: "",
                        effective_to: "",
                        notes: "",
                        status: "active",
                      })
                    }
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                  >
                    Add Salary
                  </button>
                </div>
              )}

              {fields.map((field, index) => (
                <div key={field.id} className="mb-6 rounded-lg border p-4">
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className={formLabelClass}>
                        Currency <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`form_input ${
                          errors.salaries?.[index]?.currency &&
                          requiredFieldClass
                        }`}
                        {...register(`salaries.${index}.currency`, {
                          required: "Currency is required",
                        })}
                      >
                        <option value="">Select Currency</option>
                        {currencies.map((currency) => (
                          <option key={currency.value} value={currency.value}>
                            {currency.label}
                          </option>
                        ))}
                      </select>
                      {errors.salaries?.[index]?.currency && (
                        <span className={errorMessageClass}>
                          {errors.salaries[index].currency.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label className={formLabelClass}>
                        Amount <span className="text-red-500">*</span>
                      </label>
                      <input
                        className={`form_input ${
                          errors.salaries?.[index]?.amount && requiredFieldClass
                        }`}
                        type="number"
                        step="0.01"
                        {...register(`salaries.${index}.amount`, {
                          required: "Amount is required",
                          min: {
                            value: 0,
                            message: "Amount must be greater than 0",
                          },
                        })}
                      />
                      {errors.salaries?.[index]?.amount && (
                        <span className={errorMessageClass}>
                          {errors.salaries[index].amount.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <label className={formLabelClass}>Allowance</label>
                      <input
                        className={`form_input`}
                        type="number"
                        {...register(`salaries.${index}.allowance`)}
                      />
                    </div>

                    <div>
                      <label className={formLabelClass}>Deduction</label>
                      <input
                        className={`form_input`}
                        type="number"
                        {...register(`salaries.${index}.deduction`)}
                      />
                    </div>

                    <div>
                      <ClearableDatePicker
                        label="Effective From"
                        value={watch(`salaries.${index}.effective_from`)}
                        onChange={(date) =>
                          setValue(`salaries.${index}.effective_from`, date)
                        }
                        required
                        error={errors.salaries?.[index]?.effective_from?.message}
                      />
                    </div>

                    <div>
                      <ClearableDatePicker
                        label="Effective To"
                        value={watch(`salaries.${index}.effective_to`)}
                        onChange={(date) =>
                          setValue(`salaries.${index}.effective_to`, date)
                        }
                        error={errors.salaries?.[index]?.effective_to?.message}
                      />
                    </div>

                    <div>
                      <label className={formLabelClass}>
                        Status <span className="text-red-500">*</span>
                      </label>
                      <select
                        className={`form_input ${
                          errors.salaries?.[index]?.status && requiredFieldClass
                        }`}
                        {...register(`salaries.${index}.status`, {
                          required: "Status is required",
                        })}
                      >
                        <option value="">Select Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                      {errors.salaries?.[index]?.status && (
                        <span className={errorMessageClass}>
                          {errors.salaries[index].status.message}
                        </span>
                      )}
                    </div>

                    <div className="col-span-2">
                      <label className={formLabelClass}>Notes</label>
                      <textarea
                        {...register(`salaries.${index}.notes`)}
                        rows={3}
                        className={formInputClass}
                      />
                    </div>
                  </div>

                  {fields.length >= 1 && (
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="rounded-md bg-red-50 px-3 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                      >
                        Remove Salary
                      </button>
                    </div>
                  )}
                </div>
              ))}

              {fields.length > 0 && (
                <div className="flex justify-center mb-4">
                  <button
                    type="button"
                    onClick={() =>
                      append({
                        amount: "",
                        currency: "NPR",
                        allowance: "",
                        deduction: "",
                        effective_from: "",
                        effective_to: "",
                        notes: "",
                        status: "active",
                      })
                    }
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                  >
                    Add Another Salary
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Submit Buttons */}
      <div className="flex items-center justify-end gap-x-6 mt-6">
        <button
          type="button"
          onClick={() => navigate("/teachers/all")}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          {isSubmitting ? "Saving..." : "Save"}
        </button>
      </div>
    </form>
  );
};

export default TeacherForm;
