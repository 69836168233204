import React, { useState, useEffect } from "react";
import { deleteEvent } from "../../Api/eventServices";
import { getTeacherAttendanceByDate } from "../../Api/attendanceServices";
import { toast } from "react-toastify";
import NepaliDate from "nepali-date-converter";
import { TableActions } from "../../components/common/TableActions";
import EventForm from "./EventForm";
import { useAuth } from "../../context/authContext";

const EventList = ({ date, events, onEventUpdate }) => {
  const [editingEvent, setEditingEvent] = useState(null);
  const [showEventForm, setShowEventForm] = useState(false);
  const [teacherAttendance, setTeacherAttendance] = useState(null);
  const { user } = useAuth();
  const canViewAttendance = ["teacher", "admin", "financier"].includes(
    user?.roles?.[0]
  );

  useEffect(() => {
    const fetchTeacherAttendance = async () => {
      if (!date || !canViewAttendance) return;

      try {
        const response = await getTeacherAttendanceByDate(date);
        setTeacherAttendance(response);
      } catch (error) {
        console.error("Failed to fetch teacher attendance:", error);
        setTeacherAttendance(null);
      }
    };

    fetchTeacherAttendance();
  }, [date, canViewAttendance]);

  const handleDeleteEvent = async (eventId) => {
    try {
      await deleteEvent(eventId);
      toast.success("Event deleted successfully");
      onEventUpdate();
    } catch (error) {
      toast.error("Failed to delete event");
    }
  };

  const handleEdit = (eventId) => {
    const eventToEdit = events.find((event) => event.id === eventId);
    setEditingEvent(eventToEdit);
    setShowEventForm(true);
  };

  const handleCloseForm = () => {
    setShowEventForm(false);
    setEditingEvent(null);
  };

  const handleSubmit = async (data) => {
    try {
      await onEventUpdate();
      handleCloseForm();
    } catch (error) {
      toast.error("Failed to update event");
    }
  };

  const formatNepaliDate = (dateString) => {
    if (!dateString) return "";

    // Handle if dateString is a Date object
    if (dateString instanceof Date) {
      return new NepaliDate(dateString).format("YYYY-MM-DD");
    }

    // Handle string date
    try {
      const parts = dateString.toString().split("-");
      // No need to adjust month since the date is already in Nepali format
      return `${parts[0]}-${parts[1].padStart(2, "0")}-${parts[2].padStart(
        2,
        "0"
      )}`;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const getEventTypeColor = (type) => {
    switch (type.toLowerCase()) {
      case "school":
        return "primary";
      case "holiday":
        return "error";
      case "exam":
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <div className="mt-4">
      {events.length === 0 ? (
        <p className="text-gray-500">No events for this date</p>
      ) : (
        <div className="space-y-4">
          {events.map((event) => (
            <div
              key={event.id}
              className="bg-white rounded-lg shadow p-4 flex justify-between items-start"
            >
              <div className="flex-grow">
                <div className="flex items-center gap-2 mb-2">
                  <h3 className="text-lg font-medium">{event.name}</h3>
                  <span
                    className={`px-2 py-1 text-xs font-semibold rounded-full ${
                      getEventTypeColor(event.type) === "primary"
                        ? "bg-blue-100 text-blue-800"
                        : getEventTypeColor(event.type) === "error"
                        ? "bg-red-100 text-red-800"
                        : getEventTypeColor(event.type) === "warning"
                        ? "bg-yellow-100 text-yellow-800"
                        : "bg-gray-100 text-gray-800"
                    }`}
                  >
                    {event.type}
                  </span>
                </div>
                <div className="mt-2">
                  <p className="text-gray-600 text-sm">{event.description}</p>
                  <div className="mt-2 space-y-1">
                    <p className="text-xs text-gray-500">
                      From: {formatNepaliDate(event.years[0].start_date)}
                    </p>
                    <p className="text-xs text-gray-500">
                      To: {formatNepaliDate(event.years[0].end_date)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-4">
                <TableActions
                  onEdit={() => handleEdit(event.id)}
                  onDelete={() => handleDeleteEvent(event.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {canViewAttendance && teacherAttendance && (
        <div className="mt-6">
          <h4 className="text-md font-semibold mb-2">
            Present Teachers ({teacherAttendance.count})
          </h4>
          <ul className="list-disc list-inside text-gray-700">
            {teacherAttendance.teacher_names.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        </div>
      )}

      {showEventForm && (
        <EventForm
          open={showEventForm}
          onClose={handleCloseForm}
          onSubmit={handleSubmit}
          initialData={editingEvent}
        />
      )}
    </div>
  );
};

export default EventList;
