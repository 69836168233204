import React, { useState, useEffect } from "react";
import NepaliDate from "nepali-date-converter";
import EventForm from "./EventForm";
import { getEventsByDateRange } from "../../Api/eventServices";
import { useAuth } from "../../context/authContext";

const WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const NEPALI_MONTHS = [
  "Baisakh",
  "Jestha",
  "Ashadh",
  "Shrawan",
  "Bhadra",
  "Ashwin",
  "Kartik",
  "Mangsir",
  "Poush",
  "Magh",
  "Falgun",
  "Chaitra",
];

// Nepali calendar data for days in each month and first day of year
const NEPALI_CALENDAR_DATA = {
  2079: {
    monthDays: [31, 31, 32, 31, 31, 31, 30, 30, 30, 29, 30, 30],
    firstDayOfYear: 4,
  },
  2080: {
    monthDays: [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    firstDayOfYear: 5,
  },
  2081: {
    monthDays: [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    firstDayOfYear: 6,
  },
  2082: {
    monthDays: [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    firstDayOfYear: 1,
  },
  2083: {
    monthDays: [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    firstDayOfYear: 2,
  },
};

const CalendarView = ({ onDateSelect, onEventCreate }) => {
  const { user } = useAuth();
  const canCreateEvent = !["student", "parent"].includes(user?.roles?.[0]);

  const [currentNepaliDate] = useState(() => {
    const date = new Date();
    const nepaliDate = new NepaliDate(date);
    nepaliDate.setMonth(nepaliDate.getMonth() + 1);
    return nepaliDate;
  });

  const [currentNepaliYear, setCurrentNepaliYear] = useState(
    currentNepaliDate.getYear()
  );
  const [currentNepaliMonth, setCurrentNepaliMonth] = useState(
    currentNepaliDate.getMonth()
  );
  const [showEventForm, setShowEventForm] = useState(false);
  const [monthEvents, setMonthEvents] = useState({});

  useEffect(() => {
    const fetchMonthEvents = async () => {
      try {
        const startDate = `${currentNepaliYear}-${String(
          currentNepaliMonth
        ).padStart(2, "0")}-01`;
        const lastDay = getDaysInMonth(
          currentNepaliYear,
          currentNepaliMonth - 1
        );
        const endDate = `${currentNepaliYear}-${String(
          currentNepaliMonth
        ).padStart(2, "0")}-${String(lastDay).padStart(2, "0")}`;

        const events = await getEventsByDateRange(startDate, endDate);
        const eventsByDate = {};

        events.forEach((event) => {
          if (!event?.years || !Array.isArray(event.years)) return;

          event.years.forEach((yearData) => {
            if (!yearData?.start_date || !yearData?.end_date) return;

            const startParts = yearData.start_date.split("-");
            const endParts = yearData.end_date.split("-");

            const startNepali = new NepaliDate(
              parseInt(startParts[0]),
              parseInt(startParts[1]),
              parseInt(startParts[2])
            );
            const endNepali = new NepaliDate(
              parseInt(endParts[0]),
              parseInt(endParts[1]),
              parseInt(endParts[2])
            );

            let currentNepali = startNepali;
            while (currentNepali <= endNepali) {
              const dateKey = `${currentNepali.getYear()}-${String(
                currentNepali.getMonth()
              ).padStart(2, "0")}-${String(currentNepali.getDate()).padStart(
                2,
                "0"
              )}`;
              if (!eventsByDate[dateKey]) {
                eventsByDate[dateKey] = [];
              }
              eventsByDate[dateKey].push(event);

              const jsDate = currentNepali.toJsDate();
              jsDate.setDate(jsDate.getDate() + 1);
              currentNepali = new NepaliDate(jsDate);
            }
          });
        });

        setMonthEvents(eventsByDate);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchMonthEvents();
  }, [currentNepaliYear, currentNepaliMonth]);

  const getDaysInMonth = (year, month) => {
    return NEPALI_CALENDAR_DATA[year]?.monthDays[month] || 30;
  };

  const getFirstDayOfMonth = () => {
    // Calculate the first day of the month based on the first day of the year
    const yearData = NEPALI_CALENDAR_DATA[currentNepaliYear];
    if (!yearData) return 0;

    let totalDays = yearData.firstDayOfYear;
    for (let i = 0; i < currentNepaliMonth - 1; i++) {
      totalDays += yearData.monthDays[i];
    }
    return totalDays % 7;
  };

  const handlePrevMonth = () => {
    if (currentNepaliMonth === 1) {
      const prevYear = currentNepaliYear - 1;
      if (NEPALI_CALENDAR_DATA[prevYear]) {
        setCurrentNepaliYear(prevYear);
        setCurrentNepaliMonth(12);
      }
    } else {
      setCurrentNepaliMonth((prev) => prev - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentNepaliMonth === 12) {
      const nextYear = currentNepaliYear + 1;
      if (NEPALI_CALENDAR_DATA[nextYear]) {
        setCurrentNepaliYear(nextYear);
        setCurrentNepaliMonth(1);
      }
    } else {
      setCurrentNepaliMonth((prev) => prev + 1);
    }
  };

  const getEventCount = (day) => {
    const dateKey = `${currentNepaliYear}-${String(currentNepaliMonth).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")}`;
    return monthEvents[dateKey]?.length || 0;
  };

  const isCurrentDate = (day) => {
    return (
      currentNepaliDate.getYear() === currentNepaliYear &&
      currentNepaliDate.getMonth() === currentNepaliMonth &&
      currentNepaliDate.getDate() === day
    );
  };

  const handleDateClick = (day) => {
    const formattedDate = `${currentNepaliYear}-${String(
      currentNepaliMonth
    ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
    const selectedEvents = monthEvents[formattedDate] || [];
    onDateSelect(formattedDate, selectedEvents);
  };

  const handleCreateEvent = (eventData) => {
    onEventCreate(eventData);
    setShowEventForm(false);
  };

  const renderCalendarDays = () => {
    const days = Array.from(
      { length: getDaysInMonth(currentNepaliYear, currentNepaliMonth - 1) },
      (_, i) => i + 1
    );
    const firstDay = getFirstDayOfMonth();
    const blanks = Array(firstDay).fill(null);

    return [...blanks, ...days].map((day, index) => (
      <div
        key={index}
        className={`text-center font-bold p-2 bg-gray-100 ${
          index === 6 ? "text-red-600" : ""
        }`}
      >
        {day}
      </div>
    ));
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <button
            onClick={handlePrevMonth}
            className="p-2 rounded-full hover:bg-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <h2 className="mx-4 text-xl font-semibold">
            {NEPALI_MONTHS[currentNepaliMonth - 1]} {currentNepaliYear}
          </h2>
          <button
            onClick={handleNextMonth}
            className="p-2 rounded-full hover:bg-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
        {canCreateEvent && (
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowEventForm(true)}
          >
            Create Event
          </button>
        )}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {WEEKDAYS.map((day, index) => (
          <div
            key={day}
            className={`text-center font-bold p-2 bg-gray-100 ${
              index === 6 ? "text-red-600" : ""
            }`}
          >
            {day}
          </div>
        ))}
        {[...Array(getFirstDayOfMonth())].map((_, index) => (
          <div key={`empty-${index}`} className="p-1" />
        ))}
        {Array.from({
          length: getDaysInMonth(currentNepaliYear, currentNepaliMonth - 1),
        }).map((_, index) => {
          const day = index + 1;
          const eventCount = getEventCount(day);
          return (
            <div
              key={day}
              onClick={() => handleDateClick(day)}
              className={`
                p-2 h-[60px] border border-gray-200 cursor-pointer relative flex items-center justify-center
                ${
                  isCurrentDate(day)
                    ? "bg-blue-50"
                    : (index + getFirstDayOfMonth() + 1) % 7 === 0
                    ? "bg-red-50"
                    : "bg-white"
                }
                hover:bg-gray-50 transition-colors
              `}
            >
              <span
                className={`text-base font-medium ${
                  (index + getFirstDayOfMonth() + 1) % 7 === 0
                    ? "text-red-600"
                    : ""
                }`}
              >
                {day}
              </span>
              {eventCount > 0 && (
                <span className="absolute top-0.5 right-0.5 inline-flex items-center justify-center px-1.5 py-0.5 text-[10px] font-bold leading-none text-white bg-blue-600 rounded-full min-w-[16px] h-4">
                  {eventCount}
                </span>
              )}
            </div>
          );
        })}
      </div>

      <EventForm
        open={showEventForm}
        onClose={() => setShowEventForm(false)}
        onSubmit={handleCreateEvent}
      />
    </div>
  );
};

export default CalendarView;
