import React, { useState, useRef, useEffect } from "react";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { getCurrentNepaliDate } from "../../utils/dateConverter";

const isValidNepaliDate = (dateStr) => {
  // Basic validation for YYYY-MM-DD format
  const pattern = /^\d{4}-\d{2}-\d{2}$/;
  if (!pattern.test(dateStr)) return false;

  const [year, month, day] = dateStr.split("-").map(Number);
  
  // Basic Nepali date validation
  if (year < 2000 || year > 2090) return false;
  if (month < 1 || month > 12) return false;
  if (day < 1 || day > 32) return false;

  return true;
};

const TypeableNepaliDatePicker = ({
  label,
  value,
  onChange,
  required = false,
  error,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const [showPicker, setShowPicker] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    // Only update the actual value if it's a valid date or empty
    if (newValue === "" || isValidNepaliDate(newValue)) {
      onChange(newValue || null);
    }
  };

  const handleDatePickerChange = (bsDate) => {
    setInputValue(bsDate || "");
    onChange(bsDate || null);
    setShowPicker(false);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setInputValue("");
    onChange(null);
    setShowPicker(false);
  };

  return (
    <div className={`mb-4 ${className}`} ref={wrapperRef}>
      <label className="block text-gray-700 text-sm font-bold mb-2">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <div className="flex gap-2">
        <div className="flex-1 relative">
          <input
            type="text"
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white pr-8"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="YYYY-MM-DD"
            onClick={() => setShowPicker(true)}
          />
          {inputValue && (
            <button
              type="button"
              onClick={handleClear}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          )}
          {showPicker && (
            <div className="absolute left-0 top-full z-50 bg-white shadow-lg rounded-md mt-1">
              <NepaliDatePicker
                className="w-full"
                value={value}
                onChange={handleDatePickerChange}
                options={{
                  calenderLocale: "ne",
                  valueLocale: "en",
                  defaultValue: getCurrentNepaliDate(),
                }}
              />
            </div>
          )}
        </div>
      </div>
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
};

export default TypeableNepaliDatePicker;
