import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAcademicYear, updateAcademicYear, deleteAcademicYear } from "../../Api/academicYearServices";
import { toast } from 'react-toastify';

export const useAcademicYearQuery = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: createAcademicYear,
    onSuccess: () => {
      queryClient.invalidateQueries(["academicYears"]);
      toast.success('Academic year created successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error creating academic year');
    },
  });

  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => updateAcademicYear(id, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(["academicYears"]);
      toast.success('Academic year updated successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error updating academic year');
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteAcademicYear,
    onSuccess: () => {
      queryClient.invalidateQueries(["academicYears"]);
      toast.success('Academic year deleted successfully');
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Error deleting academic year');
    },
  });

  return {
    createMutation,
    editMutation,
    deleteMutation,
  };
};
