import React from "react";
import Table from "../../components/common/Table";
import LoadingDots from "../../components/common/LoadingDots";
import { useTeachersTable } from "../../hooks/teacher/useTeachersTable";

const ListTeachers = () => {
  const {
    data: { data },
    columns,
    error,
    isLoading,
    nextPage,
    prevPage,
    currentPage,
    totalPages,
    pagination,
    setSearchQuery,
  } = useTeachersTable();

  const handleLink = () => "/teachers/create";

  if (isLoading) {
    return <LoadingDots />;
  }

  if (error) return <div>Error loading data: {error.message}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4"></div>
      <Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        pagination={pagination}
        handleLink={handleLink}
        tableName="Staff"
        baseEditPath="/teachers/edit"
        setSearchQuery={setSearchQuery}
      />
    </div>
  );
};

export default ListTeachers;
