import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useFeesQuery from "../../hooks/fees/useFeesQuery";
import useTableColumns from "../../hooks/fees/useTableColumns";
import Table from "../../components/common/Table";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../lib/axiosConfig";
import Select from "react-select";
import debounce from "lodash/debounce";
import { searchParents } from "../../Api/parentServices";
import LoadingDots from "../common/LoadingDots";

const ListFees = () => {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedParent, setSelectedParent] = useState(null);
  const [parentSearch, setParentSearch] = useState("");

  // Fetch academic years
  const { data: academicYearsResponse } = useQuery({
    queryKey: ["academicYears"],
    queryFn: async () => {
      const response = await axiosInstance.get("/academicyears/");
      return response.data;
    },
  });

  // Search parents
  const { data: searchedParents, isLoading: isLoadingParents } = useQuery({
    queryKey: ["searchParents", parentSearch],
    queryFn: () => searchParents(parentSearch),
    enabled: parentSearch.length >= 2,
  });

  const academicYears = academicYearsResponse?.data || [];

  const {
    fees,
    isLoading,
    isError,
    deleteMutation,
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    goToPage,
  } = useFeesQuery(selectedYear?.value, selectedParent?.value);

  // Set default year when academic years are loaded
  useEffect(() => {
    if (academicYears.length > 0 && !selectedYear) {
      setSelectedYear({
        value: academicYears[0].id,
        label: academicYears[0].year,
      });
    }
  }, [academicYears, selectedYear]);

  const debouncedParentSearch = useCallback(
    debounce((inputValue) => {
      setParentSearch(inputValue);
    }, 500),
    []
  );

  const handleEdit = (id) => {
    navigate(`/fees/edit/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteMutation.mutateAsync(id);
      toast.success("Fee deleted successfully");
    } catch (error) {
      toast.error(error.message || "Failed to delete fee");
    }
  };

  const handleRowClick = (row) => {
    navigate(`/fees/edit/${row.id}`);
  };

  const columns = useTableColumns({ handleEdit, handleDelete });

  const handleLink = () => "/fees/create";

  const pagination = {
    currentPage,
    totalPages,
    nextPage,
    prevPage,
    goToPage,
  };

  if (isLoading) {
    return <LoadingDots />;
  }

  if (isError) {
    return <div>Error loading fees</div>;
  }

  return (
    <>
      <div className="flex gap-4 mb-4">
        <div className="w-[250px]">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Academic Year
          </label>
          <Select
            value={selectedYear}
            onChange={setSelectedYear}
            options={academicYears.map((year) => ({
              value: year.id,
              label: year.name,
            }))}
            isSearchable={false}
            placeholder="Select Academic Year"
          />
        </div>

        <div className="w-[250px]">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Parent (Optional)
          </label>
          <Select
            value={selectedParent}
            onChange={setSelectedParent}
            options={
              searchedParents?.data?.map((parent) => ({
                value: parent.id,
                label: `${parent.first_name} ${parent.last_name}`,
              })) || []
            }
            onInputChange={debouncedParentSearch}
            isLoading={isLoadingParents}
            placeholder="Search parent..."
            isClearable
          />
        </div>
      </div>

      <Table
        data={fees}
        columns={columns}
        isLoading={isLoading}
        handleLink={handleLink}
        tableName="Fees"
        pagination={pagination}
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default ListFees;
