import React from "react";
import { Link } from "react-router-dom";
import { Eye, Trash } from "lucide-react";
import { useAuth } from "../../context/authContext";

export const TableActions = ({ onEdit, onDelete }) => {
  const { user } = useAuth();
  const isAdmin = user?.roles?.includes("admin");
  const canEdit = !["student", "parent"].includes(user?.roles?.[0]);

  return (
    <div className="flex gap-1" onClick={(e) => e.stopPropagation()}>
      {canEdit && (
        <button
          onClick={onEdit}
          className="p-1 bg-blue-50 text-blue-600 rounded-full hover:bg-blue-100"
        >
          <Eye size={16} />
        </button>
      )}
      {onDelete && isAdmin && (
        <button
          onClick={onDelete}
          className="p-1 bg-red-50 text-red-600 rounded-full hover:bg-red-100"
        >
          <Trash size={16} />
        </button>
      )}
    </div>
  );
};
