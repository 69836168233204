import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  getAllProcessSteps,
  createProcessStep,
  updateProcessStep,
  deleteProcessStep,
} from "../../Api/processStepsService";
import LoadingDots from "../../components/common/LoadingDots";
import { toast } from "react-toastify";
import { useTableColumns } from "../../hooks/processStep/useTableColumns";
import Table from "../../components/common/Table";
import confirmDelete from "../../lib/confirmDelete";

const requiredFieldClass = "border-red-500 ring-1 ring-red-500";
const errorMessageClass = "text-red-500 text-sm mt-1";

const ProcessSteps = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [processSteps, setProcessSteps] = useState([]);
  const [selectedProcessStep, setSelectedProcessStep] = useState(null);

  const defaultValues = {
    name: "",
    code: "",
    description: "",
    roles: ["student"],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues,
  });

  const roles = ["student", "teacher", "financier", "admin", "parent"];
  const watchedRoles = watch("roles", ["student"]);

  useEffect(() => {
    fetchProcessSteps();
  }, []);

  const fetchProcessSteps = async () => {
    try {
      const response = await getAllProcessSteps({ offset: 0, limit: 100 });
      setProcessSteps(response.data || []);
    } catch (error) {
      toast.error("Failed to fetch process steps");
    }
  };

  const handleRoleToggle = (role) => {
    const currentRoles = watchedRoles;
    const newRoles = currentRoles.includes(role)
      ? currentRoles.filter((r) => r !== role)
      : [...currentRoles, role];
    setValue("roles", newRoles);
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      if (selectedProcessStep) {
        // Include version in the update payload
        await updateProcessStep(selectedProcessStep.id, {
          ...data,
          version: selectedProcessStep.version,
        });
        toast.success("Process step updated successfully");
      } else {
        await createProcessStep(data);
        toast.success("Process step created successfully");
      }
      fetchProcessSteps();
      reset();
      setSelectedProcessStep(null);
    } catch (error) {
      if (error.response?.status === 409) {
        toast.error("This record has been modified. Please refresh and try again.");
      } else {
        toast.error(
          selectedProcessStep
            ? "Failed to update process step"
            : "Failed to create process step"
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = (processStep) => {
    setSelectedProcessStep(processStep);
    reset({
      name: processStep.name,
      code: processStep.code || "",
      description: processStep.description || "",
      roles: processStep.roles,
    });
    // Scroll to the form
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDelete = (id) => {
    confirmDelete(async () => {
      return new Promise((resolve, reject) => {
        deleteProcessStep(id)
          .then(() => {
            fetchProcessSteps();
            resolve();
          })
          .catch(reject);
      });
    });
  };

  const columns = useTableColumns({ handleEdit, handleDelete });

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-6">
          {selectedProcessStep ? "Edit Process Step" : "Create Process Step"}
        </h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow"
        >
          <div className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label className="form_label">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`form_input ${errors.name && requiredFieldClass}`}
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <span className={errorMessageClass}>
                    {errors.name.message}
                  </span>
                )}
              </div>

              <div>
                <label className="form_label">Code</label>
                <input
                  type="text"
                  className={`form_input ${errors.code && requiredFieldClass}`}
                  {...register("code")}
                />
                {errors.code && (
                  <span className={errorMessageClass}>
                    {errors.code.message}
                  </span>
                )}
              </div>
            </div>

            <div>
              <label className="form_label">Description</label>
              <textarea
                className={`form_input min-h-[100px] ${
                  errors.description && requiredFieldClass
                }`}
                {...register("description")}
              />
              {errors.description && (
                <span className={errorMessageClass}>
                  {errors.description.message}
                </span>
              )}
            </div>

            <div>
              <label className="form_label">
                Roles <span className="text-red-500">*</span>
              </label>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {roles.map((role) => (
                  <div key={role} className="flex items-center">
                    <input
                      type="checkbox"
                      id={role}
                      checked={watchedRoles.includes(role)}
                      onChange={() => handleRoleToggle(role)}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor={role}
                      className="ml-2 block text-sm text-gray-900"
                    >
                      {role}
                    </label>
                  </div>
                ))}
              </div>
              {errors.roles && (
                <span className={errorMessageClass}>
                  {errors.roles.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-5">
            {selectedProcessStep && (
              <button
                type="button"
                onClick={() => {
                  setSelectedProcessStep(null);
                  reset(defaultValues);
                }}
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
            )}
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {isSubmitting ? (
                <LoadingDots
                  text={selectedProcessStep ? "Updating" : "Creating"}
                />
              ) : selectedProcessStep ? (
                "Update"
              ) : (
                "Create"
              )}
            </button>
          </div>
        </form>

        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Process Steps List</h3>
          <Table columns={columns} data={processSteps} baseEditPath="" />
        </div>
      </div>
    </div>
  );
};

export default ProcessSteps;
