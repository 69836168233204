import axiosInstance from "../lib/axiosConfig";

export const getTeachers = async ({ offset = 0, limit = 10 } = {}) => {
  const response = await axiosInstance.get(`teachers/`, {
    params: {
      offset,
      limit,
    },
  });
  return response.data;
};

export const getTeacherById = async (id) => {
  const response = await axiosInstance.get(`teachers/${id}`);
  return response.data;
};

export const createTeacher = async (data) => {
  const response = await axiosInstance.post("teachers/", data);
  return response.data;
};

export const updateTeacher = async (id, data) => {
  const response = await axiosInstance.patch(`teachers/${id}`, data);
  return response.data;
};

export const deleteTeacher = async (id) => {
  const response = await axiosInstance.delete(`teachers/${id}`);
  return response.data;
};

export const searchTeachers = async (query) => {
  const response = await axiosInstance.get(
    `teachers/?filter=first_name~${query}`
  );
  return response.data;
};
